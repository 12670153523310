import { NgModule } from '@angular/core';
import { EolPipe } from './eol.pipe';
import { SafeUrlPipe } from './safeurl.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';

@NgModule({
    declarations: [
        EolPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
    ],
    exports: [
        EolPipe,
        SafeUrlPipe,
        SafeHtmlPipe,
    ],
    imports: [],
    providers: [],
})
export class PipesModule { }
