<div id="wrap" *ngIf="langsReady">
	<header class="grad">
		<div id="logo"></div>
	</header>
	<div id="main" [class]='currentLang.dir === "rtl" ? "full rtl" : "full ltr"'>
		<div id="submain">
			<div class="content">
				<h1>404</h1>
				page not found
			</div>		
		</div>
	</div>	
	<footer class="grad">
		<div class="footer-content">
			<div class="btn home" routerLink="/"><span></span></div>
		</div>		
	</footer>		
</div>	

<div id="loading" [class]="langsReady ? '' : 'visible'">
	<div class="loading-spinner"></div>
</div>
