import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ILang } from '../../../model/lang.interface';

@Component({
    selector: 'notification-popup',
    templateUrl: 'notification.popup.component.html',
    styleUrls: ["./notification.popup.component.scss"],
})
export class NotificationPopupComponent {
    @Input() active: boolean;
    @Input() currentLang: ILang;
    @Input() leadId: number = 0;
    @Input() link: string = "";
    @Input() message: string = "";
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private navCtrl: NavController
    ) {
    }

    public goToLeadClient() {
        var elem = document.getElementsByClassName('lead-client-routing')[0];
        if (elem) {
            elem.classList.remove('lead-client-hidden');
            elem.classList.add('lead-client-visible');
        }
        if (!this.link) {
            this.link = `/leadClients/${this.leadId}`;
        }
        this.navCtrl.navigateForward(this.link);
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }
}
