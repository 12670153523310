import { Model } from './model';
import { IMyDpShortDate } from './dp.shortdate.interface';
import { ILeadPrices } from './leadprices.interface';
import { LeadNote } from './leadnote.model';
import { LeadPermissionType } from './leadpermission.interface';

export class Lead extends Model {
    constructor(
        public assigneeId?: number,
        public assigneeName?: string,
        public callRecordingUrl?: string,
        public callStatus?: string,
        public campaignId?: number,
        public campaignTitle?: string,
        public createDate?: string, //2019-11-09T16:00:53.39
        public dealAmount?: number,
        public email?: string,
        public followUp?: string, //2019-11-09T16:00:53.39
        public formUrl?: string,
        public fullname?: string,
        public googleKeyword?: string,
        public id?: number,
        public leadAdditionalStatusId?: number,
        public leadProcessId?: number,
        public leadStatusId?: number,
        public leadType?: string,
        public otherFields?: string,
        public phone?: string,
        public refererUrl?: string,
        public isCalculatedLead?: boolean,
        public leadClientId?: number,
        public assetTitle?: string,
        public restrictions?: LeadPermissionType[],

        // not from API
        public rawFollowup?: Date,
        public rawCreatedate?: Date,
        public token?: string,
        public prices?: ILeadPrices,
        public notes?: LeadNote[],
        public preview?: string,

        // validate
        public emailError?: string,
        public phoneError?: string,

        public expand?: boolean
    ) {
        super();
    }

    public build(o: Object, token?: string): Lead {
        super.build(o);
        this.rawFollowup = new Date(this.followUp);
        this.rawCreatedate = new Date(this.createDate);

        if (token) {
            this.token = token;
        }

        return this;
    }

    public init(): Lead {
        this.fullname = "";
        this.leadProcessId = 0;
        this.leadStatusId = 0;
        let d = new Date();
        // current date in format "1900-01-01T00:00:00.00"
        this.followUp = `${d.getFullYear()}-${this.twoDigits(d.getMonth() + 1)}-${this.twoDigits(d.getDate())}T${this.twoDigits(d.getHours())}:${this.twoDigits(d.getMinutes())}:00.00`;
        this.createDate = this.followUp;
        this.rawFollowup = new Date(this.followUp);
        this.rawCreatedate = new Date(this.createDate);

        return this;
    }

    get formatFollowup(): string {
        return `${this.rawFollowup.getDate()}/${this.twoDigits(this.rawFollowup.getMonth() + 1)}/${this.rawFollowup.getFullYear()} ${this.twoDigits(this.rawFollowup.getHours())}:${this.twoDigits(this.rawFollowup.getMinutes())}`;
    }

    get formatCreatedate(): string {
        return `${this.rawCreatedate.getDate()}/${this.twoDigits(this.rawCreatedate.getMonth() + 1)}/${this.rawCreatedate.getFullYear()} ${this.twoDigits(this.rawCreatedate.getHours())}:${this.twoDigits(this.rawCreatedate.getMinutes())}`;
    }

    get calendarFollowupDate(): IMyDpShortDate {
        return { date: { year: this.rawFollowup.getFullYear(), month: this.rawFollowup.getMonth() + 1, day: this.rawFollowup.getDate() } };
    }

    get followupHour(): number {
        return this.rawFollowup.getHours();
    }

    get followupMinute(): number {
        return this.rawFollowup.getMinutes();
    }

    get fullRefererUrl(): string {
        if (this.refererUrl.length) {
            return (this.refererUrl.indexOf("http") === -1) ? `http://${this.refererUrl}` : this.refererUrl;
        }
        else {
            return this.refererUrl;
        }
    }

    get whatsapp(): string {
        return this.phone.replace(/[^0-9]/g, '');
    }
}
