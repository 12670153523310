/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./404.page.scss.ngstyle";
import * as i1 from "../../app.component.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/router";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/common";
import * as i6 from "./404.page";
import * as i7 from "../../services/langs.repository";
var styles_Page404 = [i0.styles, i1.styles];
var RenderType_Page404 = i2.ɵcrt({ encapsulation: 2, styles: styles_Page404, data: {} });
export { RenderType_Page404 as RenderType_Page404 };
function View_Page404_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 14, "div", [["id", "wrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "header", [["class", "grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "div", [["id", "logo"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 5, "div", [["id", "main"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 4, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 3, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["404"])), (_l()(), i2.ɵted(-1, null, [" page not found "])), (_l()(), i2.ɵeld(9, 0, null, null, 5, "footer", [["class", "grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 4, "div", [["class", "footer-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 3, "div", [["class", "btn home"], ["routerLink", "/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i2.ɵnov(_v, 13).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i2.ɵdid(12, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i2.Renderer2, i2.ElementRef], { routerLink: [0, "routerLink"] }, null), i2.ɵdid(13, 737280, null, 0, i4.RouterLinkDelegate, [i5.LocationStrategy, i4.NavController, i2.ElementRef, i3.Router, [2, i3.RouterLink]], null, null), (_l()(), i2.ɵeld(14, 0, null, null, 0, "span", [], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = "/"; _ck(_v, 12, 0, currVal_1); _ck(_v, 13, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentLang.dir === "rtl") ? "full rtl" : "full ltr"); _ck(_v, 3, 0, currVal_0); }); }
export function View_Page404_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_Page404_1)), i2.ɵdid(1, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["id", "loading"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 0, "div", [["class", "loading-spinner"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.langsReady; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.langsReady ? "" : "visible"); _ck(_v, 2, 0, currVal_1); }); }
export function View_Page404_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "page404", [], null, null, null, View_Page404_0, RenderType_Page404)), i2.ɵdid(1, 49152, null, 0, i6.Page404, [i7.LangsRepository], null, null)], null, null); }
var Page404NgFactory = i2.ɵccf("page404", i6.Page404, View_Page404_Host_0, {}, {}, []);
export { Page404NgFactory as Page404NgFactory };
