<div *ngIf="currentLang && leadClient && statuses"
    [class]="active ? 'overlay listClients '+currentLang.dir+' active' : 'overlay ' + currentLang.dir">
    <div class="ol-main" style="width: 400px;">
        <div class="ol-head grad">
            <div class="ol-title">{{title}}</div>
            <div class="ol-close" (click)="close ();"><span></span></div>
        </div>
        <div class="ol-content">
            <div id="wls-statuses" [dir]="currentLang.dir" *ngIf="step === 1">
                <button id="inprocess" [class]="leadClient.leadStatusId === 4 ? 'active' : ''"
                    (click)="setLeadStatus (4);"><span>{{currentLang.words["in-process"]}}</span></button>
                <button id="meeting" [class]="leadClient.leadStatusId === 7 ? 'active' : ''"
                    (click)="setLeadStatus (7);"><span>{{currentLang.words["meeting"]}}</span></button>
                <button id="deal" [class]="leadClient.leadStatusId === 9 ? 'active' : ''"
                    (click)="setLeadStatus (9);"><span>{{currentLang.words["deal"]}}</span></button>
                <button id="notrelevant" [class]="leadClient.leadStatusId === 11 ? 'active' : ''"
                    (click)="setLeadStatus (11);"><span>{{currentLang.words["not-relevant"]}}</span></button>
            </div>
            <div id="wls-substatuses" *ngIf="step === 2">
                <div id="wlss-content" [dir]="currentLang.dir">
                    <button *ngFor="let ss of substatuses"
                        [class]="leadClient.additionalStatusId === ss.id ? 'active' : ''"
                        (click)="setLeadSubstatus (ss.id);">
                        <span [innerHTML]="ss.title"></span>
                    </button>
                </div>
                <div class="edit edit-button">
                    <div class="edit-param">
                        <button id="wls-back" (click)="step = 1;" class="big red-btn">{{currentLang.words["back"]}}</button>
                    </div>
                </div>
            </div>
            <div id="wls-amount" *ngIf="step === 3">
                <div class="edit">
                    <div class="edit-param" style="margin-bottom: 15px">
                        <label [class]="leadClient.amount != null ? '' : 'empty'">{{currentLang.words["amount"]}} <span
                            class="required"></span></label>
                        <input type="number" name="amount" [(ngModel)]="amount"
                            (change)="onLeadAmountChanged();">
                        <div class="bg-white"></div>
                    </div>
                    <div class="edit-status error" [innerHTML]="amountRequiredError"></div>
                    <div class="edit-param">
                        <button (click)="apply();">{{currentLang.words['apply']}}</button>
                    </div>
                    <div class="edit edit-button">
                        <div class="edit-param">
                            <button id="wls-back" (click)="step = 1;" class="big red-btn">{{currentLang.words["back"]}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="step === 4 && calendarReady">
                <div class="ol-flexblock center">
                    <my-date-picker [(ngModel)]="currentDate" [options]="calendarOptions" [locale]="currentLang.slug">
                    </my-date-picker>
                </div>
                <div class="ol-flexblock center ltr-always">
                    <select name="calendarHour" [(ngModel)]="currentHour">
                        <option *ngFor="let hour of hours" [value]="hour">{{hour | number:'2.0-0'}}</option>
                    </select>
                    &nbsp;&nbsp;:&nbsp;&nbsp;
                    <select name="calendarMinute" [(ngModel)]="currentMinute">
                        <option *ngFor="let minute of minutes" [value]="minute">{{minute | number:'2.0-0'}}</option>
                    </select>
                </div>
                <div class="ol-block center">
                    <div class="action"><span
                            (click)="calendarForward (1);">{{currentLang.words["1day-forward"]}}</span></div>
                    <div class="action"><span
                            (click)="calendarForward (3);">{{currentLang.words["3days-forward"]}}</span></div>
                    <div class="action"><span
                            (click)="calendarForward (7);">{{currentLang.words["1week-forward"]}}</span></div>
                </div>
                <div class="ol-apply">
                    <button (click)="calendarApply ();">{{currentLang.words["apply"]}}</button>
                </div>
                <div class="edit edit-button" style="margin: 10px;">
                    <div class="edit-param">
                        <button id="wls-back" (click)="step = 1;" class="big red-btn">{{currentLang.words["back"]}}</button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="ol-right" (click)="close ();"></div>
</div>