import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { CommonModule } from '@angular/common';
import { MyDatePickerModule } from 'mydatepicker';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { PipesModule } from '../pipes/pipes.module';
import { SubStatusViewOverlayComponent } from "../substatuses/overlay/substatus.overlay.page";
import { UsersViewOverlayComponent } from "../users/overlay/users.overlay.page";
import { SubstatusesPage } from "../substatuses/substatuses.page";
import { SubStatusNewOverlayComponent } from "../substatuses/overlay/substatus.overlay.new";
import { SubStatusEditOverlayComponent } from '../substatuses/overlay/substatus.overlay.edit';
import { UsersPage } from '../users/users.page';
import { UsersNewOverlayComponent } from '../users/overlay/users.overlay.new';
import { UsersEditOverlayComponent } from '../users/overlay/users.overlay.edit';
import { SubStatusReplaceOverlayComponent } from "../substatuses/overlay/substatus.overlay.replace";
import { UserReplaceOverlayComponent } from "../users/overlay/users.overlay.replace";
import { SettingsOverlayComponent } from "../settings/overlay/settings.overlay.page";
import { SettingsPage } from "../settings/settings.page";
import { ProcessesPage } from "../processes/processes.page";
import { ProcessesViewOverlayComponent } from "../processes/overlay/processes.overlay.page";
import { IEWarningComponent } from "./popups/ie-warning/ie.warning.popup";
import { AlertNotificationPopupComponent } from "./popups/followUp/alertnotification.popup.component";
import { TooltipPopupComponent } from "./popups/tooltip/tooltip.popup.component";
import { PermissionPopupComponent } from "./popups/android-permission/androidPermission.popup";
import { DownloadPopupComponent } from "./popups/download/download.popup.component";
import { ErrorPopupComponent } from "./popups/error/error.popup.component";
import { NotificationPopupComponent } from "./popups/notification/notification.popup.component";
import { LeadCalendarBlockComponent } from "./lead/calendar/lead.calendar.block.component";
import { LeadEditComponent } from "./lead/edit/lead.edit.component";
import { LeadFooterComponent } from "./lead/footer/lead.footer.component";
import { LeadPreviewComponent } from "./lead/preview/lead.preview.component";
import { LeadViewComponent } from "./lead/view/lead.view.component";
import { LeadCalendarWinComponent } from "./lead/calendar/lead.calendar.win.component";
import { LeadStatusesWinComponent } from "./lead/statuses/lead.statuses.win.component";
import { IntroComponent } from './intro/intro.component';
import { SubstatusEditComponent } from './substatus/substatus.edit.component';
import { UserEditComponent } from './user/user.edit.component';
import { ProcessEditComponent } from './process/process.edit.component';
import { ProcessesNewOverlayComponent } from '../processes/overlay/processes.overlay.new';
import { ProcessesEditOverlayComponent } from '../processes/overlay/processes.overlay.edit';
import { ProcessesReplaceOverlayComponent } from '../processes/overlay/processes.overlay.replace';
import { VersionUpdateComponent } from './updatePopup/version.update.popup';
import { SettingsNotificationsOverlayComponent } from '../settings/overlay/notifications/settings.overlay.notifications';
import { SettingsNotificationsEditComponent } from './settings/notifications/notifications.settings.edit.component';
import { WhatsAppNotificationPopupComponent } from './popups/whatsApp/whatsApp.notification.popup.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        MyDatePickerModule,
        PipesModule
    ],
    declarations: [
        LeadFooterComponent,
        LeadViewComponent,
        LeadPreviewComponent,
        LeadEditComponent,
        LeadCalendarWinComponent,
        LeadCalendarBlockComponent,
        LeadStatusesWinComponent,
        IntroComponent,
        SubstatusEditComponent,
        UserEditComponent,
        ErrorPopupComponent,
        DownloadPopupComponent,
        NotificationPopupComponent,
        PermissionPopupComponent,
        IEWarningComponent,
        AlertNotificationPopupComponent,
        WhatsAppNotificationPopupComponent,

        // Overlays for Substatus
        SubStatusViewOverlayComponent,
        SubstatusesPage,
        SubStatusNewOverlayComponent,
        SubStatusEditOverlayComponent,
        SubStatusReplaceOverlayComponent,

        // overlays for Users
        UsersViewOverlayComponent,
        UsersPage,
        UsersNewOverlayComponent,
        UsersEditOverlayComponent,
        UserReplaceOverlayComponent,

        // For Notifications
        TooltipPopupComponent,

        // For client settings
        SettingsOverlayComponent,
        SettingsPage,

        SettingsNotificationsEditComponent,
        SettingsNotificationsOverlayComponent,
        // Overlays for Processes
        ProcessesViewOverlayComponent,
        ProcessesPage,
        ProcessEditComponent,
        ProcessesNewOverlayComponent,
        ProcessesViewOverlayComponent,
        ProcessesEditOverlayComponent,
        ProcessesReplaceOverlayComponent,

        // For Version Update
        VersionUpdateComponent,
    ],
    exports: [
        LeadFooterComponent,
        LeadViewComponent,
        LeadPreviewComponent,
        LeadEditComponent,
        LeadCalendarWinComponent,
        LeadCalendarBlockComponent,
        LeadStatusesWinComponent,
        IntroComponent,
        SubstatusEditComponent,
        UserEditComponent,
        ErrorPopupComponent,
        DownloadPopupComponent,
        NotificationPopupComponent,
        PermissionPopupComponent,
        IEWarningComponent,
        AlertNotificationPopupComponent,
        WhatsAppNotificationPopupComponent,

        // Overlays for Substatus
        SubStatusViewOverlayComponent,
        SubstatusesPage,
        SubStatusNewOverlayComponent,
        SubStatusEditOverlayComponent,
        SubStatusReplaceOverlayComponent,

        // overlays for Users
        UsersViewOverlayComponent,
        UsersPage,
        UsersNewOverlayComponent,
        UsersEditOverlayComponent,

        // For Notifications
        TooltipPopupComponent,

        // For client settings
        SettingsOverlayComponent,
        SettingsPage,
        SettingsNotificationsOverlayComponent,
        SettingsNotificationsEditComponent,
        
        // Overlays for Processes
        ProcessesViewOverlayComponent,
        ProcessesPage,
        ProcessEditComponent,
        ProcessesNewOverlayComponent,
        ProcessesViewOverlayComponent,
        ProcessesEditOverlayComponent,
        ProcessesReplaceOverlayComponent,
        
        // For Version Update
        VersionUpdateComponent
    ],
    providers: [
        CallNumber,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IfaceComponentsModule { }
