import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ILang } from '../../model/lang.interface';

@Component({
    selector: 'version-update-popup',
    templateUrl: 'version.update.popup.html',
    styleUrls: ["../../app.component.scss"]
})

export class VersionUpdateComponent {
    @Input() active: boolean;
    @Input() currentLang: ILang;
    @Input() isIOS: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public onPopupClick(event: MouseEvent): void {
        if ((event.target as HTMLElement).className === "popup active") {
            this.close();
        }
    }

    public goToLink($event: any, link: string) {
        $event.preventDefault();
        var obj = new InAppBrowser().create(link, '_system');
        obj.show();
    }
}
