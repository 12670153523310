import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from 'rxjs';
import { LangsRepository } from "../langs.repository";
import { LeadClient } from "src/app/model/leadClients/lead.client.model";
import { IResponse } from "src/app/model/response.interface";
import { LeadDeal } from "src/app/model/leadClients/lead.client.deal";
import { LeadClientMeeting } from "src/app/model/leadClients/lead.client.meeting";
import { WhatsAppDataService } from "../DataServices/whatsApp.data.service";
import { Lead } from "src/app/model/lead.model";
import { DataService } from "../DataServices/data.service";
import { LeadClientMeetingStatus } from "src/app/model/leadClients/lead.client.meeting.meetingstatus";

@Injectable()
export class LeadClientRepositoryBPT {
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        public whatsAppdataService: WhatsAppDataService,
        public langsRepository: LangsRepository,
        public dataService: DataService
    ) {
        this.dataService.token.subscribe(token => {
            if (token) {
                this.ready.next(true);
            } else {
                this.ready.next(false);
            }
        })
    }

    public loadLeadClientByPhone(phone: string): Observable<IResponse<LeadClient>> {
        return this.dataService.getLeadClient(phone);
    }

    public loadLeadClientByEmail(email: string): Observable<IResponse<LeadClient>>{
        return this.dataService.getLeadClientByEmail(email);
    }

    public sendUpdateLeadClientFollowup(id: number, followUp: string): Observable<IResponse<any>> {
        return this.dataService.but_updateLeadClientFollowUp(id, followUp);
    }

    public sendUpdateLeadClientStatus(leadClient: LeadClient): Observable<IResponse<any>> {
        return this.dataService.but_updateLeadClientStatus(leadClient);
    }

    public sendUpdateLeadClient(id: number, leadClient: LeadClient) {
        return this.dataService.but_updateLeadClient(leadClient);
    }

    public sendAddLeadClient(leadClient: LeadClient): Observable<IResponse<number>> {
        return this.dataService.but_addLeadClient(leadClient);
    }

    public sendLeadClientDeal(dealId: number, leadClientId: number, subject: string, amount: number, assigneeId: number, createdDate: string): Observable<IResponse<number>> {
        return this.dataService.but_updateLeadClientDeal(dealId, leadClientId, subject, amount, assigneeId, createdDate);
    }

    public sendLeadClientUpdateDeal(dealId: number, leadClientId: number, subject: string, amount: number, assigneeId: number): Observable<IResponse<number>> {
        return this.dataService.but_UpdateDeal(dealId, leadClientId, subject, amount, assigneeId);
    }

    public getLeadClientDeal(id: number): Observable<IResponse<LeadDeal>> {
        return this.dataService.but_getDealById(id);
    }

    public getLeadClientMeeting(id: number): Observable<IResponse<LeadClientMeeting>> {
        return this.dataService.but_LeadClientGetMeeting(id);
    }

    public CancelLeadClientMeeting(id: number, locale: string): Observable<IResponse<boolean>> {
        return this.dataService.but_LeadClientCancelMeeting(id, locale);
    }

    public CancelLeadClientDeal(id: number): Observable<IResponse<boolean>> {
        return this.dataService.but_LeadClientCancelDeal(id);
    }

    public addMeeting(id: number, leadClientId: number, meetingDate: string, meetingEndDate: string, subject: string, meetingAssigneeId: number, externalId: number,
            note: string, meetingStatus: LeadClientMeetingStatus) {
        return this.dataService.but_LeadClientAddMeeting(id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus);
    }

    public updateMeeting(id: number, leadClientId: number, meetingDate: string, meetingEndDate: string, subject: string, meetingAssigneeId: number, externalId: number,
            note: string, meetingStatus: LeadClientMeetingStatus) {
        return this.dataService.but_LeadClientUpdateMeeting(id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus);
    }

    public getNotes(leadClientId: number) {
        return this.dataService.but_getLeadClientNotes(leadClientId);
    }

    public addNote(leadClientId: number, note: string, assigneeId: number, gpsLongitude: string, gpsLatitude: string, createdDate: string) {
        return this.dataService.but_addLeadClientNote(leadClientId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate);
    }

    public getLeads(leadClientId: number): Observable<IResponse<Lead[]>> {
        return this.dataService.but_getLeadsForLeadClient(leadClientId);
    }

    public getDeals(leadClientId: number): Observable<IResponse<LeadDeal[]>> {
        return this.dataService.but_getDealsForLeadClient(leadClientId);
    }

    public getMeetings(leadClientId: number): Observable<IResponse<LeadClientMeeting[]>> {
        return this.dataService.but_getMeetingsForLeadClient(leadClientId);
    }
}