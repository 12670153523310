import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { PromoPage } from './pages/promo/promo.page';
import { PagesModule } from './pages/pages.module';
import { Page404 } from './pages/notFound/404.page';
import { AuthGuard } from './services/auth.guard';
import { PaymentPage } from './pages/payment/payment.page';
import { ComingsoonPage } from './pages/comingsoon/comingsoon.page';
import { PrivacyPolicyPage } from './pages/privacy-policy/privacy-policy.page';
import { RegistrationPage } from './pages/registration/registration.page';
import { LeadClientsBUTPage } from './leadClients/leadClientsBUTPage/lead.clients.but.page';
import { TestPage } from './pages/test/test.page';

const routes: Routes = [
	//{path: '',  component: HomePage, pathMatch: 'full'},	// /l/MdWfhRcr
	{ path: '', redirectTo: "leadClients", pathMatch: 'full' },
	{ path: "404", component: Page404, pathMatch: 'full' },
	{ path: "comingsoon", component: ComingsoonPage, pathMatch: 'full' },
	{ path: "promo/:token", component: PromoPage, pathMatch: 'full' },
	{ path: "payment/:token", component: PaymentPage, pathMatch: 'full' },
	{ path: "registration/:token", component: RegistrationPage, pathMatch: 'full' },
	{ path: "l", loadChildren: "./leads.byleadtoken/leads.blt.module#LeadsBLTModule" },
	{ path: "leadClients", component: LeadClientsBUTPage, canActivate: [AuthGuard], loadChildren: "./leadClients/lead.clients.but.module#LeadClientsBUTModule" },
	{ path: 'lc/phone', canActivate: [AuthGuard], loadChildren: "./leadClient.byWhatsAppToken/leadClient.bpt.module#LeadClientBPTModule" },
	{ path: 'lc/email', canActivate: [AuthGuard], loadChildren: "./leadClient.byGoogleToken/leadClient.bet.module#LeadClientBETModule" },
	{ path: 'leadClientsByCrmToken', loadChildren: "./leadClientWhatsappChat/lead.client.whatsapp.chat.module#leadClientWhatsappChatModule" },
	{ path: "auth", loadChildren: "./auth/auth.module#AuthModule" },
	{ path: "testpage", component: TestPage, pathMatch: 'full' },
	{ path: "privacy-policy", component: PrivacyPolicyPage, pathMatch: 'full' },
	{ path: "**", redirectTo: "/404" }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules }),
		PagesModule,
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
