import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LangsRepository } from '../../services/langs.repository';
import { ILang } from '../../model/lang.interface';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { AppService } from '../../services/app.service';
import { User } from '../../model/user.model';
import { Location } from '@angular/common';

@Component({
    selector: 'payment-page',
    templateUrl: 'payment.page.html',
    styleUrls: ['payment.page.scss', "../../app.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class PaymentPage implements OnInit {
    public token = "";
    public langsReady = false;
    public step = 1;
    public busy = false;
    public step1Status = "&nbsp;";
    public step1Error = false;
    public step2Status = "&nbsp;";
    public step2Error = false;
    public phone = "";
    public email = "";
    public login = "";
    public emailError = "&nbsp;";
    public phoneError = "&nbsp;";
    public passcodeError = "&nbsp";
    public passcode = "";
    public iframeSrc = "";
    public showSendSms = false;
    public _httpError = "HTTP error";

    constructor(
        private langsRepository: LangsRepository,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private authService: AuthService,
        private appService: AppService,
        private readonly location: Location
    ) {
    }

    ngOnInit(): void {
        this.langsRepository.ready.subscribe(r => {
            this.langsReady = r;
        });

        this.route.params.subscribe(p => {
            this.token = p["token"];
            this.step = 1;
            this.step1Status = "&nbsp;"
            this.step1Error = false;
            this.step2Status = "&nbsp;"
            this.step2Error = false;
            this.emailError = "&nbsp;";
            this.phoneError = "&nbsp;";
            this.passcodeError = "&nbsp;";
            this.passcode = "";
            this.iframeSrc = "";
            this.showSendSms = false;

            this.busy = true;
            this.authService.getPaymentIframe(this.token, this.login).subscribe(res => {
                if (res.responseCode === 200) {
                    this.step1Status = "&nbsp;";
                    this.step1Error = false;
                    this.iframeSrc = res.data.iframeSrc;
                    this.showSendSms = res.data.showSendSms;
                    this.step = 2;
                } else {
                    this.step1Status = res.error;
                    this.step1Error = true;
                }

                this.busy = false;
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError(this._httpError, `${this.currentLang.words["http_error"]} in payment-page init getPaymentIframe: ${message}`, true);
            });
        });
    }

    get currentLang(): ILang { return this.langsRepository.current; }

    public back(): void {
        this.location.back();
    }

    public toStep1(): void {
        this.step = 1;
        this.step1Status = "&nbsp;"
        this.step1Error = false;
        this.step2Status = "&nbsp;"
        this.step2Error = false;
        this.emailError = "&nbsp;";
        this.phoneError = "&nbsp;";
        this.passcodeError = "&nbsp;";
        this.passcode = "";
        this.iframeSrc = "";
        this.showSendSms = false;
    }

    public toStep2(): void {
        this.email = this.email.trim();

        if (this.validateStep1()) {
            this.busy = true;
            this.authService.getPaymentIframe(this.token, this.login).subscribe(res => {
                if (res.responseCode === 200) {
                    this.step1Status = "&nbsp;";
                    this.step1Error = false;
                    this.iframeSrc = res.data.iframeSrc;
                    this.showSendSms = res.data.showSendSms;
                    this.step = 2;
                } else {
                    this.step1Status = res.error;
                    this.step1Error = true;
                }

                this.busy = false;
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError(this._httpError, `${this.currentLang.words["http_error"]} in payment-page toStep2 getPaymentIframe: ${message}`, true);
            });
        }
    }

    public validatePhone(): void {
        this.phone = this.phone.replace(/[^0-9]+/g, "");
    }

    public validateEmail(): boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.email).toLowerCase());
    }

    public validateStep1(): boolean {
        let error: boolean = false;

        if (!this.email.length) {
            error = true;
            this.emailError = this.currentLang.words["enter-email"];
        } else if (!this.validateEmail()) {
            error = true;
            this.emailError = this.currentLang.words["correct-email"];
        } else {
            this.emailError = "&nbsp;"
        }

        if (!this.phone.length) {
            error = true;
            this.phoneError = this.currentLang.words["enter-phone"];
        } else {
            this.phoneError = "&nbsp;"
        }

        return !error;
    }

    public validateStep2(): boolean {
        let error: boolean = false;

        if (!this.passcode.length) {
            error = true;
            this.passcodeError = this.currentLang.words["enter-passcode"];
        } else {
            this.passcodeError = "&nbsp;";
        }

        return !error;
    }

    public finish(): void {
        this.passcode = this.passcode.trim();

        if (this.validateStep2()) {
            this.busy = true;
            this.authService.addUserBySms(this.token, this.passcode, this.login).subscribe(res => {
                if (res.body.responseCode === 200) {
                    let token: string | null = res.headers.get("X-CLIENT-TOKEN");
                    let user: User = res.body.data;

                    if (token && user) {
                        this.step2Status = "&nbsp;";
                        this.step2Error = false;
                        this.authService.saveAuthData({ token, user });
                        this.navCtrl.navigateForward("/leads");
                    } else {
                        this.step2Status = this.currentLang.words["token-not-found"];
                        this.step2Error = true;
                    }
                } else {
                    this.step2Status = res.body.error;
                    this.step2Error = true;
                }

                this.busy = false;
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError(this._httpError, `${this.currentLang.words["http_error"]} in payment-page finish addUserBySms: ${message}`, true);
            });
        }
    }
}
