import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { StatusType } from 'src/app/Enums/status.type.enum';
import { ILang } from 'src/app/model/lang.interface';
import { IProcess } from 'src/app/model/process.interface';
import { ISubstatus } from 'src/app/model/substatus.interface';
import { LangsRepository } from 'src/app/services/langs.repository';
import { LeadClientRepositoryBUT } from 'src/app/services/lead.clients.repository.but';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'process-replace-overlay',
    templateUrl: 'processes.overlay.replace.html',
    styleUrls: ["../../app.component.scss", "processes.overlay.replace.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class ProcessesReplaceOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() processId: number = 0;

    public langsReady = false;
    public substatusesReady = false;
    public processesReady = false;
    public targetProcessId: number = null;

    constructor(
        private langsRepository: LangsRepository,
        private processesRepository: ProcessesRepositoryBUT,
        private appService: AppService,
        private leadClientsRepository: LeadClientRepositoryBUT,
        private substatusesRepository: SubstatusesRepository,
    ) {
        this.ready = true;
        this.init();
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
        this.targetProcessId = null;
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) this.langsReady = true; });
    }

    get processes(): IProcess[] { return this.processesRepository.processes.filter(x => x.id != this.processId); }
    get targetProcess(): IProcess { return this.processesRepository.processes.find(x => x.id == this.targetProcessId); }
    get substatusesFromTargetProcess(): ISubstatus[] {
        var list = [];
        if (this.targetProcessId) {
            list.push(...this.targetProcess.statuses.find(x => x.id == StatusType.InProcess).subStatuses.filter(x => x.isSelected));
            list.push(...this.targetProcess.statuses.find(x => x.id == StatusType.Meeting).subStatuses.filter(x => x.isSelected));
            list.push(...this.targetProcess.statuses.find(x => x.id == StatusType.Closed).subStatuses.filter(x => x.isSelected));
            list.push(...this.targetProcess.statuses.find(x => x.id == StatusType.NotGood).subStatuses.filter(x => x.isSelected));
        }

        return list;
    }

    // get substatuses(): ISubstatus[] { return this.substatusesRepository.substatuses; }
    get subStatusForInProcess(): ISubstatus[] { return this.substatusesFromTargetProcess.filter(x => x.statusId == StatusType.InProcess); }
    get subStatusForMeetingProcess(): ISubstatus[] { return this.substatusesFromTargetProcess.filter(x => x.statusId == StatusType.Meeting); }
    get subStatusForDealProcess(): ISubstatus[] { return this.substatusesFromTargetProcess.filter(x => x.statusId == StatusType.Closed); }
    get subStatusForNotGoodProcess(): ISubstatus[] { return this.substatusesFromTargetProcess.filter(x => x.statusId == StatusType.NotGood); }

    public setTargetProcess(pr: IProcess): void {
        this.targetProcessId = pr.id;
    }

    public delete(ss: ISubstatus): void {
        if (confirm(this.currentLang.words["sure"])) {
            this.appService.showNetworkstatus("sending data...");
            var sub = this.processesRepository.processes.find(x => x.id == this.processId);
            sub.targetProcessId = this.targetProcessId;
            sub.targetSubStatusId = ss.id;
            this.processesRepository.deleteProcess(sub).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.processesRepository.load();
                } else this.appService.showError("API error", res.error || res.Error, true, res.responseCode);

                this.close();
                this.targetProcessId = null;
            
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in process-replace-overlay delete deleteProcess: ${message}`, true);
            });

            var leadClients = this.appService.leadClients.filter(x=>x.processId == sub.id);
            leadClients.forEach((leadClient)=> {
                leadClient.processId = sub.targetProcessId;
                leadClient.additionalStatusId = sub.targetSubStatusId;
                leadClient.leadStatusId = ss.statusId;
            })

            this.processesRepository.delete(sub);
        }
    }

    public replaceToGlobalStatus(statusId: number) {
        if (confirm(this.currentLang.words["sure"])) {
            var ss: ISubstatus = { statusId: statusId, position: 0, replaceStatusId: 0, id : 0 };
            this.appService.showNetworkstatus("sending data...");
            var sub = this.processesRepository.processes.find(x => x.id == this.processId);
            sub.targetProcessId = this.targetProcessId;
            sub.targetSubStatusId = ss.id;
            sub.targetLeadStatusId = statusId;
            this.processesRepository.deleteProcess(sub).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.processesRepository.load();
                } else this.appService.showError("API error", res.error || res.Error, true, res.responseCode);

                this.close();
                this.targetProcessId = null;
            
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in process-replace-overlay replaceToGlobalStatus deleteProcess: ${message}`, true);
            });

            var leadClients = this.appService.leadClients.filter(x=>x.processId == sub.id);
            leadClients.forEach((leadClient)=> {
                leadClient.processId = sub.targetProcessId;
                leadClient.additionalStatusId = sub.targetSubStatusId;
                leadClient.leadStatusId = ss.statusId;
            })

            this.processesRepository.delete(sub);
        }
    }
}