/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../../app.component.scss.shim.ngstyle";
import * as i1 from "./notifications.settings.edit.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./notifications.settings.edit.component";
var styles_SettingsNotificationsEditComponent = [i0.styles, i1.styles];
var RenderType_SettingsNotificationsEditComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_SettingsNotificationsEditComponent, data: {} });
export { RenderType_SettingsNotificationsEditComponent as RenderType_SettingsNotificationsEditComponent };
function View_SettingsNotificationsEditComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 24, "div", [["class", "setting notification-setting-edit-component"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 5, "div", [["class", "edit-param-switch"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "div", [["class", "switch"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.user.isPushNotifications = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, [" SignalR"])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.user.isPushNotifications = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, [" Push Notifications"])), (_l()(), i2.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 3, "div", [["class", "edit-param"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.user.sendPushNewLead = !_co.user.sendPushNewLead) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 1, "div", [["class", "settings-title"], ["style", "margin-left: 10px;margin-right: 10px;width: 85%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(12, null, ["", ""])), (_l()(), i2.ɵeld(13, 0, null, null, 3, "div", [["class", "edit-param"]], null, null, null, null, null)), (_l()(), i2.ɵeld(14, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.user.sendPushFollowUp = !_co.user.sendPushFollowUp) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(15, 0, null, null, 1, "div", [["class", "settings-title"], ["style", "margin-left: 10px;margin-right: 10px;width: 85%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(16, null, ["", ""])), (_l()(), i2.ɵeld(17, 0, null, null, 3, "div", [["class", "edit-param"]], null, null, null, null, null)), (_l()(), i2.ɵeld(18, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.user.sendMeetingCreatedEmail = !_co.user.sendMeetingCreatedEmail) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(19, 0, null, null, 1, "div", [["class", "settings-title"], ["style", "margin-left: 10px;margin-right: 10px;width: 85%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(20, null, ["", ""])), (_l()(), i2.ɵeld(21, 0, null, null, 3, "div", [["class", "edit-param"]], null, null, null, null, null)), (_l()(), i2.ɵeld(22, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = ((_co.user.sendMeetingCanceledEmail = !_co.user.sendMeetingCanceledEmail) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(23, 0, null, null, 1, "div", [["class", "settings-title"], ["style", "margin-left: 10px;margin-right: 10px;width: 85%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(24, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("switch-state" + (!_co.user.isPushNotifications ? " active" : "")); _ck(_v, 3, 0, currVal_0); var currVal_1 = ("switch-state" + (_co.user.isPushNotifications ? " active" : "")); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.user.sendPushNewLead; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.currentLang.words["notification-settings-option-1"]; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.user.sendPushFollowUp; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.currentLang.words["notification-settings-option-2"]; _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.user.sendMeetingCreatedEmail; _ck(_v, 18, 0, currVal_6); var currVal_7 = _co.currentLang.words["notification-settings-option-3"]; _ck(_v, 20, 0, currVal_7); var currVal_8 = _co.user.sendMeetingCanceledEmail; _ck(_v, 22, 0, currVal_8); var currVal_9 = _co.currentLang.words["notification-settings-option-4"]; _ck(_v, 24, 0, currVal_9); }); }
export function View_SettingsNotificationsEditComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_SettingsNotificationsEditComponent_1)), i2.ɵdid(1, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentLang && _co.user); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SettingsNotificationsEditComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "notification-settings-edit", [], null, null, null, View_SettingsNotificationsEditComponent_0, RenderType_SettingsNotificationsEditComponent)), i2.ɵdid(1, 49152, null, 0, i4.SettingsNotificationsEditComponent, [], null, null)], null, null); }
var SettingsNotificationsEditComponentNgFactory = i2.ɵccf("notification-settings-edit", i4.SettingsNotificationsEditComponent, View_SettingsNotificationsEditComponent_Host_0, { user: "user", currentLang: "currentLang" }, {}, []);
export { SettingsNotificationsEditComponentNgFactory as SettingsNotificationsEditComponentNgFactory };
