import { EventEmitter, Inject, Injectable, isDevMode } from "@angular/core";
import * as Sentry from "@sentry/angular"
import { Integrations } from '@sentry/tracing';
import { Config } from "./error-handler.module";

export interface Options extends Sentry.ErrorHandlerOptions { }

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService extends Sentry.SentryErrorHandler {
  // onError = new EventEmitter();

  public static skipErrorLog(error: any): boolean {
    let message = "";
    if (error instanceof Error) {
      message = error.message;
    } else if (typeof error === 'object') {
      message = JSON.stringify(error);
    } else {
      message = String(error);
    }
    if (typeof message === "string" && (
          message.includes("WebSocket failed to connect") // signalR cannot connect to server
          || message.includes("Unknown Error"))) {  // httpClient cannot connect to server
      return true;
    }
    return false;
  }

  constructor(
    @Inject('config') config?: Config,
  ) {
    super(config.options);
    this._options.extractor = (e: any) => e.rejection || this._defaultExtractor(e);
    const release = config.release && (typeof config.release === 'string' ? config.release : config.release());
    Sentry.init({
      release,
      environment: config.environment,
      dsn: config.dsn,
      integrations: config.browserTracking !== false ? [
        new Integrations.BrowserTracing(),
      ] : [],
      tracesSampleRate: config.tracesSampleRate,
      beforeSend(event: Sentry.Event, hint: Sentry.EventHint) {
        try {
          if (hint!.originalException instanceof Error) {
            const err = hint.originalException as Error;

            // disable sentry log of errors that cannot be handled in handleError
            if (ErrorHandlerService.skipErrorLog(err.message)) {
              return null;
            }
          }
        } catch (err) {}
        return event;
      },
    });
  }

  setShowDialog(show: boolean) {
    this._options.showDialog = show;
  }
  
  handleError(error) {
    if (!ErrorHandlerService.skipErrorLog(error)) {
      // this.onError.emit(error);
      Sentry.withScope(scope => {
        scope.setExtra("errorExtraData", error);
        scope.setExtra("handlerSource", "service");
        super.handleError(error);
      });
    }

    // do not catch in dev mode
    // if (isDevMode()) {
    //   console.error(error);
    // } else {
    //   super.handleError(error);
    // }
  }
}
