import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Calendar } from '@ionic-native/calendar/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AppService } from './app.service';
import { DataService } from './DataServices/data.service';
import { LeadsRepositoryBLT } from './leads.repository.blt';
import { LeadsRepositoryBUT } from './leads.repository.but';
import { AssigneesRepositoryBLT } from './assignees.repository.blt';
import { AssigneesRepositoryBUT } from './assignees.repository.but';
import { ProcessesRepositoryBLT } from './processes.repository.blt';
import { ProcessesRepositoryBUT } from './processes.repository.but';
import { LangsRepository } from './langs.repository';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { SettingsRepository } from './settings.repository';
import { SubstatusesRepository } from './substatuses.repository';
import { UsersRepository } from './users.repository';
import { CallService } from './call.service';
import { LogService } from './log.service';
import { NotificationService } from './notification.service';
import { WhatsappService } from './whatsapp.service';
import { LeadClientRepositoryBUT } from './lead.clients.repository.but';
import { LeadClientRepositoryBPT } from './bptRepository/leadClient.bpt.repository';
import { WhatsAppDataService } from './DataServices/whatsApp.data.service';
import { AssigneesRepositoryBPT } from './bptRepository/assignees.bpt.repository';
import { ProcessesRepositoryBPT } from './bptRepository/processes.bpt.repository';
import { SubstatusesRepositoryBPT } from './bptRepository/substatuses.bpt.repository';

var dataProviders = [DataService, WhatsAppDataService];
var butRepositories = [
    LeadsRepositoryBUT,
    AssigneesRepositoryBUT,
    ProcessesRepositoryBUT,
    LeadClientRepositoryBUT
];

var bltRepositories = [
    LeadsRepositoryBLT,
    AssigneesRepositoryBLT,
    ProcessesRepositoryBLT
];

var bptRepositories = [
    LeadClientRepositoryBPT,
    AssigneesRepositoryBPT,
    ProcessesRepositoryBPT,
    SubstatusesRepositoryBPT
];

var repositories = [
    LangsRepository,
    SettingsRepository,
    SubstatusesRepository,
    UsersRepository,
];
var services = [
    AppService,
    LogService,
    NotificationService,
    AuthGuard,
    AuthService,
    CallService,
    WhatsappService,
];

var commonModules = [
    Calendar,
    FirebaseX,
    AndroidPermissions
];


var providers = [...dataProviders, 
    ...repositories,
    ...butRepositories, 
    ...bptRepositories, 
    ...bltRepositories, 
    ...services, 
    ...commonModules];

@NgModule({
    declarations: [],
    exports: [],
    imports: [
        HttpClientModule,
    ],
    providers: providers
})
export class ServicesModule { }
