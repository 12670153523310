/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.ngstyle";
import * as i1 from "./processes.overlay.edit.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../iface.components/process/process.edit.component.ngfactory";
import * as i4 from "../../iface.components/process/process.edit.component";
import * as i5 from "@angular/common";
import * as i6 from "./processes.overlay.edit";
import * as i7 from "../../services/langs.repository";
import * as i8 from "../../services/substatuses.repository";
import * as i9 from "../../services/processes.repository.but";
import * as i10 from "../../services/leads.repository.but";
import * as i11 from "../../services/app.service";
var styles_ProcessesEditOverlayComponent = [i0.styles, i1.styles];
var RenderType_ProcessesEditOverlayComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_ProcessesEditOverlayComponent, data: {} });
export { RenderType_ProcessesEditOverlayComponent as RenderType_ProcessesEditOverlayComponent };
function View_ProcessesEditOverlayComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 18, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 16, "div", [["class", "ol-main"], ["style", "width: 400px;"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "div", [["class", "ol-head grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "ol-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "ol-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 10, "div", [["class", "ol-content"], ["style", "height: calc(100% - 44px);"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 9, "div", [["class", "full"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 8, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 1, "process-edit", [], null, null, null, i3.View_ProcessEditComponent_0, i3.RenderType_ProcessEditComponent)), i2.ɵdid(12, 49152, null, 0, i4.ProcessEditComponent, [], { process: [0, "process"], currentLang: [1, "currentLang"] }, null), (_l()(), i2.ɵeld(13, 0, null, null, 4, "div", [["class", "edit edit-button"], ["style", "margin-top: 20px"]], null, null, null, null, null)), (_l()(), i2.ɵeld(14, 0, null, null, 3, "div", [["class", "edit-param"]], null, null, null, null, null)), (_l()(), i2.ɵeld(15, 0, null, null, 2, "button", [["class", "big opaque"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(16, 0, null, null, 1, "span", [["class", "ico-check-in-btn"]], null, null, null, null, null)), (_l()(), i2.ɵted(17, null, ["", ""])), (_l()(), i2.ɵeld(18, 0, null, null, 0, "div", [["class", "ol-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.process; var currVal_3 = _co.currentLang; _ck(_v, 12, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? (("overlay clientOverlay clientSubstatusOverlayEdit " + _co.currentLang.dir) + " active") : ("overlay clientOverlay " + _co.currentLang.dir)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.currentLang.words["edit-process"]; _ck(_v, 4, 0, currVal_1); var currVal_4 = _co.currentLang.words["save"]; _ck(_v, 17, 0, currVal_4); }); }
export function View_ProcessesEditOverlayComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesEditOverlayComponent_1)), i2.ɵdid(1, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProcessesEditOverlayComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "processes-edit-overlay", [], null, null, null, View_ProcessesEditOverlayComponent_0, RenderType_ProcessesEditOverlayComponent)), i2.ɵdid(1, 49152, null, 0, i6.ProcessesEditOverlayComponent, [i7.LangsRepository, i8.SubstatusesRepository, i9.ProcessesRepositoryBUT, i10.LeadsRepositoryBUT, i11.AppService], null, null)], null, null); }
var ProcessesEditOverlayComponentNgFactory = i2.ɵccf("processes-edit-overlay", i6.ProcessesEditOverlayComponent, View_ProcessesEditOverlayComponent_Host_0, { active: "active", currentLang: "currentLang", process: "process" }, { activeChange: "activeChange", cancel: "cancel" }, []);
export { ProcessesEditOverlayComponentNgFactory as ProcessesEditOverlayComponentNgFactory };
