import { Injectable } from "@angular/core";
import { DataService } from './DataServices/data.service';
import { AssigneesRepository } from './assignees.repository';
import { AppService } from './app.service';
import { LangsRepository } from "./langs.repository";

@Injectable()
export class AssigneesRepositoryBUT extends AssigneesRepository {
    public lastReload = 0;
    public cacheKey: string = "";

    constructor(
        private dataService: DataService,
        private appService: AppService,
        private langsRepository: LangsRepository
    ) { super(); 
        this.cacheKey = this.getCurrentCacheKeyFromLocalStorage();
    }

    public load(): void {
        this.dataService.token.subscribe(token => {
            this.ready.next(false);
            this.assignees = [];
            if (token) {
                this.dataService.but_getAssignees().subscribe(res => {
                    if (res.responseCode === 200) {
                        this.setCacheKeyIfNotExistToLocalStorage(res.settingsCacheToken)
                        this.assignees = (res.data.length) ? res.data : [];
                        this.lastReload = (new Date()).getTime();
                        this.ready.next(true);
                    } else {
                        this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, err => {
                    const message = this.appService.getErrorMessage(err);
                    this.appService.showError("HTTP error", `${this.langsRepository.current.words["http_error"]} in AssigneesRepositoryBUT load but_getAssignees: ${message}`, true);
                });
            }
        });
    }

    public prepare(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.ready.value && this.getCurrentCacheKeyFromLocalStorage() == this.cacheKey) {
                resolve();
            } else {
                this.load();
                this.ready.subscribe(r => {
                    if (r) {
                        resolve();
                        this.updateCacheKeyInLocalStorage(this.cacheKey);
                    }
                });
            }
        });
    }

    public setCacheKeyIfNotExistToLocalStorage(key: string) {
        var storeKey = localStorage.getItem('cacheKey');
        if (!storeKey) localStorage.setItem('cacheKey', key);
    }

    public getCurrentCacheKeyFromLocalStorage() {
        return localStorage.getItem('cacheKey');
    }

    public updateCacheKeyInLocalStorage(key: string) {
        localStorage.setItem('cacheKey', key);
    }
}
