import { Injectable, } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import * as Sentry from "@sentry/angular"
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { AuthService } from "./auth.service";
import { LangsRepository } from './langs.repository';

@Injectable()
export class AuthGuard {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService,
        private readonly appService: AppService,
        private langsRepository: LangsRepository,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            if (!this.authService.getToken()) {
                this.authService.prevUrl = state.url;
                this.router.navigateByUrl("/auth/login");
                resolve(false);
            } else {
                this.authService.getClientToken().toPromise().then(response => {
                    if (response.responseCode !== 200) {
                        this.authService.logout();
                        this.authService.prevUrl = state.url;
                        this.router.navigateByUrl("/auth/login");
                        this.appService.showError(response.responseCode, response.error, false, response.responseCode);
                        resolve(false);
                    }
                    if (this.authService!.user!.id) {
                        Sentry.configureScope(scope => {
                          scope.setUser({
                              id: this.authService.user.id.toString(),
                              email: this.authService.user.email,
                              username: this.authService.user.firstName + " " + this.authService.user.lastName,
                          });
                        });
                    }
                    resolve(true);
                }).catch(err => {
                    this.authService.logout();
                    this.authService.prevUrl = state.url;
                    this.router.navigateByUrl("/auth/login");
                    const message = this.appService.getErrorMessage(err);
                    this.appService.showError(err.name, `${this.langsRepository.current.words["http_error"]} in AuthGuard canActivate: ${message}`, false, 401);
                    resolve(false);
                });
            }
        });
    }
}
