/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./version.update.popup";
var styles_VersionUpdateComponent = [i0.styles];
var RenderType_VersionUpdateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VersionUpdateComponent, data: {} });
export { RenderType_VersionUpdateComponent as RenderType_VersionUpdateComponent };
function View_VersionUpdateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "popup-flexblock center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink($event, _co.currentLang.words["google-play-link"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "google play"], ["src", "../assets/img/google-play.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["google-play-link"]; _ck(_v, 1, 0, currVal_0); }); }
function View_VersionUpdateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "popup-flexblock center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLink($event, _co.currentLang.words["app-store-link"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "app store"], ["src", "../assets/img/app-store.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["app-store-link"]; _ck(_v, 1, 0, currVal_0); }); }
export function View_VersionUpdateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [], [[8, "lang", 0], [8, "className", 0], [8, "dir", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPopupClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "popup-win"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "popup-head grad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "popup-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "popup-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "popup-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "popup-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VersionUpdateComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VersionUpdateComponent_2)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [["class", "popup-foot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "popup-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = !_co.isIOS; _ck(_v, 13, 0, currVal_5); var currVal_6 = _co.isIOS; _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.slug; var currVal_1 = (_co.active ? "popup active" : "popup"); var currVal_2 = _co.currentLang.dir; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.currentLang.words["version-update-popup-title"]; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.currentLang.words["version-update-popup-description"]; _ck(_v, 10, 0, currVal_4); var currVal_7 = _co.currentLang.words["close"]; _ck(_v, 18, 0, currVal_7); }); }
export function View_VersionUpdateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "version-update-popup", [], null, null, null, View_VersionUpdateComponent_0, RenderType_VersionUpdateComponent)), i1.ɵdid(1, 49152, null, 0, i3.VersionUpdateComponent, [], null, null)], null, null); }
var VersionUpdateComponentNgFactory = i1.ɵccf("version-update-popup", i3.VersionUpdateComponent, View_VersionUpdateComponent_Host_0, { active: "active", currentLang: "currentLang", isIOS: "isIOS" }, { activeChange: "activeChange" }, []);
export { VersionUpdateComponentNgFactory as VersionUpdateComponentNgFactory };
