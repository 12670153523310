<div class="edit substatus-edit-component" *ngIf="currentLang && substatus && statuses">
    <div class="edit-param">
        <label [class]="substatus.title.length ? '' : 'empty'">{{currentLang.words["name"]}} <span class="required"></span></label>
        <label *ngIf="substatus.title && substatus.title.length" class="empty clear-button"(click)="clearInput('title')">X</label>
        <input type="text" name="title" [(ngModel)]="substatus.title">
        <div class="bg-white"></div>
    </div>
    <div class="edit-status error" [innerHTML]="substatus.titleError"></div>
    <div style="display: none" class="edit-param edit-param-select">
        <label [class]="substatus.statusId !== null ? '' : 'empty'">{{currentLang.words["stage"]}} <span
                class="required"></span></label>
        <select name="status" [(ngModel)]="substatus.statusId">
            <option [ngValue]="null"></option>
            <ng-container *ngFor="let s of statuses">
                <option *ngIf="s.id" [ngValue]="s.id">{{s.title}}</option> <!-- exclude "new" status (id==0) -->
            </ng-container>
        </select>
        <div class="bg-white select-bg"></div>
    </div>
    <div class="edit-status error" [innerHTML]="substatus.statusIdError"></div>
    <div class="edit-param">
        <label [class]="!isEmpty(substatus.position) ? '' : 'empty'">{{currentLang.words["position"]}}</label>
        <input type="number" name="position" class="position-input" [(ngModel)]="substatus.position" (keyup)="validateNumber()" (keydown)="validateNumber()"
            pattern="[0-9]*">
        <div class="bg-white"></div>
    </div>
    <div class="edit-status error" [innerHTML]="substatus.positionError"></div>

    <div class="edit-param">
        <ion-checkbox style="vertical-align: middle;" slot="end" name="advanced" ngDefaultControl
            [(ngModel)]="advanced" id="advanced" (click)="enableAdvanced()"></ion-checkbox>
        <ion-label style="padding: 0 10px;">{{currentLang.words["advanced"]}}</ion-label>
    </div>
    <div class="edit-param" *ngIf="advanced">
        <label style="transform: translateY(0px); padding: 0;" [class]="substatus.template && substatus.template.length ? '' : 'empty'">{{currentLang.words["leads-preview"]}}</label>
        <textarea #template [(ngModel)]="substatus.template"></textarea>
    </div>
    <div class="edit-param" *ngIf="advanced">
        <label>{{currentLang.words["avail-fields"]}}</label>
        <div class="tags" (click)="addTemplateElement ($event);">
            <div class="tag">{{'{'}}fullname}</div>
            <div class="tag">{{'{'}}email}</div>
            <div class="tag">{{'{'}}phone}</div>
            <div class="tag">{{'{'}}stage}</div>
            <div class="tag">{{'{'}}status}</div>
            <div class="tag">{{'{'}}callstatus}</div>
            <div class="tag">{{'{'}}recording-url}</div>
            <!--<div class="tag">{{'{'}}process}</div>-->
            <div class="tag">{{'{'}}campaign}</div>
            <div class="tag">{{'{'}}referer}</div>
            <div class="tag">{{'{'}}followup}</div>
            <!--<div class="tag">{{'{'}}price}</div>
            <div class="tag">{{'{'}}meeting-price}</div>
            <div class="tag">{{'{'}}deal-price}</div>-->
            <div class="tag">{{'{'}}deal-amount}</div>
        </div>
    </div>
</div>