import { Injectable } from "@angular/core";

@Injectable()
export class LogService {
  public logs: LogItem[] = [];

  add(text: string) {
    this.logs.push({
      date: new Date(),
      text
    });
  }

  clear() {
    this.logs = [];
  }
}
