/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promo.page.scss.shim.ngstyle";
import * as i1 from "../../app.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/router";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/common";
import * as i6 from "./promo.page";
import * as i7 from "../../services/langs.repository";
var styles_PromoPage = [i0.styles, i1.styles];
var RenderType_PromoPage = i2.ɵcrt({ encapsulation: 0, styles: styles_PromoPage, data: {} });
export { RenderType_PromoPage as RenderType_PromoPage };
function View_PromoPage_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 21, "div", [["id", "wrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 4, "header", [["class", "grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "div", [["id", "logo"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 2, "div", [["id", "h-left"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "a", [["class", "back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(5, null, ["", ""])), (_l()(), i2.ɵeld(6, 0, null, null, 15, "div", [["id", "main"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 14, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 13, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 12, "div", [["class", "promo"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 4, "div", [["class", "promo-btnwrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 12).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i2.ɵnov(_v, 13).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i2.ɵdid(12, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i2.Renderer2, i2.ElementRef], { routerLink: [0, "routerLink"] }, null), i2.ɵdid(13, 737280, null, 0, i4.RouterLinkDelegate, [i5.LocationStrategy, i4.NavController, i2.ElementRef, i3.Router, [2, i3.RouterLink]], null, null), (_l()(), i2.ɵted(14, null, ["", ""])), (_l()(), i2.ɵeld(15, 0, null, null, 4, "div", [["class", "promo-btnwrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(16, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 17).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i2.ɵnov(_v, 18).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i2.ɵdid(17, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i2.Renderer2, i2.ElementRef], { routerLink: [0, "routerLink"] }, null), i2.ɵdid(18, 737280, null, 0, i4.RouterLinkDelegate, [i5.LocationStrategy, i4.NavController, i2.ElementRef, i3.Router, [2, i3.RouterLink]], null, null), (_l()(), i2.ɵted(19, null, ["", ""])), (_l()(), i2.ɵeld(20, 0, null, null, 1, "div", [["class", "promo-imgwrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(21, 0, null, null, 0, "img", [["alt", "coming soon"], ["src", "../../assets/img/106.png"], ["style", "width: 70%;"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ("/registration/" + _co.token); _ck(_v, 12, 0, currVal_2); _ck(_v, 13, 0); var currVal_4 = ("/auth/login/" + _co.token); _ck(_v, 17, 0, currVal_4); _ck(_v, 18, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["back"]; _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.currentLang.dir === "rtl") ? "rtl" : "ltr"); _ck(_v, 6, 0, currVal_1); var currVal_3 = _co.currentLang.words["payment-form"]; _ck(_v, 14, 0, currVal_3); var currVal_5 = _co.currentLang.words["signin"]; _ck(_v, 19, 0, currVal_5); }); }
export function View_PromoPage_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_PromoPage_1)), i2.ɵdid(1, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.langsReady; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PromoPage_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "promo-page", [], null, null, null, View_PromoPage_0, RenderType_PromoPage)), i2.ɵdid(1, 49152, null, 0, i6.PromoPage, [i7.LangsRepository, i3.ActivatedRoute, i5.Location], null, null)], null, null); }
var PromoPageNgFactory = i2.ɵccf("promo-page", i6.PromoPage, View_PromoPage_Host_0, {}, {}, []);
export { PromoPageNgFactory as PromoPageNgFactory };
