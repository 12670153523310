/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.ngstyle";
import * as i1 from "./substatus.overlay.page.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../substatuses.page.ngfactory";
import * as i4 from "../substatuses.page";
import * as i5 from "../../services/langs.repository";
import * as i6 from "../../services/substatuses.repository";
import * as i7 from "../../services/processes.repository.but";
import * as i8 from "../../services/auth.service";
import * as i9 from "../../services/app.service";
import * as i10 from "@angular/common";
import * as i11 from "./substatus.overlay.page";
var styles_SubStatusViewOverlayComponent = [i0.styles, i1.styles];
var RenderType_SubStatusViewOverlayComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_SubStatusViewOverlayComponent, data: {} });
export { RenderType_SubStatusViewOverlayComponent as RenderType_SubStatusViewOverlayComponent };
function View_SubStatusViewOverlayComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 8, "div", [["class", "ol-main"], ["style", "width: 400px;"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "div", [["class", "ol-head grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "ol-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "ol-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 2, "div", [["class", "ol-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 1, "substatuses-page", [], null, null, null, i3.View_SubstatusesPage_0, i3.RenderType_SubstatusesPage)), i2.ɵdid(9, 49152, null, 0, i4.SubstatusesPage, [i5.LangsRepository, i6.SubstatusesRepository, i7.ProcessesRepositoryBUT, i8.AuthService, i9.AppService, i10.Location], null, null), (_l()(), i2.ɵeld(10, 0, null, null, 0, "div", [["class", "ol-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? (("overlay clientSubstatusOverlay clientOverlay " + _co.currentLang.dir) + " active") : ("overlay clientOverlay " + _co.currentLang.dir)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.currentLang.words["menu-statuses"]; _ck(_v, 4, 0, currVal_1); }); }
export function View_SubStatusViewOverlayComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_SubStatusViewOverlayComponent_1)), i2.ɵdid(1, 16384, null, 0, i10.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SubStatusViewOverlayComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "substatus-view-overlay", [], null, null, null, View_SubStatusViewOverlayComponent_0, RenderType_SubStatusViewOverlayComponent)), i2.ɵdid(1, 49152, null, 0, i11.SubStatusViewOverlayComponent, [i9.AppService], null, null)], null, null); }
var SubStatusViewOverlayComponentNgFactory = i2.ɵccf("substatus-view-overlay", i11.SubStatusViewOverlayComponent, View_SubStatusViewOverlayComponent_Host_0, { active: "active", currentLang: "currentLang" }, { activeChange: "activeChange", cancel: "cancel" }, []);
export { SubStatusViewOverlayComponentNgFactory as SubStatusViewOverlayComponentNgFactory };
