import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { ILang } from '../../model/lang.interface';
import { LeadClient } from '../../model/leadClients/lead.client.model';
import { IStatus } from '../../model/status.interface';
import { ISubstatus } from '../../model/substatus.interface';
import { IMyDpOptions } from 'mydatepicker';
import { IMyDpShortDate } from '../../model/dp.shortdate.interface';
import { AppService } from '../../services/app.service';
import { LeadClientDatetime } from '../../model/leadClients/lead.client.datetime';
import { StatusType } from "../../Enums/status.type.enum";

@Component({
    selector: 'lead-client-statuses-win',
    templateUrl: 'lead.clients.statuses.win.html',
    styleUrls: ["../../app.component.scss", "./lead.clients.statuses.win.scss"],
})
export class LeadStatusesWinComponent implements OnChanges, OnInit {
    @Input() active: boolean;
    @Input() currentLang: ILang;
    @Input() leadClient: LeadClient;
    @Input() statuses: IStatus[];
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter();
    @Output() leadClientParamsChange: EventEmitter<boolean> = new EventEmitter();
    @Output() leadClientFollowupChange: EventEmitter<boolean> = new EventEmitter();

    public step: number = 1;
    public substatuses: ISubstatus[] = [];
    public amount: number = 0;
    public amountRequiredError: string = "";
    // calendar
    public calendarOptions: IMyDpOptions = {
        inline: true,
        showTodayBtn: false,
        selectorWidth: "100%",
    };
    public currentDate: IMyDpShortDate = {};
    public currentHour: number = 0;
    public currentMinute: number = 0;
    public calendarReady: boolean = false;
    public hours: number[] = [];
    public minutes: number[] = [];

    constructor(private appService: AppService) { }

    public ngOnInit(): void {
        this.hours = this.appService.range(0, 23);
        this.minutes = this.appService.range(0, 59);

        this.calendarOptions.dayLabels = this.currentLang.slug === 'he'
            ? { su: 'א', mo: 'ב', tu: 'ג', we: 'ד', th: 'ה', fr: 'ו', sa: 'ש' }
            : { su: 'Sun', mo: 'Mon', tu: 'Tue', we: 'Wed', th: 'Thu', fr: 'Fri', sa: 'Sat' };

        this.calendarOptions.monthLabels = this.currentLang.slug === 'he'
            ? { 1: 'ינואר', 2: 'פברואר', 3: 'מרץ', 4: 'אפריל', 5: 'מאי', 6: 'יוני', 7: 'יולי', 8: 'אוגוסט', 9: 'ספטמבר', 10: 'אוקטובר', 11: 'נובמבר', 12: 'דצמבר' }
            : { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };

    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.step = 1;
        this.substatuses = [];

        if (this.leadClient) {
            this.currentDate = this.leadClient.calendarFollowupDate;
            this.currentHour = this.leadClient.followupHour;
            this.currentMinute = this.leadClient.followupMinute;
            this.calendarReady = true;
        } else {
            this.calendarReady = false;
        }
    }

    get title(): string {
        switch (this.step) {
            case 1:
                return this.currentLang.words["stage"];
            case 2:
                return this.currentLang.words["status"];
            case 3:
                return this.currentLang.words["set-amount"];
            case 4:
                return this.currentLang.words["save-meeting"];
            default:
                return "";
        }
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public apply(): void {
        this.amountRequiredError = "";

        if (this.step === 3 && this.amount <= 0) {
            this.amountRequiredError = this.currentLang.words["amount-required"];
            return;
        }
        this.active = false;
        this.activeChange.emit(false);
    }

    public setLeadStatus(statusId: number): void {
        if (this.leadClient.leadStatusId !== statusId) {
            this.leadClient.leadStatusId = statusId;
            this.leadClient.additionalStatusId = null;
            this.leadClientParamsChange.emit(true);
        }

        let currentStatus: IStatus | null = this.statuses.find(s => s.id === statusId) || null;

        // next step if needed
        if (currentStatus && currentStatus.subStatuses.filter(x => x.isSelected || !this.leadClient.processId).length) {
            this.substatuses = currentStatus.subStatuses.filter(x => x.isSelected || !this.leadClient.processId);
            this.step = 2;
        } else if (this.leadClient.leadStatusId === StatusType.Closed) {
            this.step = 3;
        } else if (this.leadClient.leadStatusId === StatusType.Meeting) {
            this.step = 4;

            this.currentDate = { date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } };
            this.currentHour = new Date().getHours();
            this.currentMinute = new Date().getMinutes();
        }
    }

    public setLeadSubstatus(substatusId): void {
        if (this.leadClient.additionalStatusId !== substatusId) {
            this.leadClient.additionalStatusId = substatusId;
            this.leadClientParamsChange.emit(true);
        }

        // next step if needed
        if (this.leadClient.leadStatusId === StatusType.Closed) {
            this.step = 3;
        } else if (this.leadClient.leadStatusId === StatusType.Meeting) {
            this.step = 4;

            this.currentDate = { date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } };
            this.currentHour = new Date().getHours();
            this.currentMinute = new Date().getMinutes();
        }
    }

    public onLeadAmountChanged(): void {
        this.leadClient.amount = this.amount;
        this.leadClientParamsChange.emit(true);
    }

    // now + N days
    public calendarForward(nDays: number): void {
        let now: Date = new Date();
        let timestamp: number = now.getTime() + 1000 * 60 * 60 * 24 * nDays;
        let newDate: Date = new Date(timestamp);
        this.currentDate = { date: { year: newDate.getFullYear(), month: newDate.getMonth() + 1, day: newDate.getDate() } };
        this.currentHour = newDate.getHours();
        this.currentMinute = newDate.getMinutes();
    }

    public calendarApply(): void {
        let leaddatetime: LeadClientDatetime = {
            year: this.currentDate.date.year,
            month: this.currentDate.date.month,
            day: this.currentDate.date.day,
            hour: this.currentHour,
            minute: this.currentMinute
        };

        let newDate: Date = this.appService.leaddatetime2date(leaddatetime);
        const serverDate: Date = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);

        this.leadClient.rawFollowup = newDate;
        this.leadClient.followUp = serverDate.toISOString();
        this.leadClientParamsChange.emit(true);
        this.leadClientFollowupChange.emit(true);
        this.close();
    }
}
