import { ISubstatus } from '../../model/substatus.interface';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { ILang } from '../../model/lang.interface';
import { IStatus } from '../../model/status.interface';

@Component({
    selector: 'substatus-edit',
    templateUrl: 'substatus.edit.component.html',
    styleUrls: ["../../app.component.scss", "./substatus.edit.component.scss"],
})
export class SubstatusEditComponent {
    @Input() substatus: ISubstatus;
    @Input() currentLang: ILang;
    @Input() statuses: IStatus[];
    public advanced: boolean = false;
    @ViewChild('template', { static: false }) private templateTextarea: ElementRef;

    public addTemplateElement(event: MouseEvent): void {
        let elem: HTMLElement = event.target as HTMLElement;
        if (elem.className === "tag") {
            this.substatus.template = this.substatus.template ? this.substatus.template + elem.innerHTML : elem.innerHTML;
            (this.templateTextarea.nativeElement as HTMLElement).focus();
        }
    }

    public isEmpty(v: any): boolean {
        return v === null || v === undefined;
    }

    public validateNumber() {
        if(this.substatus.position == null){
            var elems = document.getElementsByClassName('position-input');
            for(var i=0;i< elems.length;i++){
                var elem = elems[i] as HTMLInputElement;
                elem.value = null;
            }
        }
        if (this.substatus.position) {
            var str = this.substatus.position.toString().replace(/[^0-9]+/g, "")
            this.substatus.position = +str;
        }
    }

    public enableAdvanced() {
        this.advanced = !this.advanced;
    }

    public clearInput(field: string) {
		switch (field) {
			case 'title': this.substatus.title = ''; break;
			default: break;
		}
	}
}
