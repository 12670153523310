import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ILang } from 'src/app/model/lang.interface';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'users-view-overlay',
    templateUrl: 'users.overlay.page.html',
    styleUrls: ["../../app.component.scss", "users.overlay.page.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class UsersViewOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    
    constructor(
        protected appService: AppService
    ) {
        this.ready = true;
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }
}
