import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ILang } from 'src/app/model/lang.interface';
import { IProcess } from 'src/app/model/process.interface';
import { IStatus } from 'src/app/model/status.interface';
import { ISubstatus } from 'src/app/model/substatus.interface';
import { LangsRepository } from 'src/app/services/langs.repository';
import { LeadsRepositoryBUT } from 'src/app/services/leads.repository.but';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'processes-edit-overlay',
    templateUrl: 'processes.overlay.edit.html',
    styleUrls: ["../../app.component.scss", "processes.overlay.edit.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class ProcessesEditOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() process: IProcess = null;

    public langsReady = false;
    public substatusesReady = false;
    public processesReady = false;

    constructor(
        private langsRepository: LangsRepository,
        private substatusesRepository: SubstatusesRepository,
        private processesRepository: ProcessesRepositoryBUT,
        private leadsRespotory: LeadsRepositoryBUT,
        private appService: AppService
    ) {
        this.ready = true;
        this.init();
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) this.langsReady = true; });
    }

    public validate(): boolean {
        let err = false;

        if (!this.process.title.trim().length) {
            this.process.titleError = this.currentLang.words['enter-process'];
            err = true;
        } else if (this.process.title.trim().length > 250) {
            this.process.titleError = this.currentLang.words['process-limit-error'];
            err = true;
        } else { this.process.titleError = ""; }

        return !err;
    }

    public save(): void {
        if (this.validate()) {
            this.appService.showNetworkstatus("sending data...");
             this.processesRepository.updateProcess(this.process).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.processesRepository.load();
                    this.substatusesRepository.load();
                    this.close();
                } else if (res.responseCode === 400) {
                    //this.appService.showError("API error", this.currentLang.words['status-exist'], true);
                } else {
                    //this.appService.showError("API error", res.error || res.Error, true);
                }
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in processes-edit-overlay save updateProcess: ${message}`, true);
            });
        }
    }
}
