<div id="notification" [class]="active ? 'active' : ''" [dir]="currentLang.dir">
    <div id="notification-content">
        <div id="notification-message">
            <span>
                <a (click)="goToLeadClient()" routerDirection="forward">
                    <span>{{title}} </span><br />
                    <span>{{message}}</span>
                </a>
            </span>
        </div>
    </div>
    <div id="notification-close" (click)="close();"><span></span></div>
</div>