import { Component, ViewEncapsulation } from '@angular/core';
import { LangsRepository } from '../../services/langs.repository';
import { ILang } from '../../model/lang.interface';

@Component({
	selector: 'page404',
	templateUrl: '404.page.html',
	styleUrls: ["404.page.scss", "../../app.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class Page404 {
	public langsReady: boolean = false;

	constructor(
		private langsRepository: LangsRepository,
	) {
		this.langsRepository.ready.subscribe(r => {
			if (r) {
				this.langsReady = true;
			}
		});
	}

	get currentLang(): ILang { return this.langsRepository.current; }
}
