import { Component, ViewEncapsulation } from '@angular/core';
import { LangsRepository } from '../services/langs.repository';
import { ILang } from '../model/lang.interface';
import { AppService } from '../services/app.service';
import { ProcessesRepositoryBUT } from '../services/processes.repository.but';
import { Location } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { User } from '../model/user.model';
import { IProcess } from '../model/process.interface';

@Component({
    selector: 'processes-page',
    templateUrl: 'processes.page.html',
    styleUrls: ['processes.page.scss', "../app.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ProcessesPage {
    public langsReady: boolean = false;
    public processesReady: boolean = false;
    public processNewActive: boolean = false;
    public newProcess: IProcess;
    public currentProcessToEdit: IProcess = null;
    public processEditActive: boolean = false;

    public processReplaceActive: boolean = false;
    public currentReplaceProcessId: number = 0;

    constructor(
        private langsRepository: LangsRepository,
        private processesRepository: ProcessesRepositoryBUT,
        private authService: AuthService,
        private appService: AppService,

        private readonly location: Location
    ) {
        this.init();
    }

    public back(): void {
        this.location.back();
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) { this.langsReady = true; } });
        this.processesRepository.prepare().then(() => { this.processesReady = true; });
    }

    get currentLang(): ILang { return this.langsRepository.current; }
    get processes(): IProcess[] { return this.processesRepository.processes; }
    set menuClass(v: string) { this.appService.menuClass = v; }
    get user(): User { return this.authService.user; }

    // public sortBy(item1: ISubstatus, item2: ISubstatus) {
    //     if (item1.position < item2.position) return -1;
    //     if (item1.position > item2.position) return 1;

    //     if (item1.title > item2.title) return 1;
    //     else if (item1.title < item2.title) return -1;

    //     return 0;
    // }

    public goToEdit(process: IProcess) {
        this.currentProcessToEdit = process;
        this.processEditActive = true;
    }
}
