<div *ngIf="ready"
    [class]="active ? 'overlay clientOverlay '+currentLang.dir+' active' : 'overlay clientOverlay '+currentLang.dir">
    <div class="ol-main">
        <div class="ol-head grad">
            <div class="ol-title">{{currentLang.words["replace-user"]}}</div>
            <div class="ol-close" (click)="close ();"><span></span></div>
        </div>
        <div class="ol-content">
            <div class="full">
                <div id="submain">
                    <div class="content replace-user">
                        <div class="user" *ngFor="let u of users" (click)="delete(u)">
                            <div class="ss-title" style="width: 100%;">{{u.firstName}} {{u.lastName}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ol-right" (click)="close ();"></div>
</div>