import { Injectable } from "@angular/core";
import { AppService } from "../app.service";
import { DataService } from "../DataServices/data.service";
import { WhatsAppDataService } from "../DataServices/whatsApp.data.service";
import { LangsRepository } from "../langs.repository";
import { ProcessesRepository } from "../processes.repository";

@Injectable()
export class ProcessesRepositoryBPT extends ProcessesRepository {
    public lastReload: number = 0;
    public cacheKey: string = "";

    constructor(
        protected dataService: DataService,
        protected langsRepository: LangsRepository,
        protected appService: AppService,
    ) {
        super(langsRepository);
    }

    public load(): void {
        this.dataService.token.subscribe(token => {
            this.ready.next(false);
            this.processes = [];
            if (token) {
                this.dataService.but_getProcesses(this.locale).subscribe(res => {
                    if (res.responseCode === 200) {
                        this.processes = (res.data.length) ? res.data : [];
                        this.lastReload = (new Date()).getTime();
                        this.setCacheKeyIfNotExistToLocalStorage(res.settingsCacheToken);
                        this.ready.next(true);
                    } else {
                        this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, err => {
                    const message = this.appService.getErrorMessage(err);
                    this.appService.showError("HTTP error", `${this.langsRepository.current.words["http_error"]} in ProcessesRepositoryBPT load but_getProcesses: ${message}`);
                });
            }
        });
    }

    public prepare(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.load();
            this.ready.subscribe(r => {
                if (r) {
                    resolve();
                }
            });
        });
    }

    public setCacheKeyIfNotExistToLocalStorage(key: string) {
        var storeKey = localStorage.getItem('cacheKey');
        if (!storeKey) localStorage.setItem('cacheKey', key);
    }

    public getCurrentCacheKeyFromLocalStorage() {
        return localStorage.getItem('cacheKey');
    }

    public updateCacheKeyInLocalStorage(key: string) {
        localStorage.setItem('cacheKey', key);
    }
}
