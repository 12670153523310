import * as Sentry from "@sentry/angular";
import { Integrations } from '@sentry/tracing';
import * as i0 from "@angular/core";
export class ErrorHandlerService extends Sentry.SentryErrorHandler {
    constructor(config) {
        super(config.options);
        this._options.extractor = (e) => e.rejection || this._defaultExtractor(e);
        const release = config.release && (typeof config.release === 'string' ? config.release : config.release());
        Sentry.init({
            release,
            environment: config.environment,
            dsn: config.dsn,
            integrations: config.browserTracking !== false ? [
                new Integrations.BrowserTracing(),
            ] : [],
            tracesSampleRate: config.tracesSampleRate,
            beforeSend(event, hint) {
                try {
                    if (hint.originalException instanceof Error) {
                        const err = hint.originalException;
                        // disable sentry log of errors that cannot be handled in handleError
                        if (ErrorHandlerService.skipErrorLog(err.message)) {
                            return null;
                        }
                    }
                }
                catch (err) { }
                return event;
            },
        });
    }
    // onError = new EventEmitter();
    static skipErrorLog(error) {
        let message = "";
        if (error instanceof Error) {
            message = error.message;
        }
        else if (typeof error === 'object') {
            message = JSON.stringify(error);
        }
        else {
            message = String(error);
        }
        if (typeof message === "string" && (message.includes("WebSocket failed to connect") // signalR cannot connect to server
            || message.includes("Unknown Error"))) { // httpClient cannot connect to server
            return true;
        }
        return false;
    }
    setShowDialog(show) {
        this._options.showDialog = show;
    }
    handleError(error) {
        if (!ErrorHandlerService.skipErrorLog(error)) {
            // this.onError.emit(error);
            Sentry.withScope(scope => {
                scope.setExtra("errorExtraData", error);
                scope.setExtra("handlerSource", "service");
                super.handleError(error);
            });
        }
        // do not catch in dev mode
        // if (isDevMode()) {
        //   console.error(error);
        // } else {
        //   super.handleError(error);
        // }
    }
}
ErrorHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorHandlerService_Factory() { return new ErrorHandlerService(i0.ɵɵinject("config")); }, token: ErrorHandlerService, providedIn: "root" });
