import { LeadClient } from '../model/leadClients/lead.client.model';
import { LangsRepository } from '../services/langs.repository';
import { AssigneesRepositoryBUT } from '../services/assignees.repository.but';
import { LeadClientRepositoryBUT } from '../services/lead.clients.repository.but';
import { SettingsRepository } from '../services/settings.repository';
import { ProcessesRepositoryBUT } from '../services/processes.repository.but';
import { ILang } from '../model/lang.interface';
import { IAssignee } from '../model/assignee.interface';
import { IStatus } from '../model/status.interface';
import { ISubstatus } from '../model/substatus.interface';
import { ILeadTemplate } from '../model/leadtemplate.interface';
import { IProcess } from '../model/process.interface';

import { AppService } from '../services/app.service';
import { AuthService } from '../services/auth.service';
import { User } from '../model/user.model';
import { LeadClientNote } from '../model/leadClients/lead.client.note.model';
import { Platform } from '@ionic/angular';

export abstract class LeadClientsRoutine {
    public id: number = 0;
    public leadClient: LeadClient = null;
    public leadClientsReady: boolean = false;
    public assigneesReady: boolean = false;
    public processesReady: boolean = false;
    public langsReady: boolean = false;
    public settingsReady: boolean = false;
    public notesReady: boolean = false;

    constructor(
        protected langsRepository: LangsRepository,
        protected appService: AppService,
        protected authService: AuthService,
        protected assigneesRepository: AssigneesRepositoryBUT,
        protected leadClientRepository: LeadClientRepositoryBUT,
        protected settingsRepository: SettingsRepository,
        protected processesRepository: ProcessesRepositoryBUT,
        protected platform: Platform
    ) {
        this.appService.initIntroPopup();
    }

    get assignees(): IAssignee[] { return this.assigneesRepository.assignees; }
    get currentLang(): ILang { return this.langsRepository.current };
    get user(): User { return this.authService.user; }
    get processes(): IProcess[] { return this.processesRepository.processes; }

    protected initLeadClient(token: string = null): Promise<void> {
        this.leadClientsReady = false; // in this function leadReady will not changed to true, because maybe prices and notes needed, so leadReady must be set manually!

        return new Promise((resolve, reject) => {
            this.leadClient = this.leadClientRepository.getLeadClientById(this.id);
            this.leadClientRepository.ready.subscribe(r => {
                if (r) {
                    this.leadClientRepository.loadLeadClient(this.id).subscribe(res => {
                        this.assigneesRepository.cacheKey = res.settingsCacheToken;
                        this.processesRepository.cacheKey = res.settingsCacheToken;
                        if (res.responseCode === 200) {
                            this.leadClient = (new LeadClient()).build(res.data);
                            this.leadClientRepository.leadCLients.push(this.leadClient); // if repository was empty, we may push lead to it fo further purposes
                            resolve();
                        } else {
                            this.catchError("API error", `code: ${res.responseCode}, error: ${res.error || res.Error}`, false, res.responseCode);
                            // reject(res.responseCode);
                        }
                    }, err => {
                        this.catchError("HTTP error", `message: ${this.currentLang.words["http_error"]}`, true);
                        // reject(err.message);
                    });
                }
            }, err => console.error(err));
        });
    }

    protected initLeadClientNotes(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.leadClientRepository.ready.subscribe(r => {
                if (r) {
                    this.leadClientRepository.getNotes(this.id).subscribe(res => {
                        if (res.responseCode === 200) {
                            this.leadClient.notes = (res.data.length) ? res.data.map(d => (new LeadClientNote()).build(d)) : [];
                            resolve();
                        } else {
                            this.catchError("API error", `code: ${res.responseCode}, error: ${res.error || res.Error}`, false, res.responseCode);
                            reject(res.responseCode);
                        }
                    }, err => {
                        this.catchError("HTTP error", `message: ${this.currentLang.words["http_error"]}`, true)
                        reject(err.message);
                    });
                }
            }, err => console.error(err));
        });
    }

    protected initLangs(): Promise<void> {
        this.langsReady = false;

        return new Promise((resolve, reject) => {
            this.langsRepository.ready.subscribe(r => {
                if (r) {
                    this.langsReady = true;
                    resolve();
                }
            }, err => console.error(err));
        });
    }


    protected initProcesses(): Promise<void> {
        this.processesReady = false;

        return new Promise((resolve, reject) => {
            this.processesRepository.prepare().then(() => {
                this.processesReady = true;
                resolve();
            });
        });
    }

    protected initSettings(): Promise<void> {
        this.settingsReady = false;

        return new Promise((resolve, reject) => {
            this.settingsRepository.ready.subscribe(r => {
                if (r) {
                    this.settingsReady = true;
                    resolve();
                }
            }, err => console.error(err));
        });
    }

    protected initAssignees(): Promise<void> {
        this.assigneesReady = false;

        return new Promise((resolve, reject) => {
            this.assigneesRepository.prepare().then(() => {
                this.assigneesReady = true;
                resolve();
            });
        });
    }

    protected catchError(errType: string, errMsg: string, isServiceNotAvailable: boolean, responseCode?: number): void {
        this.appService.showError(errType, errMsg, isServiceNotAvailable, responseCode);
    }

    public getLeadTemplate(leadClient: LeadClient): string {
        return (leadClient.additionalStatusId) ? this.getLeadTemplateBySubstatus(leadClient) : this.getLeadTemplateByStatus(leadClient);
    }

    private getLeadTemplateByStatus(lead: LeadClient): string {
        let template: ILeadTemplate = this.settingsRepository.leadTemplates.find(lt => lt.lang === this.currentLang.slug);
        let schemaNo: string = lead.leadStatusId ? lead.leadStatusId.toString() : "0";

        if (schemaNo === "11" && !lead.additionalStatusId) {
            schemaNo = "11s";
        }
        
        let schema: string = "";
        if (template) {
            if (template.schemas) {
                schema = template.schemas[schemaNo] || template.schemas["0"];
            }
        }

        return this.getTemplateReplacement(schema, lead);
    }

    private getLeadTemplateBySubstatus(leadClient: LeadClient): string {
        if (this.processesRepository.processes[0]) {
            let status: IStatus | null = this.processesRepository.processes[0].statuses.find(s => s.id === leadClient.leadStatusId) || null;

            if (status) {
                let substatus: ISubstatus | null = status.subStatuses.find(ss => ss.id === leadClient.additionalStatusId) || null;

                if (substatus && substatus.template) {
                    let template = this.getTemplateReplacement(substatus.template, leadClient);
                    if (template == '') {
                        template = this.currentLang.words["empty-values"]
                    }
                    return template;
                } else {
                    return this.getLeadTemplateByStatus(leadClient);
                }
            } else {
                return this.getLeadTemplateByStatus(leadClient);
            }
        }
    }

    private getTemplateReplacement(template: string, leadClient: LeadClient): string {
        template = this.replaceAll(template, "{fullname}", leadClient.fullName ? (this.user && this.user.canViewFullname ? leadClient.fullName : "*") : "");
        template = this.replaceAll(template, "{phone}", leadClient.phone ? (this.user && this.user.canViewPhone ? leadClient.phone.split('\n')[0] : "*") : "");
        template = this.replaceAll(template, "{email}", leadClient.email ? (this.user && this.user.canViewEmail ? leadClient.email.split('\n')[0] : "*") : "");
        template = this.replaceAll(template, "{createdate}", leadClient.formatCreatedate ? leadClient.formatCreatedate : "");
        template = this.replaceAll(template, "{followup}", leadClient.formatFollowup ? leadClient.formatFollowup : "");
        template = this.replaceAll(template, "{deal-amount}", leadClient.amount !== 0 && leadClient.amount ? leadClient.amount.toString() : "");
        template = this.replaceAll(template, "{status}", this.getLeadSubstatusName(leadClient) ? this.getLeadSubstatusName(leadClient) : "");
        template = this.replaceAll(template, "{stage}", this.getLeadStatusName(leadClient) ? this.getLeadStatusName(leadClient) : "");
        template = this.replaceAll(template, "{callstatus}", leadClient.callStatus || "");
        template = this.replaceAll(template, "{campaign}", leadClient.campaignTitle);
        template = this.replaceAll(template, "{campaigntitle}", leadClient.campaignTitle);
        template = this.replaceAll(template, "{leadtype}", '' ? '' : "");
        template = this.replaceAll(template, "{referer}", leadClient.referralUrl || "");
        template = this.replaceAll(template, "{recording-url}", leadClient.callRecordingUrl)

        return template
            .replace(/\s,\s/g, " ");
    }

    private replaceAll(template: string, search: string, replace: string): string {
        return template.split(search).join(replace);
    }

    private getLeadSubstatusName(leadClient: LeadClient): string {
        let res: string = "";
        let process: IProcess | null = this.processesRepository.processes[0] || null;

        if (process) {
            let status: IStatus | null = process.statuses.find(s => s.id === leadClient.leadStatusId) || null;

            if (status) {
                let substatus: ISubstatus | null = status.subStatuses.find(ss => ss.id === leadClient.additionalStatusId) || null;

                if (substatus) {
                    res = substatus.title;
                }
            }
        }

        return res;
    }

    private getLeadStatusName(leadClient: LeadClient): string {
        let res: string = "";
        let process: IProcess | null = this.processesRepository.processes[0] || null;

        if (process) {
            let status: IStatus | null = process.statuses.find(s => s.id === leadClient.leadStatusId) || null;

            if (status) {
                res = status.title;
            }
        }

        return res;
    }

    public isEmpty(v: any): boolean {
        return v === null || v === undefined;
    }

}