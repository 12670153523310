import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular"
import { DataService } from './DataServices/data.service';
import { Observable } from 'rxjs';
import { IResponse } from '../model/response.interface';
import { HttpResponse } from '@angular/common/http';
import { User } from '../model/user.model';
import { IAuthData } from '../model/authdata.interface';
import { NotificationService } from './notification.service';
import { LangsRepository } from './langs.repository';
import { IIframeResponse } from '../model/iframeresponse.interface';
import { WhatsAppDataService } from "./DataServices/whatsApp.data.service";
import { WhatsappService } from "./whatsapp.service";
import { LeadPermission, LeadPermissionType } from "../model/leadpermission.interface";

@Injectable()
export class AuthService {
    public authenticated = false;
    public user: User | null = null;
    public prevUrl = "";

    constructor(
        private dataService: DataService,
        private whatsAppDataService: WhatsAppDataService,
        private notificationService: NotificationService,
        private whatsappService: WhatsappService,
        private langsRepository: LangsRepository,
    ) {
        let authData: IAuthData = JSON.parse(localStorage.getItem(this.authKey));
        console.log('AuthService constructor', authData ? authData.user.email : "", this.authKey, authData);
        if (authData) {
            this.dataService.token.next(authData.token);
            if (authData.user) {
                this.dataService.userId.next(authData.user.id);
            }
            this.user = authData.user;
            this.notificationService.subscribe(this.user.id);
            this.whatsappService.subscribe(this.user);
            this.authenticated = true;
        }
    }

    get authKey(): string {
        const isInIframe = (parent !== window);
        const hostname = isInIframe ? document.referrer : window.location.hostname;
        return `fxa-${ hostname }`;
    }

    public login(username: string, password: string): Observable<HttpResponse<IResponse<User>>> {
        var locale: string = this.langsRepository.current.locale;
        return this.dataService.login(username, password, locale);
    }

    public forgotPassword(username: string): Observable<HttpResponse<IResponse<User>>> {
        var locale: string = this.langsRepository.current.locale;
        return this.dataService.forgotPassword(username, locale);
    }

    public getToken(): string {
        return this.dataService.token.value;
    }

    public getClientToken(): Observable<any> {
        return this.dataService.authGetToken();
    }

    public getServerApplicationVersion(): Observable<any> {
        return this.dataService.getCurrentVersionFromServer();
    }

    public saveAuthData(authData: IAuthData): void {
        this.dataService.token.next(authData.token);
        if (authData.user) {
            this.dataService.userId.next(authData.user.id);
        }
        this.user = authData.user;
        console.log('saveAuthData', authData ? authData.user.email : "", this.authKey, authData);
        localStorage.setItem(this.authKey, JSON.stringify(authData));
        Sentry.configureScope(scope => {
            if (this.user) {
                scope.setUser({
                    id: this.user.id.toString(),
                    email: this.user.email,
                    username: this.user.firstName + " " + this.user.lastName,
                });
            }
        });

        setTimeout(() => {
            if (this.user) {
                this.notificationService.subscribe(this.user.id);
                this.whatsappService.subscribe(this.user);
            }
        }, 1000);

        this.authenticated = true;
    }

    public logout(): void {
        if (this.user) {
            this.notificationService.unsubscribe(this.user.id);
            this.whatsappService.unsubscribe(this.user);
        }

        console.log('logout');
        localStorage.removeItem(this.authKey);
        this.dataService.token.next("");
        this.dataService.userId.next(null);
        this.user = null;
        this.authenticated = false;
    }

    public updateUser(user: User, token: string): void {
        this.notificationService.unsubscribe(this.user.id);
        this.whatsappService.unsubscribe(this.user);
        this.user = user;
        let authData: IAuthData = { token: token, user: this.user };
        console.log('updateUser', authData ? authData.user.email : "", this.authKey, authData);
        localStorage.setItem(this.authKey, JSON.stringify(authData));
        setTimeout(() => {
            this.notificationService.subscribe(this.user.id);
            this.whatsappService.subscribe(this.user);
        }, 1000);

        this.dataService.token.next(token);
        if (authData.user) {
            this.dataService.userId.next(authData.user.id);
        }
    }

    public updateUserSettings(user: User){
        var item = localStorage.getItem(this.authKey);
        var authData: IAuthData = JSON.parse(item);
        console.log('updateUserSettings', authData ? authData.user.email : "", this.authKey, authData);
        authData.user = user;
        localStorage.setItem(this.authKey, JSON.stringify(authData));
    }

    public getPaymentIframe(token: string, login: string): Observable<IResponse<IIframeResponse>> {
        return this.dataService.blt_getPaymentIframe(token, this.langsRepository.current.locale, login);
    }

    public addUserBySms(token: string, smsCode: string, login: string): Observable<HttpResponse<IResponse<User>>> {
        return this.dataService.blt_addUserBySms(token, smsCode, login, this.langsRepository.current.locale);
    }

    public registrationComplete(token: string, smsCode: string, phone: string, email: string, firstName: string, lastName: string): Observable<HttpResponse<IResponse<User>>> {
        return this.dataService.blt_registrationComplete(token, smsCode, email, phone, this.langsRepository.current.locale, firstName, lastName);
    }

    public registrationProcess(token: string, phone: string, email: string, firstName: string, lastName: string): Observable<HttpResponse<IResponse<User>>> {
        return this.dataService.blt_registrationProcess(token, email, phone, this.langsRepository.current.locale, firstName, lastName);
    }

    public resendSmsForRegistration(token: string, id: number): Observable<HttpResponse<IResponse<User>>> {
        return this.dataService.blt_resendSmsForRegistration(token, id, this.langsRepository.current.locale);
    }

    public sendLogAs(clientToken: string): Observable<HttpResponse<IResponse<User>>> {
        return this.dataService.authLogAs(clientToken);
    }

    public setWhatsAppToken(token: string) {
        this.whatsAppDataService.token.next(token);
    }

    public getUserByWhatsAppToken() {
        this.whatsAppDataService.getUserByToken().subscribe((res) => { this.user = res.data; })
    }

    public hasRestriction = (type: LeadPermissionType): boolean => {
        if (!this.user || !this.user.activeRestrictions) {
            return false;
        }
        return this.user.activeRestrictions.some((i: LeadPermission) => i.restriction === type);
    }
        
}
