import { BehaviorSubject } from 'rxjs';
import { IProcess } from '../model/process.interface';
import { LangsRepository } from './langs.repository';

export abstract class ProcessesRepository {
    public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public processes: IProcess[] = [];

    constructor(
        protected langsRepository: LangsRepository,
    ) { }

    get locale(): string { return this.langsRepository.current.locale; }
}

