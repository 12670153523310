/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./processes.page.scss.ngstyle";
import * as i1 from "../app.component.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./overlay/processes.overlay.new.ngfactory";
import * as i5 from "./overlay/processes.overlay.new";
import * as i6 from "../services/app.service";
import * as i7 from "../services/auth.service";
import * as i8 from "../services/langs.repository";
import * as i9 from "../services/substatuses.repository";
import * as i10 from "../services/processes.repository.but";
import * as i11 from "./overlay/processes.overlay.edit.ngfactory";
import * as i12 from "./overlay/processes.overlay.edit";
import * as i13 from "../services/leads.repository.but";
import * as i14 from "./overlay/processes.overlay.replace.ngfactory";
import * as i15 from "./overlay/processes.overlay.replace";
import * as i16 from "../services/lead.clients.repository.but";
import * as i17 from "./processes.page";
var styles_ProcessesPage = [i0.styles, i1.styles];
var RenderType_ProcessesPage = i2.ɵcrt({ encapsulation: 2, styles: styles_ProcessesPage, data: {} });
export { RenderType_ProcessesPage as RenderType_ProcessesPage };
function View_ProcessesPage_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["class", "ss-edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToEdit(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_ProcessesPage_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["class", "ss-delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.processReplaceActive = true;
        var pd_0 = ((_co.currentReplaceProcessId = _v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_ProcessesPage_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 6, "div", [["class", "substatus"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 1, "div", [["class", "ss-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToEdit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(3, null, ["", ""])), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesPage_3)), i2.ɵdid(5, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesPage_4)), i2.ɵdid(7, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.user; _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.user && (_v.context.$implicit.id > 0)) && (_v.context.$implicit.title != "\u05DB\u05DC\u05DC\u05D9")); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_0); }); }
function View_ProcessesPage_5(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "a", [["class", "add-new-process"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.processNewActive = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["add-process"]; _ck(_v, 2, 0, currVal_0); }); }
function View_ProcessesPage_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 13, "div", [["class", "full"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 6, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 5, "div", [["class", "content substatuses"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 4, "div", [["class", "global-status"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesPage_2)), i2.ɵdid(5, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesPage_5)), i2.ɵdid(7, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(8, 0, null, null, 1, "processes-new-overlay", [], null, [[null, "activeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeChange" === en)) {
        var pd_0 = ((_co.processNewActive = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ProcessesNewOverlayComponent_0, i4.RenderType_ProcessesNewOverlayComponent)), i2.ɵdid(9, 49152, null, 0, i5.ProcessesNewOverlayComponent, [i6.AppService, i7.AuthService, i8.LangsRepository, i9.SubstatusesRepository, i10.ProcessesRepositoryBUT], { active: [0, "active"], currentLang: [1, "currentLang"] }, { activeChange: "activeChange" }), (_l()(), i2.ɵeld(10, 0, null, null, 1, "processes-edit-overlay", [], null, [[null, "activeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeChange" === en)) {
        var pd_0 = ((_co.processEditActive = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ProcessesEditOverlayComponent_0, i11.RenderType_ProcessesEditOverlayComponent)), i2.ɵdid(11, 49152, null, 0, i12.ProcessesEditOverlayComponent, [i8.LangsRepository, i9.SubstatusesRepository, i10.ProcessesRepositoryBUT, i13.LeadsRepositoryBUT, i6.AppService], { active: [0, "active"], currentLang: [1, "currentLang"], process: [2, "process"] }, { activeChange: "activeChange" }), (_l()(), i2.ɵeld(12, 0, null, null, 1, "process-replace-overlay", [], null, [[null, "activeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeChange" === en)) {
        var pd_0 = ((_co.processReplaceActive = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ProcessesReplaceOverlayComponent_0, i14.RenderType_ProcessesReplaceOverlayComponent)), i2.ɵdid(13, 49152, null, 0, i15.ProcessesReplaceOverlayComponent, [i8.LangsRepository, i10.ProcessesRepositoryBUT, i6.AppService, i16.LeadClientRepositoryBUT, i9.SubstatusesRepository], { active: [0, "active"], currentLang: [1, "currentLang"], processId: [2, "processId"] }, { activeChange: "activeChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.processes; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.user; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.processNewActive; var currVal_3 = _co.currentLang; _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = _co.processEditActive; var currVal_5 = _co.currentLang; var currVal_6 = _co.currentProcessToEdit; _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.processReplaceActive; var currVal_8 = _co.currentLang; var currVal_9 = _co.currentReplaceProcessId; _ck(_v, 13, 0, currVal_7, currVal_8, currVal_9); }, null); }
export function View_ProcessesPage_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesPage_1)), i2.ɵdid(1, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.langsReady && _co.processesReady); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProcessesPage_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "processes-page", [], null, null, null, View_ProcessesPage_0, RenderType_ProcessesPage)), i2.ɵdid(1, 49152, null, 0, i17.ProcessesPage, [i8.LangsRepository, i10.ProcessesRepositoryBUT, i7.AuthService, i6.AppService, i3.Location], null, null)], null, null); }
var ProcessesPageNgFactory = i2.ɵccf("processes-page", i17.ProcessesPage, View_ProcessesPage_Host_0, {}, {}, []);
export { ProcessesPageNgFactory as ProcessesPageNgFactory };
