import { Component, ViewEncapsulation } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LangsRepository } from '../../services/langs.repository';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { AssigneesRepositoryBUT } from '../../services/assignees.repository.but';
import { LeadClientsRoutine } from '../lead.clients.routine';
import { LeadClientRepositoryBUT } from '../../services/lead.clients.repository.but';
import { ProcessesRepositoryBUT } from '../../services/processes.repository.but';
import { SettingsRepository } from '../../services/settings.repository';
import { IStatus } from 'src/app/model/status.interface';
import { LeadClient } from 'src/app/model/leadClients/lead.client.model';
import { LeadClientDatetime } from '../../model/leadClients/lead.client.datetime';
import { SortStore } from 'src/app/model/sort.store.model';
import { FilterStore } from 'src/app/model/filter.store.model';
import { StatusType } from 'src/app/Enums/status.type.enum';
import { LeadClientMeeting } from 'src/app/model/leadClients/lead.client.meeting';
import { environment } from 'src/environments/environment';
import { IProcess } from 'src/app/model/process.interface';
import { LeadClientMeetingStatus } from 'src/app/model/leadClients/lead.client.meeting.meetingstatus';

@Component({
    selector: "leadClients-but-page",
    templateUrl: "lead.clients.but.page.html",
    styleUrls: ["lead.clients.but.page.scss", "../../app.component.scss", "../../calendar.scss"],
    encapsulation: ViewEncapsulation.None
})

export class LeadClientsBUTPage extends LeadClientsRoutine {
    public langsReady: boolean = false;
    public leadClientsReady: boolean = false;
    public loading: boolean = false;
    private perLoad: number = 20;
    private loaded: number = 0;
    private total: number = 0;
    public leadClients: LeadClient[] = [];

    // search
    public advancedSearchActive: boolean = false;
    public presetsMinimized: boolean = false;
    public sortingMinimized: boolean = false;
    public searchKey: string = "";
    public processesSearchActive: boolean = false;

    //filters and sorting
    public sortActive: boolean = false;
    public filter: number = 0;
    // public dir: number = 0;

    // calendar win
    public leadClient4Calendar: LeadClient = (new LeadClient()).init();
    // public calendarActive: boolean = false;
    public eventSchema: string = '';

    // statuses win
    public leadClient4Statuses: LeadClient = (new LeadClient()).init();
    public leadClientParamsChanged: boolean = false;
    public leadClientFollowupChanged: boolean = false;

    constructor(
        protected langsRepository: LangsRepository,
        public appService: AppService,
        protected authService: AuthService,
        protected assigneeRepository: AssigneesRepositoryBUT,
        protected route: ActivatedRoute,
        protected platform: Platform,
        protected leadClientsRepository: LeadClientRepositoryBUT,
        protected settingsRepository: SettingsRepository,
        protected processesRepository: ProcessesRepositoryBUT,
        protected router: Router
    ) {
        super(langsRepository, appService, authService, assigneeRepository, leadClientsRepository, settingsRepository, processesRepository, platform);

        setTimeout(this.SetHtmlElements, 100);
        window.addEventListener("resize", this.SetHtmlElements);
        if (this.isCordova) {
            this.authService.getServerApplicationVersion().toPromise().then(response => {
                var serverVersion = response.data.split('.')[2];
                var appVersion = environment.version.split('.')[2];
                if (+serverVersion > +appVersion) {
                    this.appService.initVersionUpdatePopup();
                }
            });
        }

        this.initLangs().then(() => { });
        this.route.params.subscribe(params => { this.id = parseInt(params["id"]); });
        this.initAssignees().then(() => { });
        this.initProcesses().then(() => {
            this.SetupLeadClients();
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (event.url === '/leadClients' && this.filter !== this.currentFilter) {
                this.SetupLeadClients();
            }

            setTimeout(this.SetHtmlElements, 100);
        });
    }

    ionViewWillEnter() { }

    set menuClass(v: string) { this.appService.menuClass = v; }
    get isCordova(): boolean { return this.platform.is('cordova'); }
    get isAndroid(): boolean { return this.platform.is('android'); }
    get currentFilter(): number { return this.appService.currentFilter; }
    get isExistProcesses(): boolean { return this.processesRepository.processes.length != 0 && this.processesRepository.processes[0].id > 0; }
    get processes(): IProcess[] { return this.processesRepository.processes; }
    get statuses(): IStatus[] {
        if (this.leadClient4Statuses) {
            var process = this.processesRepository.processes.find(x => x.id == this.leadClient4Statuses.processId) || this.processesRepository.processes[0];
            if (process) {
                return process.statuses;
            }
        }

        return [];
    }

    public SetHtmlElements(): void {
        var elem = document.getElementsByClassName('lead-client-routing')[0];
        var bannerElem = document.getElementById("banner");
        if (bannerElem) {
            if (location.hash === "#/leadClients"
                || location.hash === "#/auth/login"
                || location.hash === "#/auth/forgotPassword"
                || location.hash === "#/comingsoon"
                || location.hash === "#/testpage"
                || location.hash === "#/privacy-policy") {
                bannerElem.style.width = "50%";
            }
            else {
                bannerElem.style.width = "25%";
            }
        }
        if (elem) {
            if (location.hash === "#/leadClients") {
                elem.classList.remove('lead-client-visible');
                elem.classList.add('lead-client-hidden');
            }
            else {
                elem.classList.remove('lead-client-hidden');
                elem.classList.add('lead-client-visible');
            }
        }
    }

    public SetupLeadClients() {
        this.leadClientsReady = false;
        this.leadClientsRepository.ready.subscribe(r => {
            if (r) {
                this.appService.leadClientsGroup = [];
                this.advancedSearchActive = false;

                this.loading = true;
                this.leadClientsRepository.leadCLients = [];
                this.loaded = 0;
                this.loadLeadClients().then(leadClients => {
                    this.appService.leadClients = leadClients;
                    this.appService.buildGroups(leadClients);
                    this.loading = false;
                    this.leadClientsReady = true;

                    this.appService.leadClients.forEach(x => x.current = false);
                    this.appService.leadClientsGroup.forEach(x => x.leadClients.map(c => c.current = false));

                    if (this.id > 0) {
                        var currentLeadClient = this.appService.leadClients.find(x => x.id == this.id);

                        if (currentLeadClient) {
                            currentLeadClient.current = true;
                        }
                    }

                }).catch(err => {
                    this.loading = false;
                    this.leadClientsReady = true;
                })

            }
        });
    }

    public onScroll(event: Event): void {
        if (this.loaded < this.total && !this.loading) {
            let elem: HTMLElement = event.target as HTMLElement;
            if (elem.scrollTop > elem.scrollHeight - elem.offsetHeight - 600) {
                this.loading = true;
                this.loadLeadClients().then(leadClients => {
                    this.appService.buildGroups(leadClients);
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                    const message = this.appService.getErrorMessage(err);
                    this.appService.showError(err.errType, `${this.currentLang.words["http_error"]} in leadClients-but-page onScroll loadLeadClients: ${message}`, true,
                                err.errResponseCode);
                });
            }
        }
    }

    get filterName(): string {
        let s: string = "";

        switch (this.appService.currentFilter) {
            case 0: s = this.currentLang.words["all-filter"]; break;
            case 1: s = this.currentLang.words["closed-deals"]; break;
            case 2: s = this.currentLang.words["not-relevant-leads"]; break;
            case 3: s = this.currentLang.words["overdue-leads"]; break;
            case 4: s = this.currentLang.words["in-process-filter"]; break;
            case 5: s = this.currentLang.words["all-meetings"]; break;
            case 6: s = this.currentLang.words["future-meetings-filter"]; break;
            case 7: s = this.currentLang.words["today-meetings"]; break;
            case 8: s = this.currentLang.words["new-filter"]; break;
            default: break;
        }

        return s;
    }

    get sortingName(): string {
        if (this.appService.sorting == 'followUp') return this.currentLang.words["followUp"];
        if (this.appService.sorting == 'createDate') return this.currentLang.words["createDate"];
        if (this.appService.sorting == 'updateDate') return this.currentLang.words["updateDate"];
        if (this.appService.sorting == 'lastMessageDate') return this.currentLang.words["last-message-date"];

        return this.currentLang.words["sorting"];
    }

    public getStatusClass(leadClient: LeadClient): string {
        switch (leadClient.leadStatusId) {
            case 0: return "new";
            case 4: return "inprocess";
            case 7: return "meeting";
            case 9: return "deal";
            case 11: return "notrelevant";
            default: return "";
        }
    }

    public changeFilterProcess(processId: number) {
        this.appService.processId = processId == 0 ? null : processId;
        this.searchKey = "";
        this.leadClientRepository.invalidate();
        this.leadClientsRepository.leadCLients = [];
        this.appService.leadClients = [];
        this.processesSearchActive = false;
        if (location.hash === "#/leadClients") {
            this.reloadLeadClients();
        } else {
            this.router.navigateByUrl("/leadClients");
            this.hideLeadClient();
            this.reloadLeadClients();
        }
    }

    public changeFilter(filter: number): void {
        // if (this.appService.currentFilter != filter) {
        this.appService.currentFilter = filter;
        this.searchKey = "";
        this.filter = filter;
        this.saveFilterToLocalStorage(this.appService.currentFilter, this.user.id);
        if ((filter === 0 || filter === 8) && this.appService.sorting !== "lastMessageDate") { // "All clients" and "New clients"
            this.changeSorting("lastMessageDate", false);
        } else if ((filter === 4 || filter === 6) && this.appService.sorting !== "followUp") {   // "In process" and "Meetings"
            this.changeSorting("followUp", false);
        }
        this.leadClientRepository.invalidate();
        this.leadClientsRepository.leadCLients = [];
        this.appService.leadClients = [];
        if (location.hash === "#/leadClients") {
            this.reloadLeadClients();
        } else {
            this.router.navigateByUrl("/leadClients");
            this.hideLeadClient();
            this.reloadLeadClients();
        }
        // }
    }

    public hideLeadClient() {
        var elem = document.getElementsByClassName('lead-client-routing')[0];
        if (elem) {
            elem.classList.remove('lead-client-visible');
            elem.classList.add('lead-client-hidden');
        }
        var bannerElem = document.getElementById("banner");
        if (bannerElem) {
            document.getElementById("banner").classList.add('full');
        }
    }

    public changeSorting(sorting: string, reloadLeadClients: boolean = true): void {
        this.appService.hideError();
        if (this.appService.sorting !== sorting) {
            this.appService.sorting = sorting;
            //this.appService.dir = (this.appService.sorting === "createDate" || this.appService.sorting === "lastMessageDate") ? 1 : 0;
            this.saveSortToLocalStorage(this.appService.sorting, this.user.id);
            if (reloadLeadClients) {
                this.leadClientRepository.invalidate();
                this.leadClientsRepository.leadCLients = [];
                this.appService.leadClients = [];
                this.reloadLeadClients();
            }
        } else {
            this.sortActive = false;
        }
    }

    public changeDirection(direction){
        this.appService.hideError();
        this.appService.dir = direction;
        this.reloadLeadClients();
    }

    public search(): void {
        this.reloadLeadClients();
    }

    public tryCloseSearch(event: MouseEvent): void {
        if ((event.target as HTMLElement).id === "leads-advancedsearch") {
            this.advancedSearchActive = false;
        }
    }

    public showCalendarWin(leadClient: LeadClient): void {
        this.leadClient4Calendar = leadClient;
        this.appService.calendarActive = true;
    }

    public changeLeadClientFollowup(leadClientdatetime: LeadClientDatetime): void {
        let newDate: Date = this.appService.leaddatetime2date(leadClientdatetime);
        this.leadClient4Calendar.rawFollowup = newDate;
        this.leadClient4Calendar.followUp = newDate.toISOString();

        let serverDate: Date = new Date(this.leadClient4Calendar.rawFollowup.getTime() - this.leadClient4Calendar.rawFollowup.getTimezoneOffset() * 60000);
        this.appService.calendarActive = false;

        this.appService.showNetworkstatus("sending data...");
        this.leadClientRepository.sendUpdateLeadClientFollowup(this.leadClient4Calendar.id, serverDate.toISOString())
            .subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");

                    // set event in calendar for meeting
                    if (this.isCordova && this.leadClient4Calendar.leadStatusId === 7) {
                        this.appService.SetCalendarEvent(this.leadClient4Calendar, this.eventSchema, null);
                    }

                    this.reloadLeadClients();
                } else {
                    this.appService.showNetworkstatus("error");
                }
            });
    }

    public showStatusesWin(leadClient: LeadClient): void {
        this.leadClient4Statuses = leadClient;
        this.leadClientParamsChanged = false;
        this.leadClientFollowupChanged = false;
        this.appService.statusesActive = true;
    }

    public onStatusesWinActiveChanged(event: boolean) {
        this.appService.statusesActive = event;
        if (!this.appService.statusesActive && this.leadClientParamsChanged) {

            this.appService.showNetworkstatus("sending data...");
            this.leadClientsReady = true;
            // update hole lead, doesnt matter, what was changed!
            var founded = this.leadClientsRepository.leadCLients.find(x => x.id == this.leadClient4Statuses.id);
            if (founded) {
                founded.leadStatusId = this.leadClient4Statuses.leadStatusId;
                founded.preview = this.getLeadTemplate(this.leadClient4Statuses);
            }
            this.leadClientRepository.sendUpdateLeadClientStatus(this.leadClient4Statuses).subscribe(res => {
                if (res.responseCode === 200) {
                    if (this.leadClient4Statuses.leadStatusId === StatusType.Closed) {
                        var now = new Date();
                        var serverDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
                        this.leadClientRepository.sendLeadClientDeal(0, this.leadClient4Statuses.id, "", this.leadClient4Statuses.amount, this.leadClient4Statuses.assigneeId, serverDate).subscribe(res => {
                            if (res.responseCode === 200) {
                                this.appService.needResyncLeadClient = true;
                                this.appService.showNetworkstatus("sent ok");
                                if (this.leadClientParamsChanged) {
                                    this.reloadLeadClients();
                                } else {
                                    this.reloadLeadClients();

                                    if (!this.appService.currentFilter) {
                                        this.leadClient4Statuses.preview = this.getLeadTemplate(this.leadClient4Statuses);
                                    }
                                }
                            } else {
                                this.appService.showNetworkstatus("error");
                            }
                        });
                    }

                    if (this.leadClient4Statuses.leadStatusId === StatusType.Meeting) {
                        this.leadClientsRepository.addMeeting(0, this.leadClient4Statuses.id, this.leadClient4Statuses.followUp, this.leadClient4Statuses.followUp,
                                "default subject", this.user.id, 0, "default note", LeadClientMeetingStatus.None).subscribe(res => {
                            if (res.responseCode === 200) {
                                this.appService.showNetworkstatus("sent ok");
                                this.leadClientRepository.invalidate();

                                var founded = this.leadClientsRepository.leadCLients.find(x => x.id == this.leadClient4Statuses.id);
                                if (founded) {
                                    founded.leadStatusId = this.leadClient4Statuses.leadStatusId;
                                    founded.preview = this.getLeadTemplate(this.leadClient4Statuses);
                                    
                                    this.appService.needResyncLeadClient = true;

                                    founded.followUp = this.leadClient4Statuses.followUp;
                                    founded.rawFollowup = new Date(founded.followUp);
                                }

                                if (this.leadClientFollowupChanged) {
                                    this.reloadLeadClients();
                                } else {
                                    this.reloadLeadClients();

                                    if (!this.appService.currentFilter) {
                                        this.leadClient4Statuses.preview = this.getLeadTemplate(this.leadClient4Statuses);
                                    }
                                }

                                if (this.isCordova) {
                                    this.appService.SetCalendarEvent(this.leadClient, this.eventSchema, null);
                                    this.appService.showNetworkstatus(this.currentLang.words["add-to-local-calendar"]);
                                }

                            } else {
                                this.appService.showNetworkstatus("error");
                            }
                        });
                    }
                } else {
                    this.appService.showNetworkstatus("error");
                }
            });
        }
    }

    private loadLeadClients(): Promise<LeadClient[]> {
        this.appService.hideError();
        return new Promise((resolve, reject) => {
            if (this.user) {
                var storeFilter = this.getFilterLocalStorage(this.user.id);
                if (storeFilter) {
                    this.appService.currentFilter = +storeFilter;
                    this.filter = +storeFilter;
                }

                var storeSort = this.getSortLocalStorage(this.user.id);
                if (storeSort) {
                    this.appService.sorting = storeSort;
                    // this.appService.dir = (this.appService.sorting === "createDate" || this.appService.sorting === "updateDate" || this.appService.sorting === "lastMessageDate") ? 1 : 0;
                }
            }

            if (this.searchKey) {
                this.appService.currentFilter = 0;
            }

            const startTime = performance.now();
            this.leadClientsRepository
                .loadLeadClients(this.loaded, this.perLoad, this.searchKey, this.appService.currentFilter, this.appService.sorting, this.appService.dir, this.appService.processId)
                .subscribe(res => {
                    if (res.responseCode == 200) {
                        this.assigneesRepository.cacheKey = res.settingsCacheToken;
                        let loadedLeadClients: LeadClient[] = [];
                        if (res.data.leadClients.length) {
                            loadedLeadClients = res.data.leadClients.map(x => this.buildLeadClient(x));
                            this.leadClients = res.data.leadClients;
                            this.leadClientsRepository.leadCLients = this.leadClientsRepository.leadCLients.concat(loadedLeadClients);
                            this.appService.leadClients = this.appService.leadClients.concat(loadedLeadClients);
                            this.appService.buildGroups(this.leadClientsRepository.leadCLients);
                            this.leadClientsRepository.lastReload = (new Date()).getTime();
                            this.loaded = this.leadClientsRepository.leadCLients.length;
                        }

                        this.total = res.data.totalCount;
                        this.leadClientsRepository.setLeadClientsReady(true);
                        resolve(loadedLeadClients);

                        this.loading = false;
                    } else {
                        this.loading = false;
                        this.appService.showError("API error", `${res.error || res.Error} in leadClients-but-page loadLeadClients`, true, res.responseCode);
                        reject({ errType: "API error", errMsg: `${res.error || res.Error} in leadClients-but-page loadLeadClients`, errResponseCode: res.responseCode });
                    }
                    const endTime = performance.now();
                    this.appService.logTimeToSentry("leadClients-but-page loadLeadClients", startTime, endTime);
                }, err => {
                    const endTime = performance.now();
                    this.appService.logTimeToSentry("leadClients-but-page loadLeadClients exc", startTime, endTime);
                    const message = this.appService.getErrorMessage(err);
                    this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in leadClients-but-page loadLeadClients: ${message}`, true);
                    reject({ errType: "HTTP error", errMsg: `${this.currentLang.words["http_error"]} in leadClients-but-page loadLeadClients: ${message}` });
                });
        });
    }

    private buildLeadClient(data: LeadClient): LeadClient {
        let leadClient = (new LeadClient()).build(data);
        leadClient.preview = this.getLeadTemplate(leadClient);

        return leadClient;
    }

    private reloadLeadClients(): void {
        this.appService.hideError();
        this.advancedSearchActive = false;
        this.sortActive = false;
        this.leadClientsReady = false;
        this.leadClientsRepository.leadCLients = [];
        this.appService.leadClientsGroup = [];
        this.loaded = 0;
        this.loading = true;
        this.loadLeadClients().then(leadClients => {
            this.appService.buildGroups(leadClients);
            this.loading = false;
            this.leadClientsReady = true;
        }).catch(err => {
            const message = this.appService.getErrorMessage(err);
            this.appService.showError(err.errType, `${this.currentLang.words["http_error"]} in leadClients-but-page reloadLeadClients loadLeadClients: ${message}`, true,
                        err.errResponseCode, true, `lc-but-page reloadLeadClients: ${message}`);
            this.loading = false;
            this.leadClientsReady = true;
        });

    }

    public saveFilterToLocalStorage(filter: number, userId: number) {
        var store = localStorage.getItem("currentLeadFilter");

        var arr = JSON.parse(store) as FilterStore[];
        var storeData = arr.find(x => x.userId === userId);

        if (storeData) {
            storeData.filter = filter;
        } else {
            arr.push(new FilterStore(userId, filter));
        }

        localStorage.setItem("currentLeadFilter", JSON.stringify(arr))
    }

    public saveSortToLocalStorage(sort: string, userId: number) {
        var store = localStorage.getItem("currentLeadSort");

        var arr = JSON.parse(store) as SortStore[];
        var storeData = arr.find(x => x.userId === userId);

        if (storeData) {
            storeData.sort = sort;
        } else {
            arr.push(new SortStore(userId, sort));
        }

        localStorage.setItem("currentLeadSort", JSON.stringify(arr))
    }

    public getSortLocalStorage(userId: number): string {
        var store = localStorage.getItem("currentLeadSort");

        if (store) {
            var arr = JSON.parse(store) as SortStore[];
            var existRow = arr.find(x => x.userId === userId);

            if (existRow) {
                return existRow.sort;
            }
        }
        else {
            localStorage.setItem("currentLeadSort", "[]")
        }

        return "";
    }

    public getFilterLocalStorage(userId: number): number {
        var store = localStorage.getItem("currentLeadFilter");

        if (store) {
            var arr = JSON.parse(store) as FilterStore[];
            var existRow = arr.find(x => x.userId === userId);

            if (existRow) {
                return existRow.filter;
            }
        }
        else {
            localStorage.setItem("currentLeadFilter", "[]")
        }

        return 0;
    }

    public clearSearchInput() {
        this.searchKey = '';
        this.search();
    }
}
