import { Component, Input } from '@angular/core';
import { ILang } from 'src/app/model/lang.interface';
import { User } from 'src/app/model/user.model';

@Component({
	selector: 'notification-settings-edit',
	templateUrl: 'notifications.settings.edit.component.html',
	styleUrls: ["../../../app.component.scss", "./notifications.settings.edit.component.scss"],
})
export class SettingsNotificationsEditComponent {
	@Input() user: User;
	@Input() currentLang: ILang;
	public sendPushNewLead: boolean = true;

	public enable(prop) {
		this.user[prop] = !this.user[prop];
	}
}
