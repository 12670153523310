import { Injectable } from "@angular/core";
import { DataService } from './DataServices/data.service';
import { BehaviorSubject } from 'rxjs';
import { AssigneesRepository } from './assignees.repository';
import { AppService } from './app.service';
import { LangsRepository } from "./langs.repository";

@Injectable()
export class AssigneesRepositoryBLT extends AssigneesRepository {
    public token: BehaviorSubject<string> = new BehaviorSubject("");

    constructor(
        private dataService: DataService,
        private appService: AppService,
        private langsRepository: LangsRepository
    ) {
        super();
        this.token.subscribe(token => {
            this.ready.next(false);

            if (token) {
                this.dataService.blt_getAssignees(token).subscribe(res => {
                    if (res.responseCode === 200) {
                        this.assignees = (res.data.length) ? res.data : [];
                        this.ready.next(true);
                    } else {
                        this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, err => {
                    const message = this.appService.getErrorMessage(err);
                    this.appService.showError("HTTP error", `${this.langsRepository.current.words["http_error"]} in AssigneesRepositoryBLT constructor blt_getAssignees: ${message}`, true);
                });
            }
        });
    }
}
