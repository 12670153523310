<div *ngIf="ready"
    [class]="active ? 'overlay clientOverlay clientUsersOverlay '+currentLang.dir+' active' : 'overlay clientOverlay '+currentLang.dir">
    <div class="ol-main ol-main-users" style="width: 400px">
        <div class="ol-head grad">
            <div class="ol-close">
                <a style="color: #fff" class="save" (click)="save ();">{{currentLang.words["save"]}}</a>
            </div>
            <div class="ol-title">{{currentLang.words["notification-settings"]}}</div>
            <div class="ol-close" (click)="close ();"><span></span></div>
        </div>
        <div class="ol-content">
            <!-- <settings-page></settings-page> -->
            <div class="full">
                <div id="submain">
                    <div class="content">
                        <notification-settings-edit [user] = "user" [currentLang]="currentLang"></notification-settings-edit>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ol-right" (click)="close ();"></div>
</div>
