import { Component, Input } from '@angular/core';
import { ILang } from '../../model/lang.interface';
import { IProcess } from 'src/app/model/process.interface';
import { ISubstatus } from 'src/app/model/substatus.interface';

@Component({
    selector: 'process-edit',
    templateUrl: 'process.edit.component.html',
    styleUrls: ["../../app.component.scss", "./process.edit.component.scss"],
})
export class ProcessEditComponent {
    @Input() process: IProcess;
    @Input() currentLang: ILang;

    public isEmpty(v: any): boolean {
        return v === null || v === undefined;
    }

    get blueSubs(): ISubstatus[] { return this.getStatuses(4); }
    get redSubs(): ISubstatus[] { return this.getStatuses(11); }
    get yellowSubs(): ISubstatus[] { return this.getStatuses(7); }
    get greenSubs(): ISubstatus[] { return this.getStatuses(9); }

    public getStatuses(statusType: number): ISubstatus[] {
        if (this.process.statuses) {
            const globalStatus = this.process.statuses.some(x => x.id == 4);
            if (globalStatus) {
                const status = this.process.statuses.find(x => x.id == statusType);
                if (status) {
                    return status.subStatuses.sort(this.sortBy);
                }
            }
        }
        return [];
    }

    public sortBy(item1: ISubstatus, item2: ISubstatus) {
        if (item1.position < item2.position) return -1;
        if (item1.position > item2.position) return 1;

        if (item1.title > item2.title) return 1;
        else if (item1.title < item2.title) return -1;

        return 0;
    }

    public clearInput(field: string) {
		switch (field) {
			case 'title': this.process.title = ''; break;
			default: break;
		}
	}
}
