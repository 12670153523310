import { NgModule, ErrorHandler, ModuleWithProviders, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { ErrorHandlerService, Options } from './error-handler.service';
import { Router } from '@angular/router';

export interface Config {
  /** @see {@link https://docs.sentry.io/platforms/javascript/#configure} */
  dsn: string;
  /**
   * @see
   * {@link https://docs.sentry.io/platforms/javascript/configuration/sampling/#configuring-the-transaction-sample-rate}
   */
  tracesSampleRate: number;
  /**
   * @see
   * {@link https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/} */
  tracingOrigins?: string[];
  /**
   *  @see {@link https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/}
   * Default: `true`
   * */
  browserTracking?: boolean;
  /** {@link https://docs.sentry.io/platforms/javascript/configuration/environments/} */
  environment?: string;
  /** @see {@link https://docs.sentry.io/platforms/javascript/enriching-events/user-feedback/#customizing-the-widget} */
  options?: Options;
  /**
   * String or funcition to pass a release string to Sentry
   * @see {@link https://docs.sentry.io/platforms/javascript/configuration/releases/}
   */
  release?: string | (() => string);
}

@NgModule({
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
		{
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class ErrorHandlerModule {

  constructor(@Optional() @SkipSelf() parentModule?: ErrorHandlerModule) {
    if (parentModule) throw new Error('ErrorHandlerModule is already loaded!');
  }

  static forRoot(config: Config): ModuleWithProviders<ErrorHandlerModule> {
    return {
      ngModule: ErrorHandlerModule,
      providers: [
        { provide: 'config', useValue: config },
      ],
    };
  }
}
