import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { StatusType } from 'src/app/Enums/status.type.enum';
import { LangsRepository } from 'src/app/services/langs.repository';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';
export class SubStatusReplaceOverlayComponent {
    constructor(langsRepository, substatusesRepository, processesRepository, appService, loadingCtrl) {
        this.langsRepository = langsRepository;
        this.substatusesRepository = substatusesRepository;
        this.processesRepository = processesRepository;
        this.appService = appService;
        this.loadingCtrl = loadingCtrl;
        this.ready = false;
        this.activeChange = new EventEmitter();
        this.cancel = new EventEmitter();
        this.substatusId = 0;
        this.globalStatus = 0;
        this.langsReady = false;
        this.substatusesReady = false;
        this.processesReady = false;
        this.ready = true;
        this.init();
    }
    close() {
        this.active = false;
        this.activeChange.emit(false);
    }
    init() {
        this.langsRepository.ready.subscribe(r => { if (r)
            this.langsReady = true; });
    }
    get substatuses() {
        var checkForCanDelete = this.substatusesRepository.substatuses.filter(x => x.id !== this.substatusId).length == 0;
        if (checkForCanDelete) {
            return this.substatusesRepository.substatuses.filter(x => x.id !== this.substatusId);
        }
        return this.substatusesRepository.substatuses.filter(x => x.id !== this.substatusId);
    }
    get subStatusForInProcess() { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.InProcess && x.id !== this.substatusId); }
    get subStatusForMeetingProcess() { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.Meeting && x.id !== this.substatusId); }
    get subStatusForDealProcess() { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.Closed && x.id !== this.substatusId); }
    get subStatusForNotGoodProcess() { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.NotGood && x.id !== this.substatusId); }
    delete(ss) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm(this.currentLang.words["sure"])) {
                this.appService.showNetworkstatus("sending data...");
                var sub = this.substatusesRepository.substatuses.find(x => x.id == this.substatusId);
                ss.replaceStatusId = this.substatusId;
                const loading = yield this.loadingCtrl.create({
                    message: this.currentLang.words["wait"],
                });
                loading.present();
                this.substatusesRepository.sendDeleteSubstatus(ss).subscribe(res => {
                    if (res.responseCode === 200) {
                        this.appService.showNetworkstatus("sent ok");
                        this.processesRepository.load();
                    }
                    else
                        this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                    loading.dismiss();
                    this.close();
                }, err => {
                    loading.dismiss();
                    const message = this.appService.getErrorMessage(err);
                    this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in substatus-replace-overlay delete sendDeleteSubstatus: ${message}`, true);
                });
                this.substatusesRepository.delete(sub);
            }
        });
    }
    replaceToGlobalStatus(statusId) {
        if (confirm(this.currentLang.words["sure"])) {
            var ss = { statusId: statusId, position: 0, replaceStatusId: 0, id: 0 };
            this.appService.showNetworkstatus("sending data...");
            var sub = this.substatusesRepository.substatuses.find(x => x.id == this.substatusId);
            ss.replaceStatusId = this.substatusId;
            this.substatusesRepository.sendDeleteSubstatus(ss).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.processesRepository.load();
                    this.substatusesRepository.delete(sub);
                    this.close();
                }
                else
                    this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in substatus-replace-overlay replaceToGlobalStatus sendDeleteSubstatus: ${message}`, true);
            });
        }
    }
}
