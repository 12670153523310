import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ILang } from 'src/app/model/lang.interface';
import { User } from 'src/app/model/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { LangsRepository } from 'src/app/services/langs.repository';
import { UsersRepository } from 'src/app/services/users.repository';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'settings-view-overlay',
    templateUrl: 'settings.overlay.page.html',
    styleUrls: ["../../app.component.scss", "settings.overlay.page.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SettingsOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    
    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        private langsRepository: LangsRepository,
        private usersRepository: UsersRepository
    ) {
        this.ready = true;
    }

    get user(): User { return this.authService.user; }
    set menuClass(v: string) { this.appService.menuClass = v; }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() { }

    public save(): void {
        this.usersRepository.sendUpdateUserSettings(this.user).subscribe(res => {
            this.authService.updateUserSettings(this.user);
            this.active = false;
            this.activeChange.emit(false);
        });
    }
}
