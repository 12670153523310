<error-popup *ngIf="langsReady" [currentLang]="currentLang" [active]="errorActive"
    (activeChange)="errorActive = $event;" [error]="error"></error-popup>

<download-popup *ngIf="langsReady && !isWhatsApp && !isGmail" [currentLang]="currentLang" [active]="downloadPopupActive"
    (activeChange)="downloadPopupActive = $event;"></download-popup>

<intro *ngIf="langsReady" [currentLang]="currentLang" [active]="introPopupActive"
    (activeChanged)="introPopupActive = $event;"></intro>

<notification-popup *ngIf="langsReady" [currentLang]="currentLang" [active]="notificationActive"
    [leadId]="notificationLeadId" [link]="notificationLink" [message]="notificationMessage"
    (activeChange)="notificationActive = $event;"></notification-popup>

<alert-notification-popup *ngIf="langsReady" [currentLang]="currentLang" [active]="followUpAlertNoticationActive"
    (activeChange)="followUpAlertNoticationActive = $event;" [leadId]="notificationLeadId"></alert-notification-popup>

<whatsApp-notification-popup *ngIf="langsReady" [currentLang]="currentLang" [active]="whatsAppNotificationActive"
    (activeChange)="whatsAppNotificationActive = $event;" [leadId]="notificationLeadId" [title]="notificationTitle" [message]="notificationMessage"></whatsApp-notification-popup>

<permission-popup *ngIf="langsReady" [currentLang]="currentLang" [active]="androidPopupActive"
    (activeChange)="androidPopupActive = $event;"></permission-popup>

<ie-warning *ngIf="langsReady" [currentLang]="currentLang" [active]="ieWarningPopupActive"
    (activeChange)="ieWarningPopupActive = $event;"></ie-warning>

<tooltip-notification-popup *ngIf="langsReady" [currentLang]="currentLang" [active]="tooltipPopupActive"
    [text]="textOfTooltipPopup" [title]="titleOfTooltipPopup" (activeChange)="tooltipPopupActive = $event;">
</tooltip-notification-popup>

<version-update-popup *ngIf="langsReady" [currentLang]="currentLang" [active]="versionUpdatePopupActive" [isIOS]="isIOS"
    (activeChange)="versionUpdatePopupActive = $event;"></version-update-popup>

<div id="app" *ngIf="langsReady" [dir]="currentLang.dir" [lang]="currentLang.slug">
    <div id="banner" class="full" *ngIf="isDesctop" [dir]="currentLang.dir"></div>
    <div id="app-menu" [class]="menuClass">
        <div id="am-main">
            <div id="am-head">
                <a *ngIf="user" routerLink='/leadClients'>
                    <img src="../assets/img/logo-color.png" alt="fixdigital">
                </a>
                <a *ngIf="!user && token" [routerLink]="'/promo/' + token">
                    <img src="../assets/img/logo-color.png" alt="fixdigital">
                </a>
                <a *ngIf="!user && !token" routerLink="/auth/login">
                    <img src="../assets/img/logo-color.png" alt="fixdigital">
                </a>
                <a class="backButton" (click)="menuClass = '';" [dir]="currentLang.dir" [lang]="currentLang.slug">
                    <ion-icon md="md-close" android="ios-close" ios="ios-close"
                        class="close-button {{currentLang.dir}}">
                    </ion-icon>
                </a>
            </div>
            <div id="am-content">
                <ul>
                    <li *ngIf="user && user.isAdmin" class="a-substatuses" (click)="substatusActive = true;">
                        {{currentLang.words["menu-statuses"]}}
                    </li>
                    <li *ngIf="user && user.isAdmin" class="a-processes" (click)="processesActive = true;">
                        {{currentLang.words["menu-processes"]}}</li>

                    <li *ngIf="user && user.isAdmin" class="a-users" (click)="usersActive = true;">
                        {{currentLang.words["menu-users"]}}
                    </li>
                    <li *ngIf="user" class="a-settings" (click)="settingsActive = true">
                         {{currentLang.words["user-notifications"]}}
                    </li>
                    <li *ngIf="user" class="a-logout" (click)="menuClass = '';" routerLink="/auth/logout">
                        {{currentLang.words["logout"]}}
                    </li>
                    <li *ngIf="user" class="fixdigital-login">
                        <a href="#" (click)="goToFixdigital()"
                            style="text-decoration: none;color: #455b65;">{{currentLang.words["menu-item-fixDigital"]}}</a>
                    </li>
                    <li *ngIf="currentLang.slug === 'en'" class="a-lang" (click)="setCurrentLang ('he');">
                        {{currentLang.words["switch-lang"]}}
                    </li>
                    <li *ngIf="currentLang.slug === 'he'" class="a-lang" (click)="setCurrentLang ('en');">
                        {{currentLang.words["switch-lang"]}}
                    </li>
                    <li class="a-download" *ngIf="!isMobile" (click)="showDownloadPopup()">
                        {{currentLang.words["download-app"]}}</li>
                    <li class="a-extension" *ngIf="!isMobile">
                        <a style="text-decoration: none;color: #455b65;"
                            href="https://chrome.google.com/webstore/detail/fixdigital-plugin-for-gma/pblpdhdohbbgoodcdnjiiinnhphhdlcn"
                            target="_blank">{{currentLang.words["extension-label"]}}</a>
                    </li>
                </ul>
                <p class="app-version" routerLink='/testpage' (click)="menuClass = '';">v{{versionNumber}}</p>
                <div class="menu-bottom a-download" *ngIf="!isMobile" (click)="showDownloadPopup()"
                    style="cursor: pointer;width: 100%;">
                    {{currentLang.words["download-app"]}}
                </div>
                <div class="menu-bottom" *ngIf="isMobile"style="width: 100%;"> </div>
            </div>

            <substatus-view-overlay [currentLang]="currentLang" [active]="substatusActive"
                (activeChange)="substatusActive = $event;">
            </substatus-view-overlay>

            <users-view-overlay [currentLang]="currentLang" [active]="usersActive"
                (activeChange)="usersActive = $event;">
            </users-view-overlay>

            <settings-view-overlay [currentLang]="currentLang" [active]="settingsActive"
                (activeChange)="settingsActive = $event;">
            </settings-view-overlay>
            
            <processes-view-overlay [currentLang]="currentLang" [active]="processesActive"
                (activeChange)="processesActive = $event;">
            </processes-view-overlay>
        </div>

        <div id="am-right" [dir]="currentLang.dir" (click)="menuClass = '';"></div>
    </div>
    <div id="app-content">
        <div id="networkstatus" [class]="networkstatusClass">{{networkstatus}}</div>
        <ion-router-outlet (activate)="onActivate ();"></ion-router-outlet>
    </div>
</div>