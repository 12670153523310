import { Model } from '../model';

export class LeadClientWhatsappReceivedMessage extends Model {
    constructor(
        public leadClientID?: number,
        public message?: string,
        public messageContentType? : string,
    ) {
        super();
    }
}
