import { Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ILang } from '../model/lang.interface';
import { LeadClient } from '../model/leadClients/lead.client.model';
import { LeadClientDatetime } from '../model/leadClients/lead.client.datetime';
import { IMyDpOptions } from 'mydatepicker';
import { IMyDpShortDate } from '../model/dp.shortdate.interface';
import { AppService } from '../services/app.service';

export abstract class LeadClientsCalendarComponent implements OnInit, OnChanges {
    @Input() currentLang: ILang;
    @Input() leadClient: LeadClient;
    @Output() followupChange: EventEmitter<LeadClientDatetime[]> = new EventEmitter<LeadClientDatetime[]>();
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() meetingId: number;
    @Input() isLastMeeting: boolean;
    @Input() date: string;
    @Input() dateEnd: string;
    @Input() status: number;

    public hours: number[] = [];
    public minutes: number[] = [];
    public options: IMyDpOptions = {
        inline: true,
        showTodayBtn: false,
        selectorWidth: "100%",
    };
    public currentDate: IMyDpShortDate = {};
    public currentDateEnd: IMyDpShortDate = {};
    public currentHour: number = 0;
    public currentHourEnd: number = 0;
    public currentMinute: number = 0;
    public currentMinuteEnd: number = 0;
    public ready: boolean = false;

    constructor(protected appService: AppService) { }

    ngOnInit(): void {
        this.hours = this.appService.range(0, 23);
        this.minutes = [0, 15, 30, 45];
        this.options.yearSelector = true;

        this.options.dayLabels = this.currentLang.slug === 'he'
            ? { su: 'א', mo: 'ב', tu: 'ג', we: 'ד', th: 'ה', fr: 'ו', sa: 'ש' }
            : { su: 'Sun', mo: 'Mon', tu: 'Tue', we: 'Wed', th: 'Thu', fr: 'Fri', sa: 'Sat' };

        this.options.monthLabels = this.currentLang.slug === 'he'
            ? { 1: 'ינואר', 2: 'פברואר', 3: 'מרץ', 4: 'אפריל', 5: 'מאי', 6: 'יוני', 7: 'יולי', 8: 'אוגוסט', 9: 'ספטמבר', 10: 'אוקטובר', 11: 'נובמבר', 12: 'דצמבר' }
            : { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.leadClient) {
            this.currentDate = this.leadClient.calendarFollowupDate;
            this.currentDateEnd = this.leadClient.calendarFollowupDate;
            this.currentHour = this.leadClient.followupHour;
            this.currentMinute = this.minutes.includes(this.leadClient.followupMinute) ? this.leadClient.followupMinute : 0;
            
            if (this.date) {
                var rawFollowup = new Date(this.date);
                this.currentDate = { date: { year: rawFollowup.getFullYear(), month: rawFollowup.getMonth() + 1, day: rawFollowup.getDate() } };
                this.currentHour = rawFollowup.getHours();
                this.currentMinute = this.minutes.includes(rawFollowup.getMinutes()) ? rawFollowup.getMinutes() : 0;
            }

            if (this.dateEnd) {
                var rawFollowupEnd = new Date(this.dateEnd);
                this.currentDateEnd = { date: { year: rawFollowupEnd.getFullYear(), month: rawFollowupEnd.getMonth() + 1, day: rawFollowupEnd.getDate() } };
                this.currentHourEnd = rawFollowupEnd.getHours();
                this.currentMinuteEnd = this.minutes.includes(rawFollowupEnd.getMinutes()) ? rawFollowupEnd.getMinutes() : 0;
            }

            this.ready = true;
        } else {
            this.ready = false;
        }
    }

    // now + N days
    public calendarForward(nDays: number): void {
        let now: Date = new Date();
        let timestamp: number = now.getTime() + 1000 * 60 * 60 * 24 * nDays;
        let newDate: Date = new Date(timestamp);
        this.currentDate = { date: { year: newDate.getFullYear(), month: newDate.getMonth() + 1, day: newDate.getDate() } };
        this.currentHour = newDate.getHours();
        this.currentMinute = 0;
    }

    public apply(): void {
        const dateEnd = new Date(this.currentDate.date.year, this.currentDate.date.month - 1, this.currentDate.date.day, this.currentHourEnd, this.currentMinuteEnd);
        if (+this.currentHourEnd < +this.currentHour || +this.currentHourEnd === +this.currentHour && +this.currentMinuteEnd < +this.currentMinute) {
            dateEnd.setDate(dateEnd.getDate() + 1);
        }
        this.followupChange.emit([{
            year: this.currentDate.date.year,
            month: this.currentDate.date.month,
            day: this.currentDate.date.day,
            hour: this.currentHour,
            minute: this.currentMinute
        }, {
            year: dateEnd.getFullYear(),
            month: dateEnd.getMonth() + 1,
            day: dateEnd.getDate(),
            hour: this.currentHourEnd,
            minute: this.currentMinuteEnd
        }]);
    }

    public cancelMeeting() {
        this.cancel.emit();
    }
}
