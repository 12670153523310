<div id="wrap">
    <header class="grad">
        <div id="h1" [dir]="currentLang.dir">{{filterName}}</div>
        <div id="h-left" [class]='currentLang.dir === "rtl" ? "rtl" : "ltr"'>
            <a class="menu settings-icon" (click)="menuClass='active';">{{currentLang.words["settings"]}}</a>
        </div>

        <div id="h-right" [class]='currentLang.dir === "rtl" ? "rtl" : "ltr"'>
            <a class="add"
                [routerLink]="isCordova && isAndroid ? '/leadClients/new/fromcalls' : '/leadClients/new/manual'"
                routerDirection="forward">{{currentLang.words["new-lead"]}}</a>
        </div>
    </header>
    <div id="main" [class]='currentLang.dir === "rtl" ? "medium rtl" : "medium ltr"' (scroll)="onScroll($event);">
        <div class="fixed-header">
            <div class="header-fast-filter">
                <span [ngClass]="[currentFilter === 0 ? 'active' : '', 'fast-filter-item']" (click)="changeFilter(0);">
                    {{currentLang.words["all-filter"]}}</span>
                <span [ngClass]="[currentFilter === 8 ? 'active' : '', 'fast-filter-item']" (click)="changeFilter(8);">
                    {{currentLang.words["new-clients"]}}</span>
                <span [ngClass]="[currentFilter === 4 ? 'active' : '', 'fast-filter-item']" (click)="changeFilter(4);">
                    {{currentLang.words["in-process-filter"]}}</span>
                <span [ngClass]="[currentFilter === 6 ? 'active' : '', 'fast-filter-item']" (click)="changeFilter(6);">
                    {{currentLang.words["future-meetings-filter"]}}</span>
            </div>
            <div id="leads-search">
                <form (submit)="search ();">
                    <div id="ls-icon" (click)="search ();"><span></span></div>
                    <div id="ls-input"><input type="text" autocomplete="off" name="search" [(ngModel)]="searchKey"
                            [placeholder]="currentLang.words['search']" (change)="search()"></div>
                    <div *ngIf="searchKey" id="ls-icon-clear" (click)="clearSearchInput()"><span></span></div>
                </form>
            </div>
            <div class="filter-sort-area">
                <div class="sort-area" [dir]="currentLang.dir">
                    <span class="label-sort-by">{{currentLang.words["sort-by"]}}:</span> <span
                        (click)="sortActive = !sortActive; advancedSearchActive = false;processesSearchActive=false;"
                        [ngClass]="['sort-by-el', sortActive ? 'revert-sort-ico' : '']">{{sortingName}}</span>
                    <span *ngIf="appService.dir == 0" (click)="changeDirection(1)"><ion-icon name="arrow-round-up" style="font-size: 22px;width: 20px;display: inline-block;position: relative;top: 5px;padding: 0 10px;cursor: pointer;"></ion-icon></span>
                    <span *ngIf="appService.dir == 1" (click)="changeDirection(0)"><ion-icon name="arrow-round-down" style="font-size: 22px;width: 20px;display: inline-block;position: relative;top: 5px;padding: 0 10px;cursor: pointer;"></ion-icon></span>
                </div>
                <div class="btn-area">
                    <button style="width: 80px;margin: 0 5px;" (click)="advancedSearchActive = !advancedSearchActive; sortActive = false;processesSearchActive = false;"
                        [ngClass]="['btn-filters', advancedSearchActive ? 'active-btn-filters' : '']">{{currentLang.words["filters"]}}</button>
                    <button *ngIf="isExistProcesses" style="width: 105px;margin: 0 5px;" (click)="processesSearchActive = !processesSearchActive; sortActive = false; advancedSearchActive = false;"
                        [ngClass]="['btn-filters', processesSearchActive ? 'active-btn-filters' : '']">{{currentLang.words["processes"]}}</button>
                </div>
            </div>
            <div id="leads-sort" [class]="sortActive ? 'active' : ''" [dir]="currentLang.dir" (click)="tryCloseSearch ($event);">
                <div id="la-wrap">
                    <div>
                        <ul *ngIf="sortActive" class="sort-active">
                            <li [class]="appService.sorting === 'followUp' ? 'active' : ''"
                                (click)="changeSorting ('followUp');">
                                {{currentLang.words["followUp"]}}</li>
                            <li [class]="appService.sorting === 'createDate' ? 'active' : ''"
                                (click)="changeSorting ('createDate');">
                                {{currentLang.words["createDate"]}}</li>
                            <li [class]="appService.sorting === 'updateDate' ? 'active' : ''"
                                (click)="changeSorting ('updateDate');">
                                {{currentLang.words["updateDate"]}}
                            </li>
                            <li [class]="appService.sorting === 'lastMessageDate' ? 'active' : ''"
                                (click)="changeSorting ('lastMessageDate');">
                                {{currentLang.words["last-message-date"]}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="leads-advancedsearch" [class]="advancedSearchActive || processesSearchActive ? 'active' : ''"
                [dir]="currentLang.dir" (click)="tryCloseSearch ($event);">
                <div id="la-wrap">
                    <div>
                        <ul *ngIf="processesSearchActive">
                            <li [class]="appService.processId === pr.id || (appService.processId == 0) ? 'active' : ''" (click)="changeFilterProcess (pr.id);" *ngFor="let pr of processes">{{pr.title}}</li>
                        </ul>
                        <ul *ngIf="advancedSearchActive">
                            <li [class]="currentFilter === 0 ? 'active' : ''" (click)="changeFilter (0);">{{currentLang.words["all-my-lead-clients"]}}</li>
                            <li [class]="currentFilter === 7 ? 'active' : ''" (click)="changeFilter (7);">{{currentLang.words["today-meetings"]}}</li>
                            <li [class]="currentFilter === 8 ? 'active' : ''" (click)="changeFilter (8);">{{currentLang.words["new-clients"]}}</li>
                            <li [class]="currentFilter === 4 ? 'active' : ''" (click)="changeFilter (4);">{{currentLang.words["lead-clients-in-process"]}}</li>
                            <li [class]="currentFilter === 6 ? 'active' : ''" (click)="changeFilter (6);">{{currentLang.words["future-meetings"]}}</li>
                            <li [class]="currentFilter === 1 ? 'active' : ''" (click)="changeFilter (1);">{{currentLang.words["closed-deals"]}}</li>
                            <li [class]="currentFilter === 3 ? 'active' : ''" (click)="changeFilter (3);">{{currentLang.words["overdue-leads"]}}</li>
                            <li [class]="currentFilter === 2 ? 'active' : ''" (click)="changeFilter (2);">{{currentLang.words["not-relevant-lead-clients"]}}</li>
                            <li [class]="currentFilter === 5 ? 'active' : ''" (click)="changeFilter (5);">{{currentLang.words["all-meetings"]}}</li>
                            <li [class]="currentFilter === 9 ? 'active' : ''" (click)="changeFilter (9);" *ngIf="!user.clientHasCRM">{{currentLang.words["return-clients"]}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div id="submain" *ngIf="langsReady && leadClientsReady && !appService.isLoadingLeadCLients && processesReady" class="leads leadClients">
            <div id="leads-list">
                <div class="lead-group" *ngFor="let lg of appService.leadClientsGroup">
                    <div class="lead-group-date">{{lg.date}}</div>
                    <lead-client-preview *ngFor="let leadClient of lg.leadClients" [leadClient]="leadClient"
                        [currentLang]="currentLang" [user]="user" [statusClass]="getStatusClass (leadClient)"
                        (showCalendar)="showCalendarWin (leadClient);" (showStatuses)="showStatusesWin(leadClient);">
                    </lead-client-preview>
                </div>
            </div>

            <lead-client-calendar-win [currentLang]="currentLang" [active]="appService.calendarActive"
                (activeChange)="appService.calendarActive = $event;" [leadClient]="leadClient4Calendar"
                (followupChange)="changeLeadClientFollowup ($event);"></lead-client-calendar-win>

            <lead-client-statuses-win [currentLang]="currentLang" [active]="appService.statusesActive"
                (activeChange)="onStatusesWinActiveChanged($event);" [leadClient]="leadClient4Statuses"
                [statuses]="statuses" (leadClientParamsChange)="leadClientParamsChanged = $event;"
                (leadClientFollowupChange)="leadClientFollowupChanged = $event;">
            </lead-client-statuses-win>
        </div>
        <div id="submain" *ngIf="!langsReady || !leadClientsReady || appService.isLoadingLeadCLients || !processesReady"
            style="margin-top: 210px;">
            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>

            <ion-skeleton-text animated style="height: 10px; margin-right: 30px; width: 30%;"></ion-skeleton-text>
            <ion-skeleton-text animated style="height: 40px; margin: 20px auto;width: 90%;"></ion-skeleton-text>
        </div>
        <footer class="compact grey">
            <div id="footer-legend">
                <div id="fl-statuses" [dir]="currentLang.dir">
                    <div class="fl-status new">{{currentLang.words["new"]}}</div>
                    <div class="fl-status meeting">{{currentLang.words["meeting"]}}</div>
                    <div class="fl-status inprocess">{{currentLang.words["in-process"]}}</div>
                    <div class="fl-status deal">{{currentLang.words["deal"]}}</div>
                    <div class="fl-status notrelevant">{{currentLang.words["not-relevant"]}}</div>
                </div>
            </div>
        </footer>

    </div>
</div>

<div class="lead-client-routing lead-client-hidden" [dir]="currentLang.dir" [lang]="currentLang.slug">
    <ion-router-outlet></ion-router-outlet>
</div>