import { ChangeDetectorRef, Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as signalR from '@microsoft/signalr';
import { Subscription } from 'rxjs';
import { AppService } from './services/app.service';
import { LangsRepository } from './services/langs.repository';
import { ILang } from './model/lang.interface';
import { AuthService } from './services/auth.service';
import { User } from './model/user.model';
import { CallService } from './services/call.service';
import { NotificationService } from './services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { environment } from './../environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { WhatsappService } from './services/whatsapp.service';
import { LeadClientWhatsappReceivedMessage } from './model/leadClients/lead.client.whatsapp.receivedmessage';
import { LeadClient } from './model/leadClients/lead.client.model';
import { MessageStatus } from './model/leadClients/lead.client.whatsapp.messagestatus';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	public langsReady = false;
	public routeCounter = 0;
	public showLeadsMenu = false;
	public token = "";
	public versionNumber: string;
	public twoComponents: boolean = false;

	public substatusActive: boolean = false;
	public usersActive: boolean = false;
	public settingsActive: boolean = false;
	public processesActive: boolean = false;

	signalRConnection: signalR.HubConnection;
	signalRConnectionSubscription: Subscription;

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private appService: AppService,
		private langsRepository: LangsRepository,
		private authService: AuthService,
		private callService: CallService,
		public readonly notificationService: NotificationService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		public appVersion: AppVersion,
		private mobileAccessibility: MobileAccessibility,
		private cdref: ChangeDetectorRef,
		private whatsappService: WhatsappService) {
		this.initializeApp();
		this.langsRepository.ready.subscribe(r => {
			this.langsReady = r;
		}, err => console.error(err));

		this.versionNumber = environment.version;

		this.route.params.subscribe(p => {
			this.token = p["token"];

			if (!this.token) {
				if (location.hash !== "#/auth/login") {
					var strs = location.hash.split('/');
					this.token = strs[strs.length - 1];
				}
			}
		});
	}


	public goToFixdigital() {
		if (this.isMobile) {
			var obj = new InAppBrowser().create(this.fixToken, '_system');
			obj.show();
		}
		else {
			window.open(this.fixToken);
		}
	}

	public hideLeadClient() {
		var elem = document.getElementsByClassName('lead-client-routing')[0];

		if (elem) {
			elem.classList.remove('lead-client-visible');
			elem.classList.add('lead-client-hidden');
		}
	}

	initializeApp() {
		if (this.isIeBrowser()) {
			this.appService.ieWarningPopupActive = true;
		}

		this.platform.ready().then(() => {
			if (this.isMobile) {
				this.statusBar.styleDefault();
				this.splashScreen.hide();
				this.callService.init();
				this.mobileAccessibility.usePreferredTextZoom(false);
				this.mobileAccessibility.setTextZoom(100);
			}

			// keep tab awake
			// https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-7.0&tabs=visual-studio#bsleep
			let lockResolver;
			let anyTypeNavigator: any;
			anyTypeNavigator = navigator;
			if (anyTypeNavigator && anyTypeNavigator.locks && anyTypeNavigator.locks.request) {
				const promise = new Promise((res) => {
					lockResolver = res;
				});
				anyTypeNavigator.locks.request('unique_lock_name', { mode: "shared" }, () => {
					return promise;
				});
			}

			this.whatsappService.signalRConnection$.subscribe((con: signalR.HubConnection) => {
				if (con !== null) {
					con.on("receiveMessage", (message, leadClientId, messageContentType) => {
						if (this.router.url.indexOf(`/${leadClientId}/whatsapp`) === -1) {
							const leadClient: LeadClient = this.appService.leadClients.find(x => x.id === leadClientId);
							if (leadClient) {
								this.notificationService.message = `${leadClient.fullName}: ${message}`;
								leadClient.lastMessageStatus = MessageStatus.Delivered;
							} else {
								this.notificationService.message = message;
							}
							this.notificationService.leadId = leadClientId;
							this.notificationService.link = `/leadClients/${leadClientId}/whatsapp`;

							this.notificationService.active = true;
							setTimeout(() => {
								this.notificationService.active = false;
								this.cdref.detectChanges();
							}, 5000);
							this.cdref.detectChanges();
						}
						this.whatsappService.setReceivedMessage(new LeadClientWhatsappReceivedMessage(leadClientId, message, messageContentType));
					});
					con.on("receiveMessageStatus", (message, leadClientId) => {
						this.whatsappService.setReceivedMessage(new LeadClientWhatsappReceivedMessage(leadClientId, message));
					});
					con.on("receiveNotification", (message, leadClientId) => {
						const leadClient: LeadClient = this.appService.leadClients.find(x => x.id === leadClientId);
						
						this.notificationService.message = message;
						this.notificationService.leadId = leadClientId;
						this.notificationService.link = `/leadClients/${leadClientId}`;

						this.notificationService.active = true;
						setTimeout(() => {
							this.notificationService.active = false;
							this.cdref.detectChanges();
						}, 5000);
						this.cdref.detectChanges();
						this.whatsappService.setReceivedMessage(new LeadClientWhatsappReceivedMessage(leadClientId, message));
					});
				}
			}, err => console.error(err));
		});
	}

	get networkstatusClass(): string { return this.appService.networkstatusClass; }
	get networkstatus(): string { return this.appService.networkstatus; }
	get errorActive(): boolean { return this.appService.errorActive; }
	set errorActive(v: boolean) { this.appService.errorActive = v; }
	get error(): string { return this.appService.error; }
	get currentLang(): ILang { return this.langsRepository.current; }
	get langs(): ILang[] { return this.langsRepository.langs; }
	get downloadPopupActive(): boolean { return this.appService.downloadPopupActive; }
	set downloadPopupActive(v: boolean) { this.appService.downloadPopupActive = v; }
	get androidPopupActive(): boolean { return this.appService.permissionPopupActive; }
	set androidPopupActive(v) { this.appService.permissionPopupActive = v; }
	get ieWarningPopupActive(): boolean { return this.appService.ieWarningPopupActive; }
	set ieWarningPopupActive(v) { this.appService.ieWarningPopupActive = v; }
	get versionUpdatePopupActive(): boolean { return this.appService.versionUpdatePopupActive }
	set versionUpdatePopupActive(v) { this.appService.versionUpdatePopupActive = v; }
	get menuClass(): string { return this.appService.menuClass; }
	set menuClass(v: string) { this.appService.menuClass = v; }
	get introPopupActive(): boolean { return this.appService.introPopupActive; }
	set introPopupActive(v: boolean) { this.appService.introPopupActive = v; }
	get user(): User { return this.authService.user; }
	get notificationActive(): boolean { return this.notificationService.active; }
	set notificationActive(v: boolean) { this.notificationService.active = v; }
	get tooltipPopupActive(): boolean { return this.appService.tooltipPopupActive; }
	set tooltipPopupActive(v: boolean) { this.appService.tooltipPopupActive = v; }
	get textOfTooltipPopup(): string { return this.appService.textOfTooltipPopup; }
	set textOfTooltipPopup(v: string) { this.appService.textOfTooltipPopup = v; }
	get titleOfTooltipPopup(): string { return this.appService.titleOfTooltipPopup; }
	set titleOfTooltipPopup(v: string) { this.appService.titleOfTooltipPopup = v; }
	get followUpAlertNoticationActive(): boolean { return this.notificationService.followUpAlert; }
	set followUpAlertNoticationActive(v: boolean) { this.notificationService.followUpAlert = v; }
	get whatsAppNotificationActive(): boolean { return this.notificationService.whatsAppAlert; }
	set whatsAppNotificationActive(v: boolean) { this.notificationService.whatsAppAlert = v; }
	get notificationTitle(): string { return this.notificationService.notificationTitle; }
	get notificationLeadId(): number { return this.notificationService.leadId; }
	get notificationLink(): string { return this.notificationService.link; }
	get notificationMessage(): string { return this.notificationService.message; }
	get isDesctop(): boolean { return !this.platform.is("cordova"); }
	get isMobile(): boolean { return this.platform.is("ios") || this.platform.is("android"); }
	get fixToken(): string { return `${environment.baseFixUrl}LogAs?fixToken=${this.authService.getToken()}`; }
	get isWhatsApp(): boolean { return location.hash.includes("#/lc/phone/"); }
	get isGmail(): boolean { return location.hash.includes("#/lc/email/"); }
	get isIOS(): boolean { return this.platform.is("ios"); }

	public onActivate(): void {
		if (!this.platform.is("cordova")) {
			if (this.routeCounter < 3) {
				this.routeCounter++;
			}

			if (this.routeCounter === 3) {
				this.appService.initDownloadPopup();
			}

			this.showLeadsMenu = this.router.url.includes('/l/')
			if (this.showLeadsMenu) {
				this.token = this.router.url.replace('/l/', '');
			}
		}
	}

	public showDownloadPopup(): void {
		this.appService.downloadPopupActive = true;
	}

	public setCurrentLang(langSlug: string): void {
		this.menuClass = "";
		this.langsRepository.setCurrent(langSlug);
	}

	public isIeBrowser() {
		var ua = window.navigator.userAgent;

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		// other browser
		return false;
	}
}
