import { Model } from '../model';
import { Lead } from '../lead.model';
import { IMyDpShortDate } from '../dp.shortdate.interface';
import { LeadClientMeeting } from './lead.client.meeting';
import { LeadDeal } from './lead.client.deal';
import { MessageStatus } from './lead.client.whatsapp.messagestatus';

export class LeadClient extends Model {
    constructor(
        public id?: number,
        public assigneeId?: number,
        public callRecordingUrl?: string,
        public callStatus?: string,
        public createDate?: string,
        public updateDate?: string,
        public amount?: number,
        public email?: string,
        public followUp?: string,
        public fullName?: string,
        public additionalStatusId?: number,
        public leadStatusId?: number,
        public phone?: string,
        public leads?: Lead[],
        public meetings?: LeadClientMeeting[],
        public deals?: LeadDeal[],
        public notes?: any[],
        public assetTitle?: string,
        public campaignTitle?: string,
        public referralUrl?: string,
        public source?: string,
        public address?: string,
        public addressCoords?: string,
        public processId?: number,
        public lastMessageDate?: string,
        public lastMessageStatus?: MessageStatus,
        public whatsappPhoneId?: number,
        public countryCode?: string,

        // not from API
        public rawFollowup?: Date,
        public rawCreatedate?: Date,
        public token?: string,
        public preview?: string,
        public current?: boolean,
        public showEmails?: string,
        public showPhones?: string,
        public countOfWhatsAppMessages?: number,

        //
        public emailError?: string,
        public phoneError?: string,
        public error?: string
    ) {
        super();
    }

    public init(): LeadClient {
        this.fullName = "";
        this.leadStatusId = 0;
        let d = new Date();
        // current date in format "1900-01-01T00:00:00.00"
        this.followUp = `${d.getFullYear()}-${this.twoDigits(d.getMonth() + 1)}-${this.twoDigits(d.getDate())}T${this.twoDigits(d.getHours())}:${this.twoDigits(d.getMinutes())}:00.00`;
        // this.CreateDate = this.FollowUp;
        this.notes = [];
        this.rawFollowup = new Date(this.followUp);
        if (!this.createDate) this.createDate = new Date().toString()
        this.rawCreatedate = new Date(this.createDate);

        return this;
    }

    public build(o: Object, token?: string): LeadClient {
        super.build(o);
        if (this.followUp)
            this.followUp = this.followUp.replace(/-/g, '/').replace("T", " ").split('.')[0];

        if (this.meetings) {
            this.meetings.sort((d1, d2) => new Date(d2.meetingDate).getTime() - new Date(d1.meetingDate).getTime());
            this.meetings.forEach(function (item) {
                item.meetingDate = item.meetingDate.replace(/-/g, '/').replace("T", " ");
            })
        }

        this.notes = [];
        this.rawFollowup = new Date(this.followUp);
        this.rawCreatedate = new Date(this.createDate);
        if (token) {
            this.token = token;
        }

        return this;
    }

    get formatFollowup(): string {
        return `${this.rawFollowup.getDate()}/${this.twoDigits(this.rawFollowup.getMonth() + 1)}/${this.rawFollowup.getFullYear()} ${this.twoDigits(this.rawFollowup.getHours())}:${this.twoDigits(this.rawFollowup.getMinutes())}`;
    }

    get formatCreatedate(): string {
        return `${this.rawCreatedate.getDate()}/${this.twoDigits(this.rawCreatedate.getMonth() + 1)}/${this.rawCreatedate.getFullYear()} ${this.twoDigits(this.rawCreatedate.getHours())}:${this.twoDigits(this.rawCreatedate.getMinutes())}`;
    }

    get calendarFollowupDate(): IMyDpShortDate {
        return { date: { year: this.rawFollowup.getFullYear(), month: this.rawFollowup.getMonth() + 1, day: this.rawFollowup.getDate() } };
    }

    get calendarCurrentDate(): IMyDpShortDate {
        return { date: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() } };
    }

    get followupHour(): number {
        return this.rawFollowup.getHours();
    }

    get followupMinute(): number {
        return this.rawFollowup.getMinutes();
    }

}
