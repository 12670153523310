import { Component } from '@angular/core';
import { LangsRepository } from '../../services/langs.repository';
import { ILang } from '../../model/lang.interface';

@Component({
	selector: 'comingsoon-page',
	templateUrl: 'comingsoon.page.html',
	styleUrls: ['comingsoon.page.scss', "../../app.component.scss"]
})

export class ComingsoonPage {
	public langsReady: boolean = false;

	constructor(
		private langsRepository: LangsRepository
	) {
		this.langsRepository.ready.subscribe(r => {
			if (r) {
				this.langsReady = true;
				var bannerElem = document.getElementById("banner");
        if (bannerElem) {
            document.getElementById("banner").classList.add('full');
        }
			}
		});
	}

	get currentLang(): ILang { return this.langsRepository.current; }

	public back(): void { window.history.back(); }
}
