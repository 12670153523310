import { Component, ViewEncapsulation } from '@angular/core';
import { LangsRepository } from '../services/langs.repository';
import { ILang } from '../model/lang.interface';
import { AppService } from '../services/app.service';
import { SubstatusesRepository } from '../services/substatuses.repository';
import { ISubstatus } from '../model/substatus.interface';
import { ProcessesRepositoryBUT } from '../services/processes.repository.but';
import { Location } from '@angular/common';
import { StatusType } from '../Enums/status.type.enum';
import { AuthService } from '../services/auth.service';
import { User } from '../model/user.model';

@Component({
    selector: 'substatuses-page',
    templateUrl: 'substatuses.page.html',
    styleUrls: ['substatuses.page.scss', "../app.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class SubstatusesPage {
    public langsReady: boolean = false;
    public substatusesReady: boolean = false;
    public processesReady: boolean = false;
    public substatusNewActive: boolean = false;
    public currentGlobalStatusId: number = 0;

    public currentStatusToEdit: ISubstatus = null;
    public substatusEditActive: boolean = false;

    public substatusReplaceActive: boolean = false;
    public currentReplaceStatusId: number = 0;

    constructor(
        private langsRepository: LangsRepository,
        private substatusesRepository: SubstatusesRepository,
        private processesRepository: ProcessesRepositoryBUT,
        private authService: AuthService,
        private appService: AppService,

        private readonly location: Location
    ) {
        this.init();
    }

    public back(): void {
        this.location.back();
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) { this.langsReady = true; } });
        this.processesRepository.prepare().then(() => { this.processesReady = true; });
        this.substatusesRepository.prepare().then(() => { this.substatusesReady = true; });
    }

    get currentLang(): ILang { return this.langsRepository.current; }
    get substatuses(): ISubstatus[] { return this.substatusesRepository.substatuses.sort(this.sortBy) }
    set menuClass(v: string) { this.appService.menuClass = v; }

    get blueSubs(): ISubstatus[] { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.InProcess).sort(this.sortBy); }
    get redSubs(): ISubstatus[] { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.NotGood).sort(this.sortBy); }
    get yellowSubs(): ISubstatus[] { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.Meeting).sort(this.sortBy); }
    get greenSubs(): ISubstatus[] { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.Closed).sort(this.sortBy); }

    get user(): User { return this.authService.user; }

    public sortBy(item1: ISubstatus, item2: ISubstatus) {
        if (item1.position < item2.position) return -1;
        if (item1.position > item2.position) return 1;

        if (item1.title > item2.title) return 1;
        else if (item1.title < item2.title) return -1;

        return 0;
    }

    public updatePositionInArray(substatus: ISubstatus, isUp: boolean) {
        this.appService.showNetworkstatus("sending data...");
        this.substatusesRepository.setPositionToSubstatus(substatus, isUp).subscribe((res) => {
            if (res.data) this.appService.showNetworkstatus("sent ok");
            else this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
        }, err => {
            const message = this.appService.getErrorMessage(err);
            this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in substatuses-page updatePositionInArray setPositionToSubstatus: ${message}`, true);
        });

        var sub = this.substatusesRepository.substatuses.find(x => x.id == substatus.id);
        if (sub) {
            var oldPosition = sub.position;
            var currentPosition = isUp ? sub.position - 1 : sub.position + 1;
            var sub2 = this.substatusesRepository.substatuses.find(x => x.statusId == substatus.statusId && x.position == currentPosition);

            sub.position = currentPosition;
            if (sub2) sub2.position = oldPosition;
        }
    }

    public goToEdit(substatus: ISubstatus) {
        this.currentStatusToEdit = substatus;
        this.substatusEditActive = true;
    }
}
