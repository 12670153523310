/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./test.page.scss.shim.ngstyle";
import * as i1 from "../../app.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "./test.page";
import * as i6 from "../../services/langs.repository";
import * as i7 from "../../services/log.service";
var styles_TestPage = [i0.styles, i1.styles];
var RenderType_TestPage = i2.ɵcrt({ encapsulation: 0, styles: styles_TestPage, data: {} });
export { RenderType_TestPage as RenderType_TestPage };
function View_TestPage_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "div", [["class", "log"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "log-text"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), (_l()(), i2.ɵeld(3, 0, null, null, 2, "div", [["class", "log-date"]], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), i2.ɵppd(5, 2)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.text; _ck(_v, 2, 0, currVal_0); var currVal_1 = i2.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i2.ɵnov(_v.parent.parent, 0), _v.context.$implicit.date, "dd/MM/yyyy HH:mm:ss")); _ck(_v, 4, 0, currVal_1); }); }
function View_TestPage_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 39, "div", [["id", "wrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 6, "header", [["class", "grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "div", [["id", "logo"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["id", "h1"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Test page"])), (_l()(), i2.ɵeld(5, 0, null, null, 2, "div", [["id", "h-left"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 1, "a", [["class", "back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(7, null, ["", ""])), (_l()(), i2.ɵeld(8, 0, null, null, 31, "div", [["id", "main"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 30, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 29, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 28, "div", [["class", "test-page-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(12, 0, null, null, 7, "div", [["class", "test-block"]], null, null, null, null, null)), (_l()(), i2.ɵeld(13, 0, null, null, 1, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Logs"])), (_l()(), i2.ɵeld(15, 0, null, null, 2, "div", [["class", "logs"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_TestPage_2)), i2.ɵdid(17, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵeld(18, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearLogs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Clear"])), (_l()(), i2.ɵeld(20, 0, null, null, 8, "div", [["class", "test-block token-block"]], null, null, null, null, null)), (_l()(), i2.ɵeld(21, 0, null, null, 1, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Token"])), (_l()(), i2.ɵeld(23, 0, null, null, 5, "input", [["placeholder", "token"], ["type", "password"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i2.ɵnov(_v, 24)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i2.ɵnov(_v, 24).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i2.ɵnov(_v, 24)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i2.ɵnov(_v, 24)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.token = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i2.ɵdid(24, 16384, null, 0, i4.DefaultValueAccessor, [i2.Renderer2, i2.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i2.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i2.ɵdid(26, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i2.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i2.ɵdid(28, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i2.ɵeld(29, 0, null, null, 10, "div", [["class", "test-block"]], null, null, null, null, null)), (_l()(), i2.ɵeld(30, 0, null, null, 1, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i2.ɵted(-1, null, ["Send test error (for Sentry testing)"])), (_l()(), i2.ɵeld(32, 0, null, null, 5, "input", [["placeholder", "error text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i2.ɵnov(_v, 33)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i2.ɵnov(_v, 33).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i2.ɵnov(_v, 33)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i2.ɵnov(_v, 33)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.errorText = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i2.ɵdid(33, 16384, null, 0, i4.DefaultValueAccessor, [i2.Renderer2, i2.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i2.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i2.ɵdid(35, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i2.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i2.ɵdid(37, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i2.ɵeld(38, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendTestError() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(-1, null, ["Send"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.logs; _ck(_v, 17, 0, currVal_2); var currVal_10 = _co.token; _ck(_v, 26, 0, currVal_10); var currVal_18 = _co.errorText; _ck(_v, 35, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["back"]; _ck(_v, 7, 0, currVal_0); var currVal_1 = ((_co.currentLang.dir === "rtl") ? "rtl" : "ltr"); _ck(_v, 8, 0, currVal_1); var currVal_3 = i2.ɵnov(_v, 28).ngClassUntouched; var currVal_4 = i2.ɵnov(_v, 28).ngClassTouched; var currVal_5 = i2.ɵnov(_v, 28).ngClassPristine; var currVal_6 = i2.ɵnov(_v, 28).ngClassDirty; var currVal_7 = i2.ɵnov(_v, 28).ngClassValid; var currVal_8 = i2.ɵnov(_v, 28).ngClassInvalid; var currVal_9 = i2.ɵnov(_v, 28).ngClassPending; _ck(_v, 23, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = i2.ɵnov(_v, 37).ngClassUntouched; var currVal_12 = i2.ɵnov(_v, 37).ngClassTouched; var currVal_13 = i2.ɵnov(_v, 37).ngClassPristine; var currVal_14 = i2.ɵnov(_v, 37).ngClassDirty; var currVal_15 = i2.ɵnov(_v, 37).ngClassValid; var currVal_16 = i2.ɵnov(_v, 37).ngClassInvalid; var currVal_17 = i2.ɵnov(_v, 37).ngClassPending; _ck(_v, 32, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); }); }
export function View_TestPage_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i3.DatePipe, [i2.LOCALE_ID]), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_TestPage_1)), i2.ɵdid(2, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.langsReady; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TestPage_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "test-page", [], null, null, null, View_TestPage_0, RenderType_TestPage)), i2.ɵdid(1, 49152, null, 0, i5.TestPage, [i6.LangsRepository, i3.Location, i7.LogService], null, null)], null, null); }
var TestPageNgFactory = i2.ɵccf("test-page", i5.TestPage, View_TestPage_Host_0, {}, {}, []);
export { TestPageNgFactory as TestPageNgFactory };
