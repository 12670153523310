<div *ngIf="ready" [class]="active ? 'overlay listClients '+currentLang.dir+' active' : 'overlay '+currentLang.dir">
    <div class="ol-main" style="width: 400px;">
        <div class="ol-head grad">
            <div class="ol-title">{{currentLang.words["followup"]}}</div>
            <div class="ol-close" (click)="close ();"><span></span></div>
        </div>
        <div class="ol-content">
            <div class="ol-flexblock center">
                <my-date-picker [(ngModel)]="currentDate" [options]="options" [locale]="currentLang.slug">
                </my-date-picker>
            </div>
            <div class="ol-flexblock center ltr-always">
                <select name="calendarHour" [(ngModel)]="currentHour">
                    <option *ngFor="let hour of hours" [value]="hour">{{hour | number:'2.0-0'}}</option>
                </select>
                &nbsp;&nbsp;:&nbsp;&nbsp;
                <select name="calendarMinute" [(ngModel)]="currentMinute">
                    <option *ngFor="let minute of minutes" [value]="minute">{{minute | number:'2.0-0'}}</option>
                </select>
            </div>
            <div class="ol-block center">
                <div class="action"><span (click)="calendarForward (1);">{{currentLang.words["1day-forward"]}}</span>
                </div>
                <div class="action"><span (click)="calendarForward (3);">{{currentLang.words["3days-forward"]}}</span>
                </div>
                <div class="action"><span (click)="calendarForward (7);">{{currentLang.words["1week-forward"]}}</span>
                </div>
            </div>
            <div class="ol-apply">
                <button (click)="apply ();">{{currentLang.words["apply"]}}</button>
            </div>
        </div>
    </div>
    <div class="ol-right" (click)="close ();"></div>
</div>