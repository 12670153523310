import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ILang } from 'src/app/model/lang.interface';
import { IStatus } from 'src/app/model/status.interface';
import { ISubstatus } from 'src/app/model/substatus.interface';
import { LangsRepository } from 'src/app/services/langs.repository';
import { LeadsRepositoryBUT } from 'src/app/services/leads.repository.but';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'substatus-edit-overlay',
    templateUrl: 'substatus.overlay.edit.html',
    styleUrls: ["../../app.component.scss", "substatus.overlay.edit.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class SubStatusEditOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() globalStatus: number = 0;
    @Input() substatus: ISubstatus = null;

    public langsReady = false;
    public substatusesReady = false;
    public processesReady = false;

    constructor(
        private langsRepository: LangsRepository,
        private substatusesRepository: SubstatusesRepository,
        private processesRepository: ProcessesRepositoryBUT,
        private leadsRespotory: LeadsRepositoryBUT,
        private appService: AppService
    ) {
        this.ready = true;
        this.init();
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) this.langsReady = true; });
        this.substatusesRepository.prepare().then(() => { this.substatusesReady = true; });
    }

    get statuses(): IStatus[] {
        var process = this.processesRepository.processes[0];
        return process ? process.statuses : [];
    }

    public validate(): boolean {
        let err = false;

        if (!this.substatus.title.trim().length) {
            this.substatus.titleError = this.currentLang.words['enter-status'];
            err = true;
        } else if (this.substatus.title.trim().length > 250) {
            this.substatus.titleError = this.currentLang.words['substatus-limit-error'];
            err = true;
        } else if (!/^[0-9a-zA-Z\u0590-\u05FF _-]+$/.test(this.substatus.title)) {
            this.substatus.titleError = this.currentLang.words['substatus-title-error'];
            err = true;
        } else { this.substatus.titleError = ""; }

        if (!this.substatus.position) {
            this.substatus.positionError = this.currentLang.words['substatus-position-error'];
            err = true;
        }

        if (this.substatus.position && this.substatus.position.toString().length > 10) {
            this.substatus.positionError = this.currentLang.words['substatus-position-error'];
            err = true;
        }

        if (!this.substatus.statusId) {
            this.substatus.statusIdError = this.currentLang.words['enter-status-stage'];
            err = true;
        } else { this.substatus.statusIdError = ""; }

        return !err;
    }

    public save(): void {
        if (this.substatus.template) this.substatus.template = this.appService.formatText(this.substatus.template, false);

        if (this.validate()) {
            this.substatusesRepository.sort();
            this.appService.showNetworkstatus("sending data...");
            this.substatusesRepository.sendUpdateSubstatus(this.substatus).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.processesRepository.load();
                    this.substatusesRepository.load();
                    this.substatusesRepository.sort();
                    this.close();
                } else if (res.responseCode === 400) {
                    this.appService.showError("API error", this.currentLang.words['status-exist'], true, res.responseCode);
                } else {
                    this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                }
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in substatus-edit-overlay save sendUpdateSubstatus: ${message}`, true);
            });
        }
    }
}
