import { Injectable } from "@angular/core";
import { ILeadTemplate } from '../model/leadtemplate.interface';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './DataServices/data.service';
import { ICalendarTemplate } from '../model/calendartemplate.interface';

@Injectable()
export class SettingsRepository {
    public leadTemplates: ILeadTemplate[] = [];
    public calendarTemplates: ICalendarTemplate[] = [];
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private dataService: DataService) {
        this.dataService.getTemplates().subscribe(data => {
            this.leadTemplates = data.leads;
            this.calendarTemplates = data.calendar;
            this.ready.next(true);
        });
    }
}