<div class="setting notification-setting-edit-component" *ngIf="currentLang && user">

    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (!user.isPushNotifications ? ' active' : '')" (click)="user.isPushNotifications = false;">
                SignalR</div>
            <div [class]="'switch-state' + (user.isPushNotifications ? ' active' : '')" (click)="user.isPushNotifications = true;">
                Push Notifications</div>
        </div>
    </div>

    <br />
    <br />

    <div class="edit-param">
        <input type="checkbox" [checked]="user.sendPushNewLead" (change)="user.sendPushNewLead = !user.sendPushNewLead" />
        <div class="settings-title" style="margin-left: 10px;margin-right: 10px;width: 85%;">{{currentLang.words["notification-settings-option-1"]}}</div>
    </div>
    <div class="edit-param">
        <input type="checkbox" [checked]="user.sendPushFollowUp" (change)="user.sendPushFollowUp = !user.sendPushFollowUp" />
        <div class="settings-title" style="margin-left: 10px;margin-right: 10px;width: 85%;">{{currentLang.words["notification-settings-option-2"]}}</div>
    </div>
    <div class="edit-param">
        <input type="checkbox" [checked]="user.sendMeetingCreatedEmail" (change)="user.sendMeetingCreatedEmail = !user.sendMeetingCreatedEmail" />
        <div class="settings-title" style="margin-left: 10px;margin-right: 10px;width: 85%;">{{currentLang.words["notification-settings-option-3"]}}</div>
    </div>
    <div class="edit-param">
        <input type="checkbox" [checked]="user.sendMeetingCanceledEmail" (change)="user.sendMeetingCanceledEmail = !user.sendMeetingCanceledEmail" />
        <div class="settings-title" style="margin-left: 10px;margin-right: 10px;width: 85%;">{{currentLang.words["notification-settings-option-4"]}}</div>
    </div>
   
</div>