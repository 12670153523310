<div *ngIf="ready"
    [class]="active ? 'overlay clientOverlay '+currentLang.dir+' active' : 'overlay clientOverlay '+currentLang.dir">
    <div class="ol-main">
        <div class="ol-head grad">
            <div class="ol-title">{{currentLang.words["replace-status"]}}</div>
            <div class="ol-close" (click)="close ();"><span></span></div>
        </div>
        <div class="ol-content">
            <div class="full">
                <div id="submain">
                    <div class="content replace-substatuses">
                        <div class="substatus" *ngFor="let ss of substatuses" (click)="delete(ss)">
                            <div class="ss-title" style="width: 100%;">{{ss.title}}</div>
                        </div>
                        <div class="substatus" *ngIf="subStatusForInProcess.length === 0" (click)="replaceToGlobalStatus(4)">
                            <div class="ss-title" style="width: 100%;">{{currentLang.words["in-process"]}}</div>
                        </div>

                        <div class="substatus" *ngIf="subStatusForMeetingProcess.length === 0" (click)="replaceToGlobalStatus(7)">
                            <div class="ss-title" style="width: 100%;">{{currentLang.words["meeting"]}}</div>
                        </div>

                        <div class="substatus" *ngIf="subStatusForDealProcess.length === 0" (click)="replaceToGlobalStatus(9)">
                            <div class="ss-title" style="width: 100%;">{{currentLang.words["deal"]}}</div>
                        </div>

                        <div class="substatus" *ngIf="subStatusForNotGoodProcess.length === 0" (click)="replaceToGlobalStatus(11)">
                            <div class="ss-title" style="width: 100%;">{{currentLang.words["not-relevant"]}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ol-right" (click)="close ();"></div>
</div>