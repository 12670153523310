<div id="intro" [class]="active ? 'active' : ''">
    <div id="intro-slider" [dir]="currentLang.dir" (mousedown)="onMouseDown($event);" (mouseup)="onMouseUp($event);"
        (mousemove)="onMouseMove($event);" (mouseleave)="onMouseLeave($event);" (touchstart)="onTouchStart($event);"
        (touchmove)="onTouchMove($event);" (touchend)="onTouchEnd($event);">
        <div id="intro-container"
            [ngStyle]="{'transform':'translateX('+pos+'vw)', 'transition-duration': transitionDuration}">
            <div class="intro-frame grad" [dir]="currentLang.dir">
                <div class="intro-frame-block">
                    <h2 class="intro-frame-title">{{currentLang.words["intro-title"]}}</h2>
                    <div class="intro-frame-img"><img src="../../assets/img/intro1.jpg" alt="intro"></div>
                    <div class="intro-frame-content" [innerHTML]="currentLang.words['intro1']"></div>
                </div>
            </div>
            <div class="intro-frame grad" [dir]="currentLang.dir">
                <div class="intro-frame-block">
                    <h2 class="intro-frame-title">{{currentLang.words["intro-title"]}}</h2>
                    <div class="intro-frame-img"><img src="../../assets/img/intro2.gif" alt="intro"></div>
                    <div class="intro-frame-content" [innerHTML]="currentLang.words['intro2']"></div>
                </div>
            </div>
            <div class="intro-frame grad" [dir]="currentLang.dir">
                <div class="intro-frame-block">
                    <h2 class="intro-frame-title">{{currentLang.words["intro-title"]}}</h2>
                    <div class="intro-frame-img"><img src="../../assets/img/intro3.gif" alt="intro"></div>
                    <div class="intro-frame-content" [innerHTML]="currentLang.words['intro3']"></div>
                </div>
            </div>
        </div>
    </div>
    <div id="intro-close" (click)="close ();">{{currentLang.words["skip"]}}</div>
    <div id="intro-steps" [dir]="currentLang.dir">
        <div [class]="step === 1 ? 'intro-step active' : 'intro-step'" (click)="toStep(1);"></div>
        <div [class]="step === 2 ? 'intro-step active' : 'intro-step'" (click)="toStep(2);"></div>
        <div [class]="step === 3 ? 'intro-step active' : 'intro-step'" (click)="toStep(3);"></div>
    </div>
    <div id="intro-nextblk">
        <div id="intro-nextbtn" [innerHTML]="step === n ? currentLang.words['finish'] : currentLang.words['next']"
            (click)="next ();"></div>
    </div>
</div>