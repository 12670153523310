import { Injectable } from "@angular/core";
import { LeadDatetime } from '../model/leaddatetime.interface';
import { Router } from '@angular/router';
import { Calendar } from '@ionic-native/calendar/ngx';
import { ICalendarEvent } from '../model/calendarevent.interface';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { LeadClient } from '../model/leadClients/lead.client.model';
import { Platform } from '@ionic/angular';
import { LeadClientsGroup } from "../model/leadClients/lead.clients.group.model";
import * as Sentry from "@sentry/angular"

@Injectable()
export class AppService {
    public menuClass = "";
    public networkstatus = "";
    public networkstatusClass = "";
    public networkstatusTimer: any = null;
    public error = "";
    public errorActive = false;
    public downloadPopupActive = false;
    public introPopupActive = false;
    public permissionPopupActive = false;
    public ieWarningPopupActive = false;
    public versionUpdatePopupActive = false;
    public tooltipPopupActive = false;
    public textOfTooltipPopup = "";
    public titleOfTooltipPopup = "";
    public isBusy = false;

    public leadClients: LeadClient[] = [];
    public leadClientsGroup: LeadClientsGroup[] = [];
    public sorting: string = "lastMessageDate";
    public dir: number = 1;
    public processId: number = null;
    public isLoadingLeadCLients = false;

    public statusesClass = "";
    public userClass = "";

    public currentFilter: number = 0;

    // for overlays
    public statusesActive: boolean = false;
    public calendarActive: boolean = false;

    public needResyncLeadClient: boolean = false;

    constructor(
        private platform: Platform,
        private router: Router,
        private calendar: Calendar,
        private readonly firebaseX: FirebaseX
    ) { }

    get isMobile(): boolean { return this.platform.is("mobile"); }
    public range(a: number, b: number): number[] {
        let res: number[] = [];

        for (let i: number = a; i <= b; i++) {
            res.push(i);
        }

        return res;
    }

    public twoDigits(n: number): string {
        return (n < 10) ? `0${n}` : `${n}`;
    }

    public showNetworkstatus(s: string): void {
        if (this.networkstatusTimer) {
            clearTimeout(this.networkstatusTimer);
        }

        this.networkstatus = s;
        this.networkstatusClass = "active";
        this.networkstatusTimer = setTimeout(() => {
            this.networkstatusClass = "";
            this.networkstatusTimer = null;
        }, 2000);
    }

    public showError(errType: string, errMsg: string, isServiceNotAvailable: boolean = false, responseCode: number = undefined, throwException = true,
                throwErrorMsg: string = null): void {
        const unauthorized: boolean = errMsg && (
                errMsg === "User not found"
                || errMsg === "code: 404, error: User not found"
                || errMsg.includes("Unauthorized")
                || errMsg.includes("- token filter error:")
                || responseCode === 401
        );
        const uuid = this.uuid();
        errMsg = errMsg.replace("{FAULT_NUMBER}", uuid);
        if (!isServiceNotAvailable) {
            this.error = `${uuid} ${errMsg}`;
            this.errorActive = true;
            let description = `url:${window.location.href}, id:${uuid}, type:${errType}, msg:${errMsg}`;
            
            if (this.platform.is("cordova") && !unauthorized) {
                this.firebaseX.logError(description); // crashlytics
            }
        } else {
            this.tooltipPopupActive = true;
            this.textOfTooltipPopup = errMsg;
            this.titleOfTooltipPopup = errType;
        }
        if (unauthorized) {
            this.router.navigateByUrl("/auth/logout");
        } else if (throwException) {
            throw new Error(throwErrorMsg || `${errType} - ${errMsg}`);
        }
    }

    public hideError() {
        this.error = "";
        this.errorActive = false;
    }

    public getErrorMessage(error: unknown) {
        if (error instanceof Error) {
            return error.message;
        } else if (typeof error === 'object') {
            return JSON.stringify(error);
        } else {
            return String(error);
        }
    }

    public initDownloadPopup(): void {
        if (!localStorage.getItem("lastPopup")) {
            localStorage.setItem("lastPopup", "0");
        }

        setInterval(() => {
            var lastPopup = parseInt(localStorage.getItem("lastPopup"));
            var now = (new Date()).getTime();

            if (now - lastPopup > 1000 * 60 * 60 * 24 * 5) { // once per 5 days            
                this.downloadPopupActive = true;
                localStorage.setItem("lastPopup", now.toString());
            }
        }, 5000);
    }

    public initPermissionPopup(): void {
        if (!localStorage.getItem("lastPopup1")) {
            localStorage.setItem("lastPopup1", "0");
        }

        setInterval(() => {
            var lastPopup = parseInt(localStorage.getItem("lastPopup1"));
            var now = (new Date()).getTime();
            if (now - lastPopup > 1000 * 60 * 60 * 24 * 5 && this.platform.is("cordova")) { // once per 5 days
                this.permissionPopupActive = true;
                localStorage.setItem("lastPopup1", now.toString());
            }
        }, 2000);
    }

    public initVersionUpdatePopup() {
        setInterval(() => {
            if (!localStorage.getItem("versionUpdatePopup")) {
                localStorage.setItem("versionUpdatePopup", "0");
            }

            var lastPopup = parseInt(localStorage.getItem("versionUpdatePopup"));
            var now = (new Date()).getTime();
            if (now - lastPopup > 1000 * 60 * 60 * 24 && this.platform.is("cordova")) { // once per 5 days
                this.versionUpdatePopupActive = true;
                localStorage.setItem("versionUpdatePopup", now.toString());
            }
        }, 2000);
    }

    public initIntroPopup(): void {
        if (!localStorage.getItem("notfirstrun")) {
            localStorage.setItem("notfirstrun", "1");
            this.introPopupActive = true;
        }
    }

    public uuid(): string {
        return Array
            .from(Array(32))
            .map((e, i) => {
                let someRandomValue = i === 12 ? 4 : (+new Date() + Math.random() * 16) % 16 | 0;
                return `${~[8, 12, 16, 20].indexOf(i) ? "-" : ""}${(i === 16 ? someRandomValue & 0x3 | 0x8 : someRandomValue).toString(16)}`;
            })
            .join("");
    }

    public leaddatetime2date(ldt: LeadDatetime): Date {
        if (Array.isArray(ldt)) {
            const year = ldt[0].year.toString();
            const month = this.twoDigits(ldt[0].month);
            const day = this.twoDigits(ldt[0].day);
            const hour = this.twoDigits(ldt[0].hour);
            const minute = this.twoDigits(ldt[0].minute);

            return new Date(`${year}/${month}/${day} ${hour}:${minute}:00`);
        }

        const year = ldt.year.toString();
        const month = this.twoDigits(ldt.month);
        const day = this.twoDigits(ldt.day);
        const hour = this.twoDigits(ldt.hour);
        const minute = this.twoDigits(ldt.minute);

        return new Date(`${year}/${month}/${day} ${hour}:${minute}:00`);
    }

    // raw Date to dd/mm/yyyy
    public fulldate2date(date: Date): string {
        return `${date.getDate()}/${this.twoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
    }

    public async SetCalendarEvent(leadCLient: LeadClient, schema: string, subject: string): Promise<void> {
        let eventName = this.buildEventName(leadCLient, schema);
        let eventNotes = `lead id: ${leadCLient.id}`;
        var eventTitle = subject == null ? eventName : subject;

        if (await this.getCalendarPermissions()) {
            let endDate = new Date(leadCLient.rawFollowup)
            endDate.setHours(leadCLient.rawFollowup.getHours() + 1);

            var eventTitle = subject == null ? eventName : subject;

            let existingEvents: ICalendarEvent[] = await this.calendar.findEvent(eventTitle, "", eventNotes, leadCLient.rawFollowup, endDate);

            if (existingEvents.length) {
                for (const ev of existingEvents) {
                    this.calendar.deleteEventById(ev.id);
                }
            }

            this.calendar.createEvent(eventTitle, "", eventNotes, leadCLient.rawFollowup, endDate);
        }
    }

    public async ModifyCalendarEvent(leadClient: LeadClient, schema: string, startDate: Date, subject: string) {
        let eventName = this.buildEventName(leadClient, schema);
        let eventNotes = `lead id: ${leadClient.id}`;
        var eventTitle = subject == null ? eventName : subject;

        if (await this.getCalendarPermissions()) {
            let endDate = new Date(startDate);
            endDate.setHours(startDate.getHours() + 1);

            this.calendar.modifyEvent(eventTitle, "", eventNotes, leadClient.rawFollowup, endDate, eventTitle, "", eventNotes, startDate, endDate)
        }
    }

    public async DeleteCalendarEvent(leadClient: LeadClient, schema: string, startDate: Date, subject: string) {
        let eventName = this.buildEventName(leadClient, schema);
        let eventNotes = `lead id: ${leadClient.id}`;
        var eventTitle = subject == null ? eventName : subject;

        if (await this.getCalendarPermissions()) {
            startDate.setHours(startDate.getHours());
            let endDate = new Date(startDate);
            endDate.setHours(startDate.getHours() + 1);

            this.calendar.deleteEvent(eventTitle, "", eventNotes, startDate, endDate)
        }
    }

    private getCalendarPermissions(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (await this.calendar.hasReadWritePermission()) {
                resolve(true);
            } else {
                await this.calendar.requestReadWritePermission();
                if (await this.calendar.hasReadWritePermission()) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }
        });
    }

    private buildEventName(leadCLient: LeadClient, schema: string): string {
        return schema
            .replace("{fullname}", leadCLient.fullName)
            .replace("{phone}", leadCLient.phone)
            .replace("{email}", leadCLient.email)
            .replace("{createdate}", leadCLient.formatCreatedate)
            .replace("{followup}", leadCLient.formatCreatedate)
            .replace(/\n/g, " ")
            .replace(/\r/g, " ")
    }

    public formatText(s: string, rebuildSpaces: boolean = true): string {
        let res = s.trim().replace(/\n/g, "<br>");

        if (rebuildSpaces) {
            res = res.replace(/ /g, "&nbsp;");
        }

        return res;
    }

    public rebuildGroups(leadClients: LeadClient[]): void {
        this.leadClientsGroup = [];
        leadClients.forEach(l => {
            let date: string = this.fulldate2date(new Date(l[this.sorting]));
            let existingLg: LeadClientsGroup | null = this.leadClientsGroup.find(lg => lg.date === date) || null;

            if (l.preview) {
                if (existingLg) {
                    let existingLeadClient: LeadClient | null = existingLg.leadClients.find(x => x.id == l.id);
                    if (!existingLeadClient) {
                        existingLg.leadClients.push(l);
                    }
                } else {
                    let newLg: LeadClientsGroup = new LeadClientsGroup(date, [l]);
                    this.leadClientsGroup.push(newLg);
                }
            }
        });
    }

    public buildGroups(leadClients: LeadClient[]): void {
        leadClients.filter(l => l[this.sorting] !== null).forEach(l => {
            let date: string = this.fulldate2date(new Date(l[this.sorting]));
            let existingLg: LeadClientsGroup | null = this.leadClientsGroup.find(lg => lg.date === date) || null;

            if (l.preview) {
                if (existingLg) {
                    let existingLeadClient: LeadClient | null = existingLg.leadClients.find(x => x.id == l.id);
                    if (!existingLeadClient) {
                        existingLg.leadClients.push(l);
                    }
                } else {
                    let newLg: LeadClientsGroup = new LeadClientsGroup(date, [l]);
                    this.leadClientsGroup.push(newLg);
                }
            }
        });
    }

    public logToSentry(message: string, params?: Record<string, string>) {
        const newError = new Error(message);
        console.error("TSTERR logToSentry", newError, params ? JSON.stringify(params) : "");
        Sentry.withScope(scope => {
            scope.setExtra("errorExtraData", newError);
            scope.setExtra("handlerSource", "app-service");
            if (params) {
                for (const key in params) {
                    scope.setExtra(key, params[key]);
                }
            }
            const eventId = Sentry.captureException(newError);
        });
    }

    // check if execution time worse than needed and log it
    public logTimeToSentry(desc: string, startTime: number, endTime: number) {
        const timeDiff = endTime - startTime;
        if (timeDiff > 5000) {
            const message = `Request takes long time | ${desc}`;
            this.logToSentry(message, { time: `${Math.round(timeDiff / 100) / 10} seconds` });
        }
    }
}
