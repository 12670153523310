<div *ngIf="ready"
    [class]="active ? 'overlay clientOverlay clientUsersOverlay '+currentLang.dir+' active' : 'overlay clientOverlay '+currentLang.dir">
    <div class="ol-main ol-main-users" style="width: 400px">
        <div class="ol-head grad">
            <div class="ol-title">{{currentLang.words["users"]}}</div>
            <div class="ol-close" (click)="close ();"><span></span></div>
        </div>
        <div class="ol-content">
            <users-page></users-page>
        </div>
    </div>
    <div class="ol-right" (click)="close ();"></div>
</div>
