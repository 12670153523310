import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Calendar } from '@ionic-native/calendar/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Platform } from '@ionic/angular';
import { LeadClientsGroup } from "../model/leadClients/lead.clients.group.model";
import * as Sentry from "@sentry/angular";
export class AppService {
    constructor(platform, router, calendar, firebaseX) {
        this.platform = platform;
        this.router = router;
        this.calendar = calendar;
        this.firebaseX = firebaseX;
        this.menuClass = "";
        this.networkstatus = "";
        this.networkstatusClass = "";
        this.networkstatusTimer = null;
        this.error = "";
        this.errorActive = false;
        this.downloadPopupActive = false;
        this.introPopupActive = false;
        this.permissionPopupActive = false;
        this.ieWarningPopupActive = false;
        this.versionUpdatePopupActive = false;
        this.tooltipPopupActive = false;
        this.textOfTooltipPopup = "";
        this.titleOfTooltipPopup = "";
        this.isBusy = false;
        this.leadClients = [];
        this.leadClientsGroup = [];
        this.sorting = "lastMessageDate";
        this.dir = 1;
        this.processId = null;
        this.isLoadingLeadCLients = false;
        this.statusesClass = "";
        this.userClass = "";
        this.currentFilter = 0;
        // for overlays
        this.statusesActive = false;
        this.calendarActive = false;
        this.needResyncLeadClient = false;
    }
    get isMobile() { return this.platform.is("mobile"); }
    range(a, b) {
        let res = [];
        for (let i = a; i <= b; i++) {
            res.push(i);
        }
        return res;
    }
    twoDigits(n) {
        return (n < 10) ? `0${n}` : `${n}`;
    }
    showNetworkstatus(s) {
        if (this.networkstatusTimer) {
            clearTimeout(this.networkstatusTimer);
        }
        this.networkstatus = s;
        this.networkstatusClass = "active";
        this.networkstatusTimer = setTimeout(() => {
            this.networkstatusClass = "";
            this.networkstatusTimer = null;
        }, 2000);
    }
    showError(errType, errMsg, isServiceNotAvailable = false, responseCode = undefined, throwException = true, throwErrorMsg = null) {
        const unauthorized = errMsg && (errMsg === "User not found"
            || errMsg === "code: 404, error: User not found"
            || errMsg.includes("Unauthorized")
            || errMsg.includes("- token filter error:")
            || responseCode === 401);
        const uuid = this.uuid();
        errMsg = errMsg.replace("{FAULT_NUMBER}", uuid);
        if (!isServiceNotAvailable) {
            this.error = `${uuid} ${errMsg}`;
            this.errorActive = true;
            let description = `url:${window.location.href}, id:${uuid}, type:${errType}, msg:${errMsg}`;
            if (this.platform.is("cordova") && !unauthorized) {
                this.firebaseX.logError(description); // crashlytics
            }
        }
        else {
            this.tooltipPopupActive = true;
            this.textOfTooltipPopup = errMsg;
            this.titleOfTooltipPopup = errType;
        }
        if (unauthorized) {
            this.router.navigateByUrl("/auth/logout");
        }
        else if (throwException) {
            throw new Error(throwErrorMsg || `${errType} - ${errMsg}`);
        }
    }
    hideError() {
        this.error = "";
        this.errorActive = false;
    }
    getErrorMessage(error) {
        if (error instanceof Error) {
            return error.message;
        }
        else if (typeof error === 'object') {
            return JSON.stringify(error);
        }
        else {
            return String(error);
        }
    }
    initDownloadPopup() {
        if (!localStorage.getItem("lastPopup")) {
            localStorage.setItem("lastPopup", "0");
        }
        setInterval(() => {
            var lastPopup = parseInt(localStorage.getItem("lastPopup"));
            var now = (new Date()).getTime();
            if (now - lastPopup > 1000 * 60 * 60 * 24 * 5) { // once per 5 days            
                this.downloadPopupActive = true;
                localStorage.setItem("lastPopup", now.toString());
            }
        }, 5000);
    }
    initPermissionPopup() {
        if (!localStorage.getItem("lastPopup1")) {
            localStorage.setItem("lastPopup1", "0");
        }
        setInterval(() => {
            var lastPopup = parseInt(localStorage.getItem("lastPopup1"));
            var now = (new Date()).getTime();
            if (now - lastPopup > 1000 * 60 * 60 * 24 * 5 && this.platform.is("cordova")) { // once per 5 days
                this.permissionPopupActive = true;
                localStorage.setItem("lastPopup1", now.toString());
            }
        }, 2000);
    }
    initVersionUpdatePopup() {
        setInterval(() => {
            if (!localStorage.getItem("versionUpdatePopup")) {
                localStorage.setItem("versionUpdatePopup", "0");
            }
            var lastPopup = parseInt(localStorage.getItem("versionUpdatePopup"));
            var now = (new Date()).getTime();
            if (now - lastPopup > 1000 * 60 * 60 * 24 && this.platform.is("cordova")) { // once per 5 days
                this.versionUpdatePopupActive = true;
                localStorage.setItem("versionUpdatePopup", now.toString());
            }
        }, 2000);
    }
    initIntroPopup() {
        if (!localStorage.getItem("notfirstrun")) {
            localStorage.setItem("notfirstrun", "1");
            this.introPopupActive = true;
        }
    }
    uuid() {
        return Array
            .from(Array(32))
            .map((e, i) => {
            let someRandomValue = i === 12 ? 4 : (+new Date() + Math.random() * 16) % 16 | 0;
            return `${~[8, 12, 16, 20].indexOf(i) ? "-" : ""}${(i === 16 ? someRandomValue & 0x3 | 0x8 : someRandomValue).toString(16)}`;
        })
            .join("");
    }
    leaddatetime2date(ldt) {
        if (Array.isArray(ldt)) {
            const year = ldt[0].year.toString();
            const month = this.twoDigits(ldt[0].month);
            const day = this.twoDigits(ldt[0].day);
            const hour = this.twoDigits(ldt[0].hour);
            const minute = this.twoDigits(ldt[0].minute);
            return new Date(`${year}/${month}/${day} ${hour}:${minute}:00`);
        }
        const year = ldt.year.toString();
        const month = this.twoDigits(ldt.month);
        const day = this.twoDigits(ldt.day);
        const hour = this.twoDigits(ldt.hour);
        const minute = this.twoDigits(ldt.minute);
        return new Date(`${year}/${month}/${day} ${hour}:${minute}:00`);
    }
    // raw Date to dd/mm/yyyy
    fulldate2date(date) {
        return `${date.getDate()}/${this.twoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
    }
    SetCalendarEvent(leadCLient, schema, subject) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let eventName = this.buildEventName(leadCLient, schema);
            let eventNotes = `lead id: ${leadCLient.id}`;
            var eventTitle = subject == null ? eventName : subject;
            if (yield this.getCalendarPermissions()) {
                let endDate = new Date(leadCLient.rawFollowup);
                endDate.setHours(leadCLient.rawFollowup.getHours() + 1);
                var eventTitle = subject == null ? eventName : subject;
                let existingEvents = yield this.calendar.findEvent(eventTitle, "", eventNotes, leadCLient.rawFollowup, endDate);
                if (existingEvents.length) {
                    for (const ev of existingEvents) {
                        this.calendar.deleteEventById(ev.id);
                    }
                }
                this.calendar.createEvent(eventTitle, "", eventNotes, leadCLient.rawFollowup, endDate);
            }
        });
    }
    ModifyCalendarEvent(leadClient, schema, startDate, subject) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let eventName = this.buildEventName(leadClient, schema);
            let eventNotes = `lead id: ${leadClient.id}`;
            var eventTitle = subject == null ? eventName : subject;
            if (yield this.getCalendarPermissions()) {
                let endDate = new Date(startDate);
                endDate.setHours(startDate.getHours() + 1);
                this.calendar.modifyEvent(eventTitle, "", eventNotes, leadClient.rawFollowup, endDate, eventTitle, "", eventNotes, startDate, endDate);
            }
        });
    }
    DeleteCalendarEvent(leadClient, schema, startDate, subject) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let eventName = this.buildEventName(leadClient, schema);
            let eventNotes = `lead id: ${leadClient.id}`;
            var eventTitle = subject == null ? eventName : subject;
            if (yield this.getCalendarPermissions()) {
                startDate.setHours(startDate.getHours());
                let endDate = new Date(startDate);
                endDate.setHours(startDate.getHours() + 1);
                this.calendar.deleteEvent(eventTitle, "", eventNotes, startDate, endDate);
            }
        });
    }
    getCalendarPermissions() {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (yield this.calendar.hasReadWritePermission()) {
                resolve(true);
            }
            else {
                yield this.calendar.requestReadWritePermission();
                if (yield this.calendar.hasReadWritePermission()) {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            }
        }));
    }
    buildEventName(leadCLient, schema) {
        return schema
            .replace("{fullname}", leadCLient.fullName)
            .replace("{phone}", leadCLient.phone)
            .replace("{email}", leadCLient.email)
            .replace("{createdate}", leadCLient.formatCreatedate)
            .replace("{followup}", leadCLient.formatCreatedate)
            .replace(/\n/g, " ")
            .replace(/\r/g, " ");
    }
    formatText(s, rebuildSpaces = true) {
        let res = s.trim().replace(/\n/g, "<br>");
        if (rebuildSpaces) {
            res = res.replace(/ /g, "&nbsp;");
        }
        return res;
    }
    rebuildGroups(leadClients) {
        this.leadClientsGroup = [];
        leadClients.forEach(l => {
            let date = this.fulldate2date(new Date(l[this.sorting]));
            let existingLg = this.leadClientsGroup.find(lg => lg.date === date) || null;
            if (l.preview) {
                if (existingLg) {
                    let existingLeadClient = existingLg.leadClients.find(x => x.id == l.id);
                    if (!existingLeadClient) {
                        existingLg.leadClients.push(l);
                    }
                }
                else {
                    let newLg = new LeadClientsGroup(date, [l]);
                    this.leadClientsGroup.push(newLg);
                }
            }
        });
    }
    buildGroups(leadClients) {
        leadClients.filter(l => l[this.sorting] !== null).forEach(l => {
            let date = this.fulldate2date(new Date(l[this.sorting]));
            let existingLg = this.leadClientsGroup.find(lg => lg.date === date) || null;
            if (l.preview) {
                if (existingLg) {
                    let existingLeadClient = existingLg.leadClients.find(x => x.id == l.id);
                    if (!existingLeadClient) {
                        existingLg.leadClients.push(l);
                    }
                }
                else {
                    let newLg = new LeadClientsGroup(date, [l]);
                    this.leadClientsGroup.push(newLg);
                }
            }
        });
    }
    logToSentry(message, params) {
        const newError = new Error(message);
        console.error("TSTERR logToSentry", newError, params ? JSON.stringify(params) : "");
        Sentry.withScope(scope => {
            scope.setExtra("errorExtraData", newError);
            scope.setExtra("handlerSource", "app-service");
            if (params) {
                for (const key in params) {
                    scope.setExtra(key, params[key]);
                }
            }
            const eventId = Sentry.captureException(newError);
        });
    }
    // check if execution time worse than needed and log it
    logTimeToSentry(desc, startTime, endTime) {
        const timeDiff = endTime - startTime;
        if (timeDiff > 5000) {
            const message = `Request takes long time | ${desc}`;
            this.logToSentry(message, { time: `${Math.round(timeDiff / 100) / 10} seconds` });
        }
    }
}
