<div [lang]="currentLang.slug" [class]="active ? 'popup active' : 'popup'" [dir]="currentLang.dir" 
    (click)="onPopupClick($event);">
    <div class="popup-win">
        <div class="popup-head grad">
            <div class="popup-title">{{currentLang.words["ie-warning-popup-title"]}}</div>
            <div class="popup-close" (click)="close();"><span></span></div>
        </div>
        <div class="popup-content">
            <div>
                <p>{{currentLang.words["ie-warning-popup-content"]}}</p>
            </div>
        </div>
        <div class="popup-foot">
            <button class="popup-close" (click)="close();">{{currentLang.words["close"]}}</button>
        </div>
    </div>
</div>