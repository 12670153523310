<div class="full" *ngIf="langsReady && processesReady">
	<div id="submain">
		<div class="content substatuses">
			<div class="global-status">
				<div *ngFor="let process of processes; index as i">
					<div class="substatus">
						<div class="ss-title" (click)="goToEdit(process)">{{process.title}}</div>
						<div class="ss-edit" *ngIf="user" (click)="goToEdit(process)"><span></span></div>
						<div class="ss-delete" *ngIf="user && process.id > 0 && process.title != 'כללי'" (click)="processReplaceActive = true; currentReplaceProcessId = process.id;"><span></span></div>
					</div>
				</div>
				<a class="add-new-process" (click)="processNewActive = true;" *ngIf="user">
					<span>{{currentLang.words["add-process"]}}</span>
				</a>
			</div>
		</div>
	</div>

	<processes-new-overlay [currentLang]="currentLang" [active]="processNewActive"
		(activeChange)="processNewActive = $event;">
	</processes-new-overlay>

	<processes-edit-overlay [currentLang]="currentLang" [active]="processEditActive"
		(activeChange)="processEditActive = $event;"
		[process]="currentProcessToEdit">
	</processes-edit-overlay>

	<process-replace-overlay [currentLang]="currentLang" [active]="processReplaceActive"
		[processId]="currentReplaceProcessId" 
		(activeChange)="processReplaceActive = $event" >
	</process-replace-overlay>
</div>