/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.ngstyle";
import * as i1 from "./substatus.overlay.replace.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./substatus.overlay.replace";
import * as i5 from "../../services/langs.repository";
import * as i6 from "../../services/substatuses.repository";
import * as i7 from "../../services/processes.repository.but";
import * as i8 from "../../services/app.service";
import * as i9 from "@ionic/angular";
var styles_SubStatusReplaceOverlayComponent = [i0.styles, i1.styles];
var RenderType_SubStatusReplaceOverlayComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_SubStatusReplaceOverlayComponent, data: {} });
export { RenderType_SubStatusReplaceOverlayComponent as RenderType_SubStatusReplaceOverlayComponent };
function View_SubStatusReplaceOverlayComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); }); }
function View_SubStatusReplaceOverlayComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.replaceToGlobalStatus(4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["in-process"]; _ck(_v, 2, 0, currVal_0); }); }
function View_SubStatusReplaceOverlayComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.replaceToGlobalStatus(7) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["meeting"]; _ck(_v, 2, 0, currVal_0); }); }
function View_SubStatusReplaceOverlayComponent_5(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.replaceToGlobalStatus(9) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["deal"]; _ck(_v, 2, 0, currVal_0); }); }
function View_SubStatusReplaceOverlayComponent_6(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.replaceToGlobalStatus(11) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["not-relevant"]; _ck(_v, 2, 0, currVal_0); }); }
function View_SubStatusReplaceOverlayComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 21, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 19, "div", [["class", "ol-main"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "div", [["class", "ol-head grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "ol-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "ol-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 13, "div", [["class", "ol-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 12, "div", [["class", "full"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 11, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 10, "div", [["class", "content replace-substatuses"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_SubStatusReplaceOverlayComponent_2)), i2.ɵdid(12, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_SubStatusReplaceOverlayComponent_3)), i2.ɵdid(14, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_SubStatusReplaceOverlayComponent_4)), i2.ɵdid(16, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_SubStatusReplaceOverlayComponent_5)), i2.ɵdid(18, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_SubStatusReplaceOverlayComponent_6)), i2.ɵdid(20, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(21, 0, null, null, 0, "div", [["class", "ol-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.substatuses; _ck(_v, 12, 0, currVal_2); var currVal_3 = (_co.subStatusForInProcess.length === 0); _ck(_v, 14, 0, currVal_3); var currVal_4 = (_co.subStatusForMeetingProcess.length === 0); _ck(_v, 16, 0, currVal_4); var currVal_5 = (_co.subStatusForDealProcess.length === 0); _ck(_v, 18, 0, currVal_5); var currVal_6 = (_co.subStatusForNotGoodProcess.length === 0); _ck(_v, 20, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? (("overlay clientOverlay " + _co.currentLang.dir) + " active") : ("overlay clientOverlay " + _co.currentLang.dir)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.currentLang.words["replace-status"]; _ck(_v, 4, 0, currVal_1); }); }
export function View_SubStatusReplaceOverlayComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_SubStatusReplaceOverlayComponent_1)), i2.ɵdid(1, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SubStatusReplaceOverlayComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "substatus-replace-overlay", [], null, null, null, View_SubStatusReplaceOverlayComponent_0, RenderType_SubStatusReplaceOverlayComponent)), i2.ɵdid(1, 49152, null, 0, i4.SubStatusReplaceOverlayComponent, [i5.LangsRepository, i6.SubstatusesRepository, i7.ProcessesRepositoryBUT, i8.AppService, i9.LoadingController], null, null)], null, null); }
var SubStatusReplaceOverlayComponentNgFactory = i2.ɵccf("substatus-replace-overlay", i4.SubStatusReplaceOverlayComponent, View_SubStatusReplaceOverlayComponent_Host_0, { active: "active", currentLang: "currentLang", substatusId: "substatusId", globalStatus: "globalStatus" }, { activeChange: "activeChange", cancel: "cancel" }, []);
export { SubStatusReplaceOverlayComponentNgFactory as SubStatusReplaceOverlayComponentNgFactory };
