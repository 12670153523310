import { Injectable, NgZone } from "@angular/core";
import { Platform, NavController } from '@ionic/angular';
import { AndroidPermissions, AndroidPermissionResponse } from '@ionic-native/android-permissions/ngx';
declare var PhoneCallTrap: any; // cordova plugin
import { Call } from '../model/call.interface';
import { AuthService } from './auth.service';
import { LeadsRepositoryBUT } from './leads.repository.but';
import { AppService } from './app.service';
import { Lead } from '../model/lead.model';

@Injectable()
export class CallService {
    private prevState = "";
    private state = "";
    private oldCalls: string[] = [];

    constructor(
        private readonly platform: Platform,
        private readonly androidPermissions: AndroidPermissions,
        private readonly authService: AuthService,
        private readonly leadsRepository: LeadsRepositoryBUT,
        private readonly appService: AppService,
        private readonly ngZone: NgZone,
        private readonly navCtrl: NavController,
    ) { }

    get canWork(): boolean { return this.platform.is("android") && (!this.platform.is('mobileweb')) }

    public async init(): Promise<void> {
        if (this.canWork) {
            if (await this.setPermissions()) {
                this.initCallProcessing();
                (<any>window).drawover.initialize(() => { }, () => { }, {});
                let strOldCalls: string = localStorage.getItem("oldCalls");

                if (strOldCalls) {
                    this.oldCalls = JSON.parse(strOldCalls);
                }
            }
        }
    }

    private async setPermissions(): Promise<boolean> {
        const values = await Promise.all([
            this.setPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE),
            this.setPermission(this.androidPermissions.PERMISSION.READ_CALL_LOG)
        ]);
        return values.every(i => i);
    }

    private async setPermission(permission: any): Promise<boolean> {
        let res: AndroidPermissionResponse = await this.androidPermissions.checkPermission(permission);

        if (res.hasPermission) {
            return true;
        }

        let res2: AndroidPermissionResponse = await this.androidPermissions.requestPermission(permission);
        return res2.hasPermission;
    }

    private initCallProcessing(): void {
        if (PhoneCallTrap) {
            PhoneCallTrap.onCall(async obj => {
                if (this.authService.authenticated) {
                    this.prevState = this.state;
                    let call: Call = JSON.parse(obj);
                    if (!call || !call.incomingNumber) {
                        return;
                    }
                    this.state = call.state;

                    if (call.incomingNumber.includes('+')) {
                        call.incomingNumber = call.incomingNumber.replace('+', '');
                    }

                    let lead: Lead | null = await this.findLead(call.incomingNumber);

                    if (this.state === "RINGING") { // incoming call

                        if (lead) {
                            this.showCallReceiveDrawOver(lead, call.incomingNumber);
                        } else {
                            this.showCallEndDrawOver(call.incomingNumber);
                        }
                    } else if (this.state === "IDLE") {
                        if (this.prevState === "RINGING") { // call ended without answer
                            if (lead) {
                                this.showCallReceiveDrawOver(lead, call.incomingNumber);
                            } else {
                                this.showCallEndDrawOver(call.incomingNumber);
                            }
                        } else if (this.prevState === 'OFFHOOK') { // call ended with answer
                            if (!this.oldCalls.includes(call.incomingNumber)) {
                                if (lead) {
                                    this.showCallReceiveDrawOver(lead, call.incomingNumber);
                                } else {
                                    this.showCallEndDrawOver(call.incomingNumber);
                                }
                                this.oldCalls.push(call.incomingNumber);
                                localStorage.setItem("oldCalls", JSON.stringify(this.oldCalls));
                            }
                        }
                    }
                }
            });
        }
    }

    private findLead(phone: string): Promise<Lead | null> {
        return new Promise((resolve, reject) => {
            this.leadsRepository.ready.subscribe(r => {
                if (r) {
                    this.leadsRepository.loadLeads(0, 1, phone, 0, "followUp", 0).subscribe(res => {
                        if (res.responseCode === 200) {
                            if (res.data.leads.length) {
                                resolve(new Lead().build(res.data.leads[0]));
                            } else {
                                resolve(null);
                            }
                        } else {
                            this.appService.showError("API error", `${res.responseCode} ${res.error || res.Error}`, false, res.responseCode);
                            resolve(null);
                        }
                    }, err => {
                        const message = this.appService.getErrorMessage(err);
                        this.appService.showError("HTTP error", `${message} in CallService findLead loadLeads`);
                        resolve(null);
                    });
                }
            });
        });
    }

    // using Dhaval plugin
    private showCallReceiveDrawOver(lead: Lead, phoneNumber: string): void {
        (<any>window).drawover.callReceive(
            () => {
                this.ngZone.run(() => {
                    (<any>window).plugins.bringtofront();
                    this.navCtrl.navigateForward(`/leadClients/${lead.leadClientId}`);
                });
            },
            (error) => { },
            { phoneNumber, name: lead.fullname }
        );
    }

    // using Dhaval plugin
    private showCallEndDrawOver(phoneNumber: string): void {
        (<any>window).drawover.callEnd(
            () => {
                this.ngZone.run(() => {
                    (<any>window).plugins.bringtofront();
                    this.navCtrl.navigateForward(`/leadClients/new/fromcalls/${phoneNumber}`);
                });
            },
            (error) => { },
            { phoneNumber }
        );
    }
}
