import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ILang } from '../../model/lang.interface';
import { AppService } from '../.././services/app.service';

@Component({
    selector: 'intro',
    templateUrl: 'intro.component.html',
    styleUrls: ["../../app.component.scss", "./intro.component.scss"],
})
export class IntroComponent {
    @Input() active: boolean;
    @Input() currentLang: ILang;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public appService: AppService) { }
    public n: number = 3;
    public step: number = 1;
    public pos: number = 0;
    private draggable: boolean = false;
    public transitionDuration: string = "0.3s";
    private prevX: number = 0;

    get dir(): number { return (this.currentLang.dir === "ltr") ? -1 : 1; }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
        this.appService.initPermissionPopup();
    }

    public toStep(i: number): void {
        this.step = i;
        this.setPosByStep();
    }

    public next(): void {
        if (this.step < this.n) {
            this.step++;
            this.setPosByStep();
        } else {
            this.close();
        }
    }

    public prev(): void {
        if (this.step > 1) {
            this.step--;
            this.setPosByStep();
        }
    }

    private setPosByStep(): void {
        this.pos = this.dir * 100 * (this.step - 1);
    }

    public onMouseDown(event: MouseEvent): void {
        if (event.button === 0) {
            this.draggable = true;
            this.transitionDuration = "0s";
            this.prevX = event.clientX;
        }
    }

    public onMouseMove(event: MouseEvent): void {
        if (this.draggable) {
            this.move(event.clientX);
        }
    }

    public onMouseUp(event: MouseEvent): void {
        if (event.button === 0) {
            this.stop();
            this.setPosByStep();
        }
    }

    public onTouchStart(event: TouchEvent): void {
        this.draggable = true;
        this.transitionDuration = "0s";
        this.prevX = event.touches[0].clientX;
    }

    public onTouchMove(event: TouchEvent): void {
        if (this.draggable) {
            this.move(event.touches[0].clientX);
        }
    }

    public onTouchEnd(event: TouchEvent): void {
        this.stop();
        this.setPosByStep();
    }

    public onMouseLeave(event: MouseEvent): void {
        this.onMouseUp(event);
    }

    private stop(): void {
        this.draggable = false;
        this.transitionDuration = "0.3s";
    }

    private move(x: number): void {
        let prepos: number = 0;

        if (this.prevX > x) {
            prepos = this.pos - 1;
        } else if (this.prevX < x) {
            prepos = this.pos + 1;
        } else {
            prepos = this.pos;
        }

        if (this.dir === 1 && prepos >= 0 && prepos <= (this.n - 1) * 100) { // rtl, can move          
            if (prepos > this.pos && prepos % 100 > 10) { // closer next
                this.stop();
                this.next();
            } else if (prepos < this.pos && prepos % 100 < 90) { // closer prev
                this.stop();
                this.prev();
            } else { // move on
                this.pos = prepos;
            }
        }

        if (this.dir === -1 && prepos <= 0 && prepos >= -(this.n - 1) * 100) { // ltr, can move          
            if (prepos < this.pos && prepos % 100 < -10) { // closer next
                this.stop();
                this.next();
            } else if (prepos > this.pos && prepos % 100 > -90) { // closer prev
                this.stop();
                this.prev();
            } else { // move on
                this.pos = prepos;
            }
        }

        this.prevX = x;
    }
}
