import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ILang } from '../../../model/lang.interface';

@Component({
    selector: 'ie-warning',
    templateUrl: 'ie.warning.popup.html',
    styleUrls: ["../../../app.component.scss"]
})

export class IEWarningComponent {
    @Input() active: boolean;
    @Input() currentLang: ILang;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public onPopupClick(event: MouseEvent): void {
        if ((event.target as HTMLElement).className === "popup active") {
            this.close();
        }
    }
}
