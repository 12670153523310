/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./error.popup.component";
var styles_ErrorPopupComponent = [i0.styles];
var RenderType_ErrorPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorPopupComponent, data: {} });
export { RenderType_ErrorPopupComponent as RenderType_ErrorPopupComponent };
export function View_ErrorPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["id", "error"]], [[8, "className", 0], [8, "dir", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["id", "error-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["id", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["id", "error-code"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["routerLink", "/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["id", "error-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [], null, null, null, null, null))], function (_ck, _v) { var currVal_4 = "/"; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? "active" : ""); var currVal_1 = _co.currentLang.dir; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.currentLang.words["error"]; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.error; _ck(_v, 4, 0, currVal_3); var currVal_5 = _co.currentLang.words["reload"]; _ck(_v, 7, 0, currVal_5); }); }
export function View_ErrorPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "error-popup", [], null, null, null, View_ErrorPopupComponent_0, RenderType_ErrorPopupComponent)), i1.ɵdid(1, 49152, null, 0, i3.ErrorPopupComponent, [], null, null)], null, null); }
var ErrorPopupComponentNgFactory = i1.ɵccf("error-popup", i3.ErrorPopupComponent, View_ErrorPopupComponent_Host_0, { active: "active", currentLang: "currentLang", error: "error" }, { activeChange: "activeChange" }, []);
export { ErrorPopupComponentNgFactory as ErrorPopupComponentNgFactory };
