/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comingsoon.page.scss.shim.ngstyle";
import * as i1 from "../../app.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./comingsoon.page";
import * as i5 from "../../services/langs.repository";
var styles_ComingsoonPage = [i0.styles, i1.styles];
var RenderType_ComingsoonPage = i2.ɵcrt({ encapsulation: 0, styles: styles_ComingsoonPage, data: {} });
export { RenderType_ComingsoonPage as RenderType_ComingsoonPage };
function View_ComingsoonPage_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 11, "div", [["id", "wrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 4, "header", [["class", "grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 0, "div", [["id", "logo"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 2, "div", [["id", "h-left"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "a", [["class", "back"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(5, null, ["", ""])), (_l()(), i2.ɵeld(6, 0, null, null, 5, "div", [["id", "main"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 4, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 3, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 2, "div", [["class", "comingsoon"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 1, "div", [["class", "comingsoon-imgwrap"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 0, "img", [["alt", "coming soon"], ["src", "../../assets/img/coming-soon.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["back"]; _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.currentLang.dir === "rtl") ? "full rtl" : "full ltr"); _ck(_v, 6, 0, currVal_1); }); }
export function View_ComingsoonPage_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_ComingsoonPage_1)), i2.ɵdid(1, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.langsReady; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ComingsoonPage_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "comingsoon-page", [], null, null, null, View_ComingsoonPage_0, RenderType_ComingsoonPage)), i2.ɵdid(1, 49152, null, 0, i4.ComingsoonPage, [i5.LangsRepository], null, null)], null, null); }
var ComingsoonPageNgFactory = i2.ɵccf("comingsoon-page", i4.ComingsoonPage, View_ComingsoonPage_Host_0, {}, {}, []);
export { ComingsoonPageNgFactory as ComingsoonPageNgFactory };
