import { Input, Component, Output, EventEmitter } from '@angular/core';
import { MessageStatus } from 'src/app/model/leadClients/lead.client.whatsapp.messagestatus';
import { LeadClient } from '../../model/leadClients/lead.client.model';
import { ILang } from '../../model/lang.interface';
import { NavController } from '@ionic/angular';
import { User } from '../../model/user.model';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: 'lead-client-preview',
    templateUrl: 'lead.client.preview.html',
    styleUrls: ["../../app.component.scss", "./lead.client.preview.scss"],
})

export class LeadCLientPreviewComponent {
    @Input() leadClient: LeadClient;
    @Input() currentLang: ILang;
    @Input() statusClass: string;
    @Input() user: User;

    @Output() showCalendar: EventEmitter<void> = new EventEmitter();
    @Output() showStatuses: EventEmitter<void> = new EventEmitter();
    private active: boolean = false;
    private wasMove: boolean = false;
    private movStartPos: number = 0;
    private movId: number = 0;
    public pos: number = 0;
    public transitionDuration: string = "0.3s";

    MessageStatus = MessageStatus;

    constructor(private navCtrl: NavController, private router: Router,
        private appService: AppService) { }

    public onMouseDown(event: MouseEvent): void {
        if (event.button === 0) {
            this.wasMove = false;
            this.active = true;
            this.transitionDuration = "0s";
            this.movStartPos = event.clientX - this.pos;
            this.movId = this.leadClient.id;
        }
    }

    public onMouseMove(event: MouseEvent): void {
        if (this.active) {
            this.wasMove = true;
            let prepos = event.clientX - this.movStartPos;
            this.move(prepos);
        }
    }

    public onMouseUp(event: MouseEvent): void {
        if (event.button === 0) {
            this.stop();

            if (!this.wasMove && this.movId === this.leadClient.id) {
                this.movId = 0;

                setTimeout(() => {
                    var elem = document.getElementsByClassName('lead-client-routing')[0];
                    if (elem) {
                        elem.classList.remove('lead-client-hidden');
                        elem.classList.add('lead-client-visible');
                    }

                    var bannerElem = document.getElementById("banner");
                    if (bannerElem) {
                        document.getElementById("banner").classList.remove('full');
                    }
                }, 50);

                this.navCtrl.navigateForward(`/leadClients/${this.leadClient.id}`);
            } else {
                this.pos = (this.pos < -50) ? -100 : 0;
            }
        }
    }

    public openWhatsAppPage() {
        this.navCtrl.navigateForward(`/leadClients/${this.leadClient.id}/whatsapp`);

        setTimeout(()=> {
            this.appService.leadClientsGroup.forEach((x) => {
                var lc = x.leadClients.find(c => c.id == this.leadClient.id);
                if (lc) {
                    lc.countOfWhatsAppMessages = 0
                }
            })
        },3000);
    }

    public onTouchStart(event: TouchEvent): void {
        this.wasMove = false;
        this.active = true;
        this.transitionDuration = "0s";
        this.movStartPos = event.touches[0].clientX - this.pos;
        this.movId = this.leadClient.id;
    }

    public onTouchMove(event: TouchEvent): void {
        if (this.active) {
            this.wasMove = true;
            let prepos = event.touches[0].clientX - this.movStartPos;
            this.move(prepos);
        }
    }

    public onTouchEnd(event: TouchEvent): void {
        this.stop();

        if (!this.wasMove && this.movId === this.leadClient.id) {
            this.movId = 0;

            setTimeout(() => {
                var elem = document.getElementsByClassName('lead-client-routing')[0];
                if (elem) {
                    elem.classList.remove('lead-client-hidden');
                    elem.classList.add('lead-client-visible');
                }
                
                var bannerElem = document.getElementById("banner");
                if (bannerElem) {
                    document.getElementById("banner").classList.remove('full');
                }
            }, 50);

            this.router.navigateByUrl(`/leadClients/${this.leadClient.id}`);
        } else {
            this.pos = (this.pos < -50) ? -100 : 0;
        }
    }

    public onMouseLeave(event: MouseEvent): void {
        // this.onMouseUp(event);
    }

    public onShowCalendar(): void {
        if (this.user && (this.user.canEditAllLeads || this.user.id === this.leadClient.assigneeId))
            this.showCalendar.emit();
    }

    public onShowStatuses(): void {
        if (this.user && (this.user.canEditAllLeads || this.user.id === this.leadClient.assigneeId))
            this.showStatuses.emit();
    }

    private stop(): void {
        this.active = false;
        this.transitionDuration = "0.3s";
    }

    private move(prepos: number): void {
        if (prepos >= -100 && prepos <= 0) {
            if (prepos < this.pos && prepos < -25 && prepos > -75) { // closer to left
                this.stop();
                this.pos = -100;
            } else if (prepos > this.pos && prepos > -75 && prepos < -25) { // closer to right
                this.stop();
                this.pos = 0;
            } else {
                this.pos = prepos; // move on
            }
        }
    }
}