import { Lead } from '../model/lead.model';
import { LeadNote } from '../model/leadnote.model';
import { DataService } from './DataServices/data.service';
import { LangsRepository } from './langs.repository';

export abstract class LeadsRepository {
    public leads: Lead[] = [];

    constructor(
        protected dataService: DataService,
        protected langsRepository: LangsRepository,
    ) {
    }

    get locale(): string { return this.langsRepository.current.locale; }

    public sortNotes(lead: Lead): void {
        if (lead.notes && lead.notes.length) {
            lead.notes.sort((a: LeadNote, b: LeadNote) => {
                return b.id - a.id;
            });
        }
    }

    // WARNING! dir==0 <=> [old first], dir==1 <=> [new first]
    public sortLeads(sorting: string, dir: number): void {
        let realSorting: string | null = null;

        // we must sort only if sorting setted to followUp or lastUpdate, in other cases date will not change and sorting is not needed!
        if (sorting === "followUp") realSorting = "rawFollowup";
        if (realSorting) this.leads = this.leads.sort((a: Lead, b: Lead) => (dir) ? b[realSorting] - a[realSorting] : a[realSorting] - b[realSorting]);
    }

    public reset(): void {
        this.leads = [];
    }
}