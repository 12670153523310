import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ILang } from '../../../model/lang.interface';

@Component({
    selector: 'tooltip-notification-popup',
    templateUrl: 'tooltip.popup.component.html',
    styleUrls: ["./tooltip.popup.component.scss"],
})
export class TooltipPopupComponent {
    @Input() active: boolean;
    @Input() currentLang: ILang;
    @Input() text: string = "";
    @Input() title: string = "";
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private navCtrl: NavController
    ) {
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public onPopupClick(event: MouseEvent): void {
        if ((event.target as HTMLElement).className === "popup active") {
            this.close();
        }
    }
}