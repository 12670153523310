<div id="wrap" *ngIf="langsReady">
	<header class="grad">
		<div id="logo"></div>
		<div id="h-left">
			<a class="back" (click)="back ();">{{currentLang.words["back"]}}</a>
		</div>
	</header>
	<div id="main" [class]='currentLang.dir === "rtl" ? "full rtl" : "full ltr"'>
		<div id="submain">
			<div class="content">
				<div class="comingsoon">
					<div class="comingsoon-imgwrap"><img src="../../assets/img/coming-soon.png" alt="coming soon"></div>
				</div>
			</div>
		</div>
	</div>
</div>