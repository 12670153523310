<div id="wrap" *ngIf="langsReady">
	<header class="grad">
		<div id="logo"></div>
		<div id="h-left">
			<a class="back" (click)="back ();">{{currentLang.words["back"]}}</a>
		</div>
	</header>
	<div id="main" [class]='currentLang.dir === "rtl" ? "rtl" : "ltr"'>
		<div id="submain">
			<div class="content">
				<div class="promo">
					<div class="promo-btnwrap">
						<button [routerLink]="'/registration/'+token">{{currentLang.words["payment-form"]}}</button>
					</div>
					<div class="promo-btnwrap">
						<button [routerLink]="'/auth/login/'+token">{{currentLang.words["signin"]}}</button>
					</div>
					<div class="promo-imgwrap" ><img src="../../assets/img/106.png" style="width: 70%;" alt="coming soon"></div>
				</div>
			</div>
		</div>
	</div>
</div>