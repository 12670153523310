<div *ngIf="ready"
    [class]="active ? 'overlay clientSubstatusOverlay clientOverlay '+currentLang.dir+' active' : 'overlay clientOverlay '+currentLang.dir">
    <div class="ol-main" style="width: 400px;">
        <div class="ol-head grad">
            <div class="ol-title">{{currentLang.words["menu-processes"]}}</div>
            <div class="ol-close" (click)="close ();"><span></span></div>
        </div>
        <div class="ol-content">
            <processes-page></processes-page>
        </div>
    </div>
    <div class="ol-right" (click)="close ();"></div>
</div>
