import { Injectable } from "@angular/core";
import { DataService } from './DataServices/data.service';
import { ISubstatus } from '../model/substatus.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { IResponse } from '../model/response.interface';

@Injectable()
export class SubstatusesRepository {
    public substatuses: ISubstatus[] = [];
    public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public lastReload: number = 0;
    public cacheKey: string = "";

    constructor(private dataService: DataService) { }

    public load(): void {
        this.dataService.token.subscribe(token => {
            this.loading.next(true);
            this.ready.next(false);
            this.substatuses = [];
            if (token) {
                this.dataService.but_getSubstatuses().subscribe(res => {
                    if (res.responseCode === 200) {
                        this.substatuses = (res.data.length) ? res.data : [];
                        this.sort();
                        this.lastReload = (new Date()).getTime();
                        this.setCacheKeyIfNotExistToLocalStorage(res.settingsCacheToken);
                        this.loading.next(false);
                        this.ready.next(true);
                    }
                }, err => { });
            } else {
                this.ready.next(false);
                this.substatuses = [];
            }
        });
    }

    public prepare(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.ready.value || this.loading.value) {
                resolve();
            } else {
                this.load();
                this.ready.subscribe(r => {
                    if (r) {
                        resolve();
                    }
                });
            }
        });
    }

    public getSubstatusById(id: number): ISubstatus | null { return this.substatuses.find(ss => ss.id === id) || null; }
    public sendAddSubstatus(substatus: ISubstatus): Observable<IResponse<ISubstatus>> { return this.dataService.but_addSubstatus(substatus); }
    public sendUpdateSubstatus(substatus: ISubstatus): Observable<IResponse<any>> { return this.dataService.but_updateSubstatus(substatus); }
    public sendDeleteSubstatus(substatus: ISubstatus): Observable<IResponse<any>> { return this.dataService.but_deleteSubstatus(substatus); }
    public setPositionToSubstatus(substatus: ISubstatus, isUp: boolean): Observable<IResponse<any>> { return this.dataService.but_setPositionToSubstatus(substatus, isUp); }

    public delete(substatus: ISubstatus): void {
        let index: number = this.substatuses.findIndex(ss => ss.id === substatus.id);

        if (index !== -1) {
            this.substatuses.splice(index, 1);
        }
    }

    public sort(): void {
        this.substatuses.sort((a: ISubstatus, b: ISubstatus) => a.position - b.position ? 1 : -1);
    }

    public setCacheKeyIfNotExistToLocalStorage(key: string) {
        var storeKey = localStorage.getItem('cacheKey');
        if (!storeKey) localStorage.setItem('cacheKey', key);
    }

    public getCurrentCacheKeyFromLocalStorage() {
        return localStorage.getItem('cacheKey');
    }

    public updateCacheKeyInLocalStorage(key: string) {
        localStorage.setItem('cacheKey', key);
    }
}