import { Injectable } from "@angular/core";
import { DataService } from './DataServices/data.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { IResponse } from '../model/response.interface';
import { LeadsList } from '../model/leadslist.interface';
import { LeadsRepository } from './leads.repository';
import { LangsRepository } from './langs.repository';

@Injectable()
export class LeadsRepositoryBUT extends LeadsRepository {
    public lastReload: number = 0;
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        protected dataService: DataService,
        protected langsRepository: LangsRepository,
    ) {
        super(dataService, langsRepository);
        this.dataService.token.subscribe(token => {
            if (token) {
                this.ready.next(true);
            } else {
                this.leads = [];
                this.ready.next(false);
            }
        });
    }

    public loadLeads(from: number, q: number, search: string, filter: number, sort: string, dir: number): Observable<IResponse<LeadsList>> {
        return this.dataService.but_getLeads(from, q, search, filter, sort, dir);
    }
}