<div [lang]="currentLang.slug" [class]="active ? 'popup active' : 'popup'" [dir]="currentLang.dir" 
    (click)="onPopupClick($event);">
    <div class="popup-win">
        <div class="popup-head grad">
            <div class="popup-title">{{currentLang.words["android-permission-popup-title"]}}</div>
            <div class="popup-close" (click)="close();"><span></span></div>
        </div>
        <div class="popup-content">
            <div>
                {{currentLang.words["android-permission-popup-content"]}}
            </div>
            <div>
                <ul class="android-popup-ul">
                    <li>{{currentLang.words["android-permission-popup-list-1"]}}</li>
                    <li>{{currentLang.words["android-permission-popup-list-2"]}}</li>
                    <li>{{currentLang.words["android-permission-popup-list-3"]}}</li>
                    <li>{{currentLang.words["android-permission-popup-list-4"]}}</li>
                    <li [hidden]="currentLang.words['android-permission-popup-list-5'] === ''" >{{currentLang.words["android-permission-popup-list-5"]}}</li>
                    <li [hidden]="currentLang.words['android-permission-popup-list-6'] === ''">{{currentLang.words["android-permission-popup-list-6"]}}</li>
                </ul>
            </div>

        </div>
        <div class="popup-foot">
            <button class="popup-close" (click)="close();">{{currentLang.words["close"]}}</button>
        </div>
    </div>
</div>