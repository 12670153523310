<div class="full" *ngIf="langsReady && substatusesReady">
	<div id="submain">
		<div class="content substatuses">
			<div class="global-status">
				<div class="in-process-status">{{currentLang.words["in-process"]}}</div>
				<div *ngFor="let ss of blueSubs; index as i">
					<div *ngIf="ss.statusId === 4" class="substatus">
						<div class="ss-title" (click)="goToEdit(ss)">
							{{ss.title}}</div>
						<div *ngIf="blueSubs.length-1 == i" class="ss-empty"><span></span></div>
						<div [ngClass]="[i != 0 ? 'ss-up' : 'ss-empty']" (click)="updatePositionInArray(ss, true)"><span></span></div>
						<div [ngClass]="[i != blueSubs.length-1 ? 'ss-down' : '']" (click)="updatePositionInArray(ss, false)"><span></span></div>
						<div class=" ss-edit" *ngIf="user" (click)="goToEdit(ss)"><span></span></div>
						<div class="ss-delete" *ngIf="user" (click)="substatusReplaceActive = true; currentReplaceStatusId = ss.id; currentGlobalStatusId = 4;"><span></span></div>
					</div>
				</div>
				<a class="add-new-status" (click)="currentGlobalStatusId = 4;substatusNewActive = true;" *ngIf="user">
					<span>{{currentLang.words["add-status"]}}</span>
				</a>
			</div>

			<div class="global-status">
				<div class="not-relevant-status">{{currentLang.words["not-relevant"]}}</div>
				<div *ngFor="let ss of redSubs; index as i">
					<div *ngIf="ss.statusId === 11" class="substatus">
						<div class="ss-title" (click)="goToEdit(ss)">
							{{ss.title}}</div>
						<div *ngIf="redSubs.length-1 == i" class="ss-empty"><span></span></div>
						<div [ngClass]="[i != 0 ? 'ss-up' : 'ss-empty']" (click)="updatePositionInArray(ss, true)"><span></span></div>
						<div [ngClass]="[i != redSubs.length-1 ? 'ss-down' : '']" (click)="updatePositionInArray(ss, false)"><span></span></div>
						<div class="ss-edit" *ngIf="user" (click)="goToEdit(ss)">
							<span></span>
						</div>
						<div class="ss-delete" *ngIf="user" (click)="substatusReplaceActive = true; currentReplaceStatusId = ss.id; currentGlobalStatusId = 11;">
							<span></span>
						</div>
					</div>
				</div>
				<a class="add-new-status" (click)="currentGlobalStatusId = 11;substatusNewActive = true;" *ngIf="user">
					<span>{{currentLang.words["add-status"]}}</span>
				</a>
			</div>

			<div class="global-status">
				<div class="meeting-status">{{currentLang.words["meeting"]}}</div>
				<div *ngFor="let ss of yellowSubs; index as i">
					<div *ngIf="ss.statusId === 7" class="substatus">
						<div class="ss-title" (click)="goToEdit(ss)">
							{{ss.title}}</div>
						<div *ngIf="yellowSubs.length-1 == i" class="ss-empty"><span></span></div>
						<div [ngClass]="[i != 0 ? 'ss-up' : 'ss-empty']" (click)="updatePositionInArray(ss, true)"><span></span></div>
						<div [ngClass]="[i != yellowSubs.length-1 ? 'ss-down' : '']" (click)="updatePositionInArray(ss, false)"><span></span></div>
						<div class="ss-edit" *ngIf="user" (click)="goToEdit(ss)">
							<span></span>
						</div>
						<div class="ss-delete" *ngIf="user" (click)="substatusReplaceActive = true; currentReplaceStatusId = ss.id; currentGlobalStatusId = 7;">
							<span></span>
						</div>
					</div>
				</div>
				<a class="add-new-status" (click)="currentGlobalStatusId = 7;substatusNewActive = true;" *ngIf="user">
					<span>{{currentLang.words["add-status"]}}</span>
				</a>
			</div>

			<div class="global-status">
				<div class="deal-status">{{currentLang.words["deal"]}}</div>
				<div *ngFor="let ss of greenSubs; index as i">
					<div *ngIf="ss.statusId === 9" class="substatus">
						<div class="ss-title">
							{{ss.title}}</div>
						<div *ngIf="greenSubs.length-1 == i" class="ss-empty"><span></span></div>
						<div [ngClass]="[i != 0 ? 'ss-up' : 'ss-empty']" (click)="updatePositionInArray(ss, true)"><span></span></div>
						<div [ngClass]="[i != greenSubs.length-1 ? 'ss-down' : '']" (click)="updatePositionInArray(ss, false)"><span></span></div>
						<div class="ss-edit" *ngIf="user"(click)="goToEdit(ss)">
							<span></span>
						</div>
						<div class="ss-delete" *ngIf="user" (click)="substatusReplaceActive = true; currentReplaceStatusId = ss.id;currentGlobalStatusId = 9;">
							<span></span>
						</div>
					</div>
				</div>
				<a class="add-new-status" (click)="currentGlobalStatusId = 9;substatusNewActive = true;" *ngIf="user">
					<span>{{currentLang.words["add-status"]}}</span>
				</a>
			</div>
		</div>
	</div>

	<substatus-new-overlay [currentLang]="currentLang" [active]="substatusNewActive"
		(activeChange)="substatusNewActive = $event;" [globalStatus]="currentGlobalStatusId">
	</substatus-new-overlay>

	<substatus-edit-overlay [currentLang]="currentLang" [active]="substatusEditActive"
		(activeChange)="substatusEditActive = $event;" [globalStatus]="currentGlobalStatusId"
		[substatus]="currentStatusToEdit">
	</substatus-edit-overlay>

	<substatus-replace-overlay [currentLang]="currentLang" [active]="substatusReplaceActive"
		[substatusId]="currentReplaceStatusId" [globalStatus]="currentGlobalStatusId" 
		(activeChange)="substatusReplaceActive = $event" >
	</substatus-replace-overlay>
</div>