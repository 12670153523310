<div id="wrap" *ngIf="langsReady">
    <header class="grad">
        <div id="h1">{{currentLang.words['registration']}}</div>
        <div id="h-left">
            <a class="back" (click)="back ();">{{currentLang.words["back"]}}</a>
        </div>
    </header>
    <div id="main" class="full" [class]='currentLang.dir === "rtl" ? "full rtl" : "full ltr"'>
        <div id="submain">
            <div class="content">
                <div id="login-logo" *ngIf="!IsApp"><img src="../../assets/img/logo-big.png" alt="fixdigital"></div>
                <ng-container *ngIf="step === 1">
                    <div class="payment-text">
                        <p [innerHTML]="currentLang.words['registrationInfo1']"></p>
                    </div>
                    <form (submit)="toStep2();">
                        <div class="edit">

                            <div class="edit-param">
                                <label [class]="firstName ? '' : 'empty'">{{currentLang.words["firstName"]}}</label>
                                <input name="firstName" type="text" [(ngModel)]="firstName" class="regist-inputs">
                                <div class="bg-white"></div>
                            </div>
                            <div class="edit-status error" [innerHTML]="firstNameError"></div>

                            <div class="edit-param">
                                <label [class]="lastName ? '' : 'empty'">{{currentLang.words["lastName"]}}</label>
                                <input name="lastName" type="text" [(ngModel)]="lastName" class="regist-inputs">
                                <div class="bg-white"></div>
                            </div>
                            <div class="edit-status error" [innerHTML]="lastNameError"></div>

                            <div class="edit-param">
                                <label [class]="phone ? '' : 'empty'">{{currentLang.words["phone"]}}</label>
                                <input name="phone" type="tel" [(ngModel)]="phone" class="regist-inputs" (keyup)="validatePhone();">
                                <div class="bg-white"></div>
                            </div>
                            <div class="edit-status error" [innerHTML]="phoneError"></div>

                            <div class="edit-param">
                                <label [class]="email ? '' : 'empty'">{{currentLang.words["email"]}}</label>
                                <input name="email" type="email" [(ngModel)]="email" class="regist-inputs">
                                <div class="bg-white"></div>
                            </div>
                            <div class="edit-status error" [innerHTML]="emailError"></div>

                            <div class="edit-param" style="margin-top: 15px">
                                <button class="big opaque" [disabled]="busy" type="submit"><span class="ico-check-in-btn">{{currentLang.words["send"]}}</span></button>
                            </div>
                            <div [class]="step1Error ? 'edit-status error' : 'edit-status'" [innerHTML]="step1Status"></div>
                        </div>
                    </form>
                    <!-- <div class="payment-text">
                        <p [innerHTML]="currentLang.words['registrationInfo2']"></p>
                    </div> -->
                </ng-container>
                <ng-container *ngIf="step === 2">
                    <ng-container *ngIf="showSendSms">
                        <div class="payment-text">
                            <p [innerHTML]="currentLang.words['registrationInfo3']"></p>
                        </div>
                        <form (submit)="finish();">
                            <div class="edit">
                                <div class="edit-param">
                                    <label [class]="passcode ? '' : 'empty'">{{currentLang.words["smscode"]}}</label>
                                    <input name="passcode" type="text" [(ngModel)]="passcode" class="regist-inputs">
                                    <div class="edit-status error" [innerHTML]="passcodeError"></div>
                                </div>
                                <div class="edit-param" style="width: 100%; display: inline-block;">
                                    <button class="big opaque" style="width: 100%;" [disabled]="busy"
                                        type="submit">{{currentLang.words["enter-passcode-button"]}}</button></div>
                                <div class="edit-link" style="display: inline-block;" (click)="resendSms()" [class]="IsRtl ? 'edit-link edit-link-rtl' : 'edit-link edit-link-en'">{{currentLang.words["didntreceive-registration"]}}</div>
                                <div [class]="step2Error ? 'edit-status error' : 'edit-status'"
                                    [innerHTML]="step2Status"></div>
                            </div>
                        </form>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>