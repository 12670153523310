<div class="edit substatus-edit-component" *ngIf="currentLang && process">
    <div class="edit-param">
        <label [class]="process.title.length ? '' : 'empty'">{{currentLang.words["name"]}} <span class="required"></span></label>
        <label *ngIf="process.title && process.title.length" class="empty clear-button"(click)="clearInput('title')">X</label>
        <input type="text" name="title" [(ngModel)]="process.title">
        <div class="bg-white"></div>
    </div>
    <div class="edit-status error" [innerHTML]="process.titleError"></div>
    <div class="content substatuses">
        <div class="global-status">
            <div class="in-process-status">{{currentLang.words["in-process"]}}</div>
            <div *ngFor="let ss of blueSubs; index as i">
                <div class="substatus">
                    <input type="checkbox" class="input-checkbox" [checked]="ss.isSelected" (change)="ss.isSelected = !ss.isSelected" />
                    <div class="ss-title" [dir]="currentLang.dir">{{ss.title}}</div>
                    <div *ngIf="blueSubs.length-1 == i" class="ss-empty"><span></span></div>
                </div>
            </div>
        </div>
        <div class="global-status">
            <div class="in-process-status">{{currentLang.words["not-relevant"]}}</div>
            <div *ngFor="let ss of redSubs; index as i">
                <div class="substatus">
                    <input type="checkbox" class="input-checkbox" [checked]="ss.isSelected" (change)="ss.isSelected = !ss.isSelected" />
                    <div class="ss-title" [dir]="currentLang.dir">{{ss.title}}</div>
                    <div *ngIf="blueSubs.length-1 == i" class="ss-empty"><span></span></div>
                </div>
            </div>
        </div>
        <div class="global-status">
            <div class="in-process-status">{{currentLang.words["meeting"]}}</div>
            <div *ngFor="let ss of yellowSubs; index as i">
                <div class="substatus">
                    <input type="checkbox" class="input-checkbox" [checked]="ss.isSelected" (change)="ss.isSelected = !ss.isSelected" />
                    <div class="ss-title" [dir]="currentLang.dir">{{ss.title}} </div>
                    <div *ngIf="blueSubs.length-1 == i" class="ss-empty"><span></span></div>
                </div>
            </div>
        </div>
        <div class="global-status">
            <div class="in-process-status">{{currentLang.words["deal"]}}</div>
            <div *ngFor="let ss of greenSubs;">
                <div class="substatus">
                    <input type="checkbox" class="input-checkbox" [checked]="ss.isSelected" (change)="ss.isSelected = !ss.isSelected"/>
                    <div class="ss-title" [dir]="currentLang.dir">{{ss.title}}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="edit-status error" [innerHTML]="substatus.titleError"></div>
    <div style="display: none" class="edit-param edit-param-select">
        <label [class]="substatus.statusId !== null ? '' : 'empty'">{{currentLang.words["stage"]}} <span
                class="required"></span></label>
        <select name="status" [(ngModel)]="substatus.statusId">
            <option [ngValue]="null"></option>
            <ng-container *ngFor="let s of statuses">
                <option *ngIf="s.id" [ngValue]="s.id">{{s.title}}</option>
            </ng-container>
        </select>
        <div class="bg-white select-bg"></div>
    </div>
    <div class="edit-status error" [innerHTML]="substatus.statusIdError"></div> -->
</div>