import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { IResponse } from "src/app/model/response.interface";
import { IAssignee } from "src/app/model/assignee.interface";
import { LeadClient } from "src/app/model/leadClients/lead.client.model";
import { LeadDeal } from "src/app/model/leadClients/lead.client.deal";
import { ISubstatus } from "src/app/model/substatus.interface";
import { LeadClientMeetingStatus } from "src/app/model/leadClients/lead.client.meeting.meetingstatus";

@Injectable()
export class WhatsAppDataService {
    private baseUrl: string;
    private leadClientBaseUrl: string;
    private tokenKey: string = "X-WHATSAPP-TOKEN";
    public locale: string = "";

    public token: BehaviorSubject<string> = new BehaviorSubject("");

    constructor(private http: HttpClient) {
        this.baseUrl = environment.baseUrlV1;
        this.leadClientBaseUrl = environment.baseUrlV2;
        this.locale = localStorage.getItem("currentLang") == "en" ? "en-US" : "he-IL";
    }

    private sendRequest(verb: string, url: string, body: Object | null, options: Object = {}): Observable<any> {
        if (verb === "POST") {
            return this.http.post(this.baseUrl + url, body, { headers: this.headers });
        } else if (verb === "PATCH") {
            return this.http.patch(this.baseUrl + url, body, { headers: this.headers });
        } else if (verb === "PUT") {
            return this.http.put(this.baseUrl + url, body, { headers: this.headers });
        } else if (verb === "DELETE") {
            return this.http.delete(this.baseUrl + url, { headers: this.headers });
        } else { // GET
            return this.http.get(this.baseUrl + url, { headers: this.headers });
        }
    }

    private sendRequest2(verb: string, url: string, body: Object | null, options: Object = {}): Observable<any> {
        if (verb === "POST") {
            return this.http.post(environment.baseUrlV2 + url, body, { headers: this.headers });
        } else if (verb === "PATCH") {
            return this.http.patch(environment.baseUrlV2 + url, body, { headers: this.headers });
        } else if (verb === "PUT") {
            return this.http.put(environment.baseUrlV2 + url, body, { headers: this.headers });
        } else if (verb === "DELETE") {
            return this.http.delete(environment.baseUrlV2 + url, { headers: this.headers });
        } else { // GET
            return this.http.get(environment.baseUrlV2 + url, { headers: this.headers });
        }
    }

    // v2
    get headers() { return new HttpHeaders({ "X-WHATSAPP-TOKEN": this.token.value, "X-LOCALE": this.locale }); };

    public getUserByToken(): Observable<any> {
        return this.sendRequest2("GET", "users/getByWhatsAppToken", null, null);
    }

    public getLeadClient(phone: string) {
        return this.sendRequest2("GET", "leadClient/getByPhone?phone=" + phone, null, null);
    }

    public getLeadClientByEmail(email: string) {
        return this.sendRequest2("GET", "leadClient/getByEmail?email=" + email, null, null);
    }

    public getAssignees(): Observable<IResponse<IAssignee[]>> {
        return this.sendRequest("GET", "assignees/getAssignees", null, { headers: this.headers });
    }

    public getProcesses(): Observable<IResponse<any>> {
        return this.sendRequest("GET", "processes/GetProcessesStatusesSubStatuses", null, null);
    }

    public getLeads(leadClientId: number) {
        return this.sendRequest2("GET", "leadClient/leads?id=" + leadClientId, null, null);
    }

    public getDeals(leadClientId: number) {
        return this.sendRequest2("GET", "leadClient/deals?id=" + leadClientId, null, null);
    }

    public getMeetings(leadClientId: number) {
        return this.sendRequest2("GET", "leadClient/meetings?id=" + leadClientId, null, null);
    }

    public getNotes(id: number) {
        return this.sendRequest2("GET", "leadClient/getNotes?id=" + id, null, null);
    }

    public addLeadClientNote(leadClientId: number, note: string, assigneeId: number, gpsLongitude: string, gpsLatitude: string, createdDate: string) {
        return this.sendRequest2("POST", "leadClient/createNote", { leadClientId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate }, null);
    }

    public updateLeadClientFollowUp(id: number, followUp: string) {
        return this.sendRequest2("PATCH", "leadClient/setFollowup", { Id: id, followUp: followUp }, null);
    }

    public updateLeadClientStatus(leadClient: LeadClient): Observable<IResponse<any>> {
        return this.sendRequest2("PATCH", "leadClient/setStatus", leadClient, null);
    }

    public updateLeadClient(leadClient: LeadClient): Observable<IResponse<any>> {
        return this.sendRequest2("PUT", "leadClient/update", leadClient, null);
    }

    public addLeadClient(leadClient: LeadClient): Observable<IResponse<number>> {
        return this.sendRequest2("POST", "leadClient/create",
            { fullname: leadClient.fullName, email: leadClient.email, phone: leadClient.phone, assigneeId: leadClient.assigneeId, leadStatusId: leadClient.leadStatusId, AdditionalStatusId: leadClient.additionalStatusId, source: leadClient.source, address: leadClient.address },
            null);
    }

    public updateLeadClientDeal(dealId, leadClientId: number, subject: string, amount: number, assigneeId: number, dealDate: string): Observable<IResponse<any>> {
        return this.sendRequest2("PATCH", "leadClient/setDeals", { dealId, leadClientId, subject, amount, assigneeId, dealDate, externalCrmId: 0 }, null)
    }

    public UpdateDeal(id: number, leadClientId: number, subject: string, amount: number, assigneeId: number): Observable<IResponse<number>> {
        return this.sendRequest2("PATCH", "leadClient/updateDeal", { id, leadClientId, subject, amount, assigneeId }, null);
    }

    public getDealById(id: number): Observable<IResponse<LeadDeal>> {
        return this.sendRequest2("GET", "leadClient/deal/getById?id=" + id, null, null);
    }

    public LeadClientCancelDeal(id: number) {
        return this.sendRequest2("GET", "leadClient/cancelDealById?id=" + id, null, null);
    }

    public GetMeetingById(id: number) {
        return this.sendRequest2("GET", "leadClient/getMeetingById?id=" + id, null, null);
    }

    public CancelMeetingById(id: number, locale: string) {
        return this.sendRequest2("GET", "leadClient/cancelMeetingById?id=" + id, null, null);
    }

    public UpdateMeeting(id: number, leadClientId: number, meetingDate: string, meetingEndDate: string, subject: string, meetingAssigneeId: number, externalId: number,
            note: string, meetingStatus: LeadClientMeetingStatus) {
        return this.sendRequest2("PATCH", "leadClient/updateMeeting", {
            id,
            leadClientId,
            meetingDate,
            meetingEndDate,
            subject,
            meetingAssigneeId,
            externalId,
            note,
            meetingStatus
        }, null);
    }

    public AddMeeting(id: number, leadClientId: number, meetingDate: string, meetingEndDate: string, subject: string, meetingAssigneeId: number, externalId: number,
            note: string, meetingStatus: LeadClientMeetingStatus) {
        return this.sendRequest2("PATCH", "leadClient/addMeeting", {
            id,
            leadClientId,
            meetingDate,
            meetingEndDate,
            subject,
            meetingAssigneeId,
            externalId,
            note,
            meetingStatus
        }, null);
    }

    public getSubstatuses(): Observable<IResponse<ISubstatus[]>> {
        return this.sendRequest("GET", "substatuses/getSubStatuses", null, null);
    }

    public addSubstatus(substatus: ISubstatus): Observable<IResponse<ISubstatus>> {
        return this.sendRequest("PUT", "substatuses/createSubStatus", substatus, null);
    }
}
