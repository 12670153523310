/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.ngstyle";
import * as i1 from "./processes.overlay.replace.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./processes.overlay.replace";
import * as i5 from "../../services/langs.repository";
import * as i6 from "../../services/processes.repository.but";
import * as i7 from "../../services/app.service";
import * as i8 from "../../services/lead.clients.repository.but";
import * as i9 from "../../services/substatuses.repository";
var styles_ProcessesReplaceOverlayComponent = [i0.styles, i1.styles];
var RenderType_ProcessesReplaceOverlayComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_ProcessesReplaceOverlayComponent, data: {} });
export { RenderType_ProcessesReplaceOverlayComponent as RenderType_ProcessesReplaceOverlayComponent };
function View_ProcessesReplaceOverlayComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["class", "ol-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["replace-process"]; _ck(_v, 1, 0, currVal_0); }); }
function View_ProcessesReplaceOverlayComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "div", [["class", "ol-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["select-status"]; _ck(_v, 1, 0, currVal_0); }); }
function View_ProcessesReplaceOverlayComponent_5(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setTargetProcess(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); }); }
function View_ProcessesReplaceOverlayComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "content replace-substatuses"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_5)), i2.ɵdid(2, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.processes; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProcessesReplaceOverlayComponent_7(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_0); }); }
function View_ProcessesReplaceOverlayComponent_8(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.replaceToGlobalStatus(4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["in-process"]; _ck(_v, 2, 0, currVal_0); }); }
function View_ProcessesReplaceOverlayComponent_9(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.replaceToGlobalStatus(7) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["meeting"]; _ck(_v, 2, 0, currVal_0); }); }
function View_ProcessesReplaceOverlayComponent_10(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.replaceToGlobalStatus(9) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["deal"]; _ck(_v, 2, 0, currVal_0); }); }
function View_ProcessesReplaceOverlayComponent_11(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "substatus"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.replaceToGlobalStatus(11) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.words["not-relevant"]; _ck(_v, 2, 0, currVal_0); }); }
function View_ProcessesReplaceOverlayComponent_6(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "div", [["class", "content replace-substatuses"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_7)), i2.ɵdid(2, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_8)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_9)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_10)), i2.ɵdid(8, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_11)), i2.ɵdid(10, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.substatusesFromTargetProcess; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.subStatusForInProcess.length === 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.subStatusForMeetingProcess.length === 0); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.subStatusForDealProcess.length === 0); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.subStatusForNotGoodProcess.length === 0); _ck(_v, 10, 0, currVal_4); }, null); }
function View_ProcessesReplaceOverlayComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 16, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 14, "div", [["class", "ol-main"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 6, "div", [["class", "ol-head grad"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_2)), i2.ɵdid(4, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_3)), i2.ɵdid(6, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(7, 0, null, null, 1, "div", [["class", "ol-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 6, "div", [["class", "ol-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 5, "div", [["class", "full"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 4, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_4)), i2.ɵdid(13, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_6)), i2.ɵdid(15, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(16, 0, null, null, 0, "div", [["class", "ol-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.targetProcessId; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.targetProcessId; _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.targetProcessId; _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.targetProcessId; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? (("overlay clientOverlay " + _co.currentLang.dir) + " active") : ("overlay clientOverlay " + _co.currentLang.dir)); _ck(_v, 0, 0, currVal_0); }); }
export function View_ProcessesReplaceOverlayComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesReplaceOverlayComponent_1)), i2.ɵdid(1, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProcessesReplaceOverlayComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "process-replace-overlay", [], null, null, null, View_ProcessesReplaceOverlayComponent_0, RenderType_ProcessesReplaceOverlayComponent)), i2.ɵdid(1, 49152, null, 0, i4.ProcessesReplaceOverlayComponent, [i5.LangsRepository, i6.ProcessesRepositoryBUT, i7.AppService, i8.LeadClientRepositoryBUT, i9.SubstatusesRepository], null, null)], null, null); }
var ProcessesReplaceOverlayComponentNgFactory = i2.ɵccf("process-replace-overlay", i4.ProcessesReplaceOverlayComponent, View_ProcessesReplaceOverlayComponent_Host_0, { active: "active", currentLang: "currentLang", processId: "processId" }, { activeChange: "activeChange", cancel: "cancel" }, []);
export { ProcessesReplaceOverlayComponentNgFactory as ProcessesReplaceOverlayComponentNgFactory };
