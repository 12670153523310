import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { IResponse } from '../model/response.interface';
import { Lead } from '../model/lead.model';
import { ILeadPrices } from '../model/leadprices.interface';
import { LeadNote } from '../model/leadnote.model';
import { LeadsRepository } from './leads.repository';

@Injectable()
export class LeadsRepositoryBLT extends LeadsRepository {
    public loadLeadByToken(token: string): Observable<IResponse<Lead>> {
        return this.dataService.blt_getLead(token, this.locale);
    }
    public getLeadByToken(token: string): Lead | null {
        return this.leads.find(l => l.token === token) || null;
    }
    public loadLeadPrices(token: string): Observable<IResponse<ILeadPrices>> {
        return this.dataService.blt_getLeadPrices(token);
    }
    public loadLeadNotes(token: string): Observable<IResponse<LeadNote[]>> {
        return this.dataService.blt_getLeadNotes(token);
    }
    public sendUpdateLeadFollowup(token: string, followUp: string): Observable<IResponse<Lead>> {
        return this.dataService.blt_updateLeadFollowup(token, followUp);
    }
    public sendAddLeadNote(token: string, note: LeadNote): Observable<IResponse<any>> {
        return this.dataService.blt_addLeadNote(token, note);
    }
    public sendUpdateLead(token: string, lead: Lead): Observable<IResponse<any>> {
        return this.dataService.blt_updateLead(token, lead);
    }
    public sendUpdateLeadStatus(token: string, statusId: number): Observable<IResponse<any>> {
        return this.dataService.blt_updateLeadStatus(token, statusId);
    }
    public sendUpdateLeadSubstatus(token: string, substatusId: number): Observable<IResponse<any>> {
        return this.dataService.blt_updateLeadSubstatus(token, substatusId);
    }
    public sendUpdateLeadDealAmount(token: string, amount: number): Observable<IResponse<any>> {
        return this.dataService.blt_updateLeadDealAmount(token, amount);
    }
}

