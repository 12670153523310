import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { IResponse } from '../../model/response.interface';
import { Lead } from '../../model/lead.model';
import { ILeadPrices } from '../../model/leadprices.interface';
import { LeadNote } from '../../model/leadnote.model';
import { IAssignee } from '../../model/assignee.interface';
import { ILang } from '../../model/lang.interface';
import { LeadsList } from '../../model/leadslist.interface';
import { ITemplates } from '../../model/templates.interface';
import { ISubstatus } from '../../model/substatus.interface';
import { User } from '../../model/user.model';
import { IIframeResponse } from '../../model/iframeresponse.interface';
import { environment } from '../../../environments/environment';
import { GetLeadClientByCrmTokenResponse } from "src/app/model/leadClients/get.lead.client.bycrmtoken.response";
import { LeadClientsList } from '../../model/leadClients/lead.clients.list.interface';
import { LeadClient } from '../../model/leadClients/lead.client.model';
import { LeadDeal } from '../../model/leadClients/lead.client.deal';
import { IProcess } from "src/app/model/process.interface";
import { LeadClientMeetingStatus } from "src/app/model/leadClients/lead.client.meeting.meetingstatus";

@Injectable()
export class DataService {
    private baseUrl: string;
    public token: BehaviorSubject<string> = new BehaviorSubject("");
    public userId: BehaviorSubject<number | null> = new BehaviorSubject(null);
    public locale: string = "";

    constructor(private http: HttpClient) {
        this.baseUrl = environment.baseUrlV1;
        this.locale = localStorage.getItem("currentLang") == "en" ? "en-US" : "he-IL";
    }

    get headers() { return new HttpHeaders({
        "X-CLIENT-TOKEN": this.token.value,
        "X-LOCALE": this.locale,
        "X-USER-ID": this.userId.value !== null ? this.userId.value.toString() : "",
    }); };

    // by lead token (BLT)
    public blt_getLead(token: string, locale: string): Observable<IResponse<Lead>> {
        return this.blt_sendRequest("GET", "lead/getByToken", null, token, locale);
    }

    public blt_getLeadPrices(token: string): Observable<IResponse<ILeadPrices>> {
        return this.blt_sendRequest("GET", "lead/getLeadPrices", null, token);
    }

    public blt_getLeadNotes(token: string): Observable<IResponse<LeadNote[]>> {
        return this.blt_sendRequest("GET", "notes/getNotes", null, token);
    }

    public blt_addLeadNote(token: string, note: LeadNote): Observable<IResponse<any>> {
        return this.blt_sendRequest("PUT", "notes/addNote", note, token);
    }

    public blt_updateLeadFollowup(token: string, followUp: string): Observable<IResponse<any>> {
        return this.blt_sendRequest("PATCH", "lead/setFollowup", { followUp: followUp }, token);
    }

    public blt_updateLead(token: string, lead: Lead): Observable<IResponse<any>> {
        return this.blt_sendRequest("POST", "lead/setByToken", lead, token);
    }

    public blt_updateLeadStatus(token: string, statusId: number): Observable<IResponse<any>> {
        return this.blt_sendRequest("PATCH", "lead/setStatus", { leadStatusId: statusId }, token);
    }

    public blt_updateLeadSubstatus(token: string, substatusId: number): Observable<IResponse<any>> {
        return this.blt_sendRequest("PATCH", "lead/setSubstatus", { leadAdditionalStatusId: substatusId }, token);
    }

    public blt_updateLeadDealAmount(token: string, amount: number): Observable<IResponse<any>> {
        return this.blt_sendRequest("PATCH", "lead/setDealAmount", { dealAmount: amount }, token);
    }

    public blt_getAssignees(token: string): Observable<IResponse<IAssignee[]>> {
        return this.blt_sendRequest("GET", "assignees/getAssignees", null, token);
    }

    public blt_getProcesses(token: string, locale: string): Observable<IResponse<any>> {
        return this.blt_sendRequest("GET", "processes/GetProcessesStatusesSubStatuses", null, token, locale);
    }

    public blt_getPaymentIframe(token: string, locale: string, login: string): Observable<IResponse<IIframeResponse>> {
        return this.blt_sendRequest("POST", "payment/requestPaymentIframe", { login }, token, locale);
    }

    public blt_addUserBySms(token: string, smsCode: string, login: string, locale: string): Observable<HttpResponse<IResponse<User>>> {
        return this.sendRequest("PUT", "users/createUserBySms", { smsCode, login }, { headers: new HttpHeaders({ "X-LEAD-TOKEN": token, "X-LOCALE": locale }), observe: "response" });
    }

    public blt_registrationProcess(token: string, email: string, phone: string, locale: string, firstName: string, lastName: string): Observable<HttpResponse<IResponse<User>>> {
        return this.sendRequest("PUT", "users/registrationProcess", { email, phone, firstName, lastName }, { headers: new HttpHeaders({ "X-LEAD-TOKEN": token, "X-LOCALE": locale }), observe: "response" });
    }

    public blt_registrationComplete(token: string, smsCode: string, email: string, phone: string, locale: string, firstName: string, lastName: string): Observable<HttpResponse<IResponse<User>>> {
        return this.sendRequest("PUT", "users/registrationComplete", { smsCode, email, phone, firstName, lastName }, { headers: new HttpHeaders({ "X-LEAD-TOKEN": token, "X-LOCALE": locale }), observe: "response" });
    }

    public blt_resendSmsForRegistration(token: string, id: number, locale: string): Observable<HttpResponse<IResponse<User>>> {
        return this.sendRequest("POST", 'users/resendSmsForRegistration', { id }, { headers: new HttpHeaders({ "X-LEAD-TOKEN": token, "X-LOCALE": locale }), observe: 'response' })
    }

    private blt_sendRequest(verb: string, url: string, body: Object | null, token: string | null, locale: string | null = null): Observable<any> {
        let headers: HttpHeaders = new HttpHeaders();

        if (token) headers = headers.set("X-LEAD-TOKEN", token);
        if (locale) headers = headers.set("X-LOCALE", locale);

        return this.sendRequest(verb, url, body, { headers: headers });
    }

    // BUT
    // by user token
    // WARNING! SortDirection==0 <=> [old first], SortDirection==1 <=> [new first]
    public but_getLeads(from: number, q: number, search: string, filter: number, sort: string, dir: number): Observable<IResponse<LeadsList>> {
        return this.sendRequest("POST", "lead/list", { Take: q, Skip: from, Search: search, Filter: filter, Sort: { Member: sort, SortDirection: `${dir}` } }, { headers: this.headers });
    }

    public but_getAssignees(): Observable<IResponse<IAssignee[]>> {
        return this.sendRequest("GET", "assignees/getAssignees", null, { headers: this.headers });
    }

    public but_getProcesses(locale: string): Observable<IResponse<any>> {
        return this.sendRequest("GET", "processes/GetProcessesStatusesSubStatuses", null, { headers: this.headers });
    }

    public but_getStatuses(): Observable<IResponse<any>> {
        return this.sendRequest("GET", "processes/GetStatusesSubStatuses", null, { headers: this.headers });
    }

    public but_createProcess(process: IProcess): Observable<IResponse<any>> {
        return this.sendRequest("POST", "processes/createProcess", process, { headers: this.headers });
    }

    public but_updateProcess(process: IProcess): Observable<IResponse<any>> {
        return this.sendRequest("POST", "processes/updateProcess", process, { headers: this.headers });
    }

    public but_deleteProcess(process: IProcess): Observable<IResponse<any>> {
        return this.sendRequest("POST", "processes/deleteProcess", process, { headers: this.headers });
    }

    public but_getSubstatuses(): Observable<IResponse<ISubstatus[]>> {
        return this.sendRequest("GET", "substatuses/getSubStatuses", null, { headers: this.headers });
    }

    public but_addSubstatus(substatus: ISubstatus): Observable<IResponse<ISubstatus>> {
        return this.sendRequest("PUT", "substatuses/createSubStatus", substatus, { headers: this.headers });
    }

    public but_updateSubstatus(substatus: ISubstatus): Observable<IResponse<any>> {
        return this.sendRequest("POST", "substatuses/updateSubStatus", substatus, { headers: this.headers });
    }

    public but_deleteSubstatus(substatus: ISubstatus): Observable<IResponse<any>> {
        return this.sendRequest("DELETE", "substatuses/deleteSubStatus", null, { headers: this.headers, body: substatus });
    }

    public but_getUsers(): Observable<IResponse<User[]>> {
        return this.sendRequest("GET", "users/getUsers", null, { headers: this.headers });
    }

    public but_addUser(user: User, locale: string): Observable<IResponse<User>> {
        return this.sendRequest("PUT", "users/createUser", user, { headers: this.headers });
    }

    public but_updateUser(user: User): Observable<HttpResponse<IResponse<User>>> {
        return this.sendRequest("POST", "users/updateUser", user, { headers: this.headers, observe: "response" });
    }

    public but_updateUserSettings(user: User): Observable<HttpResponse<IResponse<User>>> {
        return this.sendRequest("POST", "users/updateUserSettings", user, { headers: this.headers, observe: "response" });
    }

    public but_deleteUser(user: User, locale: string): Observable<IResponse<any>> {
        return this.sendRequest("DELETE", "users/deleteUser", null, { headers: this.headers, body: user });
    }

    // universal
    public getLangs(): Observable<ILang[]> {
        return this.http.get<ILang[]>("assets/json/langs.json");
    }

    public getTemplates(): Observable<ITemplates> {
        return this.http.get<ITemplates>("assets/json/templates.json");
    }

    public authGetToken(): Observable<HttpResponse<IResponse<any>>> {
        return this.sendRequest("GET", "auth/getAuthToken", null, { headers: this.headers });
    }

    public getCurrentVersionFromServer(): Observable<HttpResponse<IResponse<any>>> {
        return this.sendRequest2("GET", "info/version", null);
    }

    public authLogAs(clientToken: string): Observable<HttpResponse<IResponse<any>>> {
        return this.sendRequest("GET", "auth/logAs?clientToken=" + clientToken, null, { observe: "response" });
    }

    public login(username: string, password: string, locale: string): Observable<HttpResponse<IResponse<User>>> {
        return this.sendRequest("POST", "auth/login", { "username": `${username}`, "password": `${password}`, "Locale": `${locale}` }, { observe: "response" });
    }

    public forgotPassword(username: string, locale: string) {
        return this.sendRequest("POST", "auth/forgotPassword", { "username": `${username}`, "Locale": `${locale}` }, { observe: "response" });
    }

    // generic request
    private sendRequest(verb: string, url: string, body: Object | null, options: Object = {}): Observable<any> {
        if (verb === "POST") {
            return this.http.post(this.baseUrl + url, body, options);
        } else if (verb === "PATCH") {
            return this.http.patch(this.baseUrl + url, body, options);
        } else if (verb === "PUT") {
            return this.http.put(this.baseUrl + url, body, options);
        } else if (verb === "DELETE") {
            return this.http.delete(this.baseUrl + url, options);
        } else {
            return this.http.get(this.baseUrl + url, options);
        }
    }

    private sendRequest2(verb: string, url: string, body: Object | null): Observable<any> {
        if (verb === "POST") {
            return this.http.post(environment.baseUrlV2 + url, body, { headers: this.headers });
        } else if (verb === "PATCH") {
            return this.http.patch(environment.baseUrlV2 + url, body, { headers: this.headers });
        } else if (verb === "PUT") {
            return this.http.put(environment.baseUrlV2 + url, body, { headers: this.headers });
        } else if (verb === "DELETE") {
            return this.http.delete(environment.baseUrlV2 + url, { headers: this.headers });
        } else if (verb === "FILE") {
            return this.http.get(environment.baseUrlV2 + url, { headers: this.headers, observe: "response", responseType: "arraybuffer" });
        } else { // GET
            return this.http.get(environment.baseUrlV2 + url, { headers: this.headers });
        }
    }

    private sendRequest2WithCrmToken(token: string, verb: string, url: string, body: Object | null): Observable<any> {
        const headers = new HttpHeaders({ "X-WHATSAPPCRM-TOKEN": token });
        if (verb === "POST") {
            return this.http.post(environment.baseUrlV2 + url, body, { headers });
        } else if (verb === "PATCH") {
            return this.http.patch(environment.baseUrlV2 + url, body, { headers });
        } else if (verb === "PUT") {
            return this.http.put(environment.baseUrlV2 + url, body, { headers });
        } else if (verb === "DELETE") {
            return this.http.delete(environment.baseUrlV2 + url, { headers });
        } else if (verb === "FILE") {
            return this.http.get(environment.baseUrlV2 + url, { headers, observe: "response", responseType: "arraybuffer" });
        } else { // GET
            return this.http.get(environment.baseUrlV2 + url, { headers });
        }
    }

    // v2

    public but_getLeadClients(from: number, q: number, search: string, filter: number, sort: string, dir: number, processId: number): Observable<IResponse<LeadClientsList>> {
        return this.sendRequest2("POST", "leadClient/list", { Take: q, Skip: from, Search: search, Filter: filter, processId: processId, Sort: { Member: sort, SortDirection: `${dir}` } });
    }

    public but_updateLeadClientFollowUp(id: number, followUp: string) {
        return this.sendRequest2("PATCH", "leadClient/setFollowup", { Id: id, followUp: followUp });
    }

    public but_updateLeadClientStatus(leadClient: LeadClient): Observable<IResponse<any>> {
        return this.sendRequest2("PATCH", "leadClient/setStatus", leadClient);
    }

    public but_getLeadClient(id: number) {
        return this.sendRequest2("GET", "leadClient/getByToken?id=" + id, null);
    }

    public but_addLeadClient(leadClient: LeadClient): Observable<IResponse<number>> {
        return this.sendRequest2("POST", "leadClient/create",
            { fullname: leadClient.fullName, email: leadClient.email, phone: leadClient.phone, assigneeId: leadClient.assigneeId, processId: leadClient.processId, leadStatusId: leadClient.leadStatusId, AdditionalStatusId: leadClient.additionalStatusId, source: leadClient.source, address: leadClient.address });
    }

    public but_updateLeadClient(leadClient: LeadClient): Observable<IResponse<any>> {
        return this.sendRequest2("PUT", "leadClient/update", {
            AdditionalStatusId: leadClient.additionalStatusId,
            address: leadClient.address,
            addressCoords: leadClient.addressCoords,
            assigneeId: leadClient.assigneeId,
            id: leadClient.id,
            email: leadClient.email,
            followUp: leadClient.followUp,
            fullname: leadClient.fullName,
            leadStatusId: leadClient.leadStatusId,
            phone: leadClient.phone,
            processId: leadClient.processId,
            source: leadClient.source,
        });
    }

    public but_updateLeadClientDeal(dealId, leadClientId: number, subject: string, amount: number, assigneeId: number, dealDate: string): Observable<IResponse<any>> {
        return this.sendRequest2("PATCH", "leadClient/setDeals", { dealId, leadClientId, subject, amount, assigneeId, dealDate, externalCrmId: 0 })
    }

    public but_getDealById(id: number): Observable<IResponse<LeadDeal>> {
        return this.sendRequest2("GET", "leadClient/deal/getById?id=" + id, null);
    }

    public but_UpdateDeal(id: number, leadClientId: number, subject: string, amount: number, assigneeId: number): Observable<IResponse<number>> {
        return this.sendRequest2("PATCH", "leadClient/updateDeal", { id, leadClientId, subject, amount, assigneeId });
    }

    public but_LeadClientAddMeeting(id: number, leadClientId: number, meetingDate: string, meetingEndDate: string, subject: string, meetingAssigneeId: number,
        externalId: number, note: string, meetingStatus: LeadClientMeetingStatus) {
        return this.sendRequest2("PATCH", "leadClient/addMeeting", { id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus });
    }

    public but_LeadClientGetMeeting(id: number) {
        return this.sendRequest2("GET", "leadClient/getMeetingById?id=" + id, null);
    }

    public but_LeadClientCancelMeeting(id: number, locale: string) {
        return this.sendRequest2("GET", "leadClient/cancelMeetingById?id=" + id, null);
    }

    public but_LeadClientCancelDeal(id: number) {
        return this.sendRequest2("GET", "leadClient/cancelDealById?id=" + id, null);
    }

    public but_LeadClientUpdateMeeting(id: number, leadClientId: number, meetingDate: string, meetingEndDate: string, subject: string, meetingAssigneeId: number,
        externalId: number, note: string, meetingStatus: LeadClientMeetingStatus) {
        return this.sendRequest2("PATCH", "leadClient/updateMeeting", { id, leadClientId, meetingDate, meetingEndDate, subject, meetingAssigneeId, externalId, note, meetingStatus });
    }

    public but_getLeadClientNotes(id: number) {
        return this.sendRequest2("GET", "leadClient/getNotes?id=" + id, null);
    }

    public but_addLeadClientNote(leadClientId: number, note: string, assigneeId: number, gpsLongitude: string, gpsLatitude: string, createdDate: string) {
        return this.sendRequest2("POST", "leadClient/createNote", { leadClientId, note, assigneeId, gpsLongitude, gpsLatitude, createdDate });
    }

    public but_getLeadNotes(leadId: number) {
        return this.sendRequest2("GET", "lead/getNotes?leadId=" + leadId, null);
    }

    public but_addLeadNote(leadId: number, note: string, assigneeId: number, gpsLongitude: string, gpsLatitude: string, createdDate: string) {
        const leadNote = new LeadNote();
        leadNote.assigneeId = assigneeId;
        leadNote.leadId = leadId;
        leadNote.content = note;
        leadNote.createDate = createdDate;
        leadNote.gpsLatitude = gpsLatitude;
        leadNote.gpsLongitude = gpsLongitude;
        return this.sendRequest2("POST", "lead/createLeadNote", leadNote);
    }

    public but_setPositionToSubstatus(substatus: ISubstatus, isUp: boolean) {
        return this.sendRequest2("PATCH", "substatus/setPositionToSubstatus", { Id: substatus.id, isUp: isUp });
    }

    public but_getLeadsForLeadClient(id: number) {
        return this.sendRequest2("GET", "leadClient/leads?id=" + id, null);
    }

    public but_getLead(leadId: number) {
        return this.sendRequest2("GET", "lead?leadId=" + leadId, null);
    }

    public but_getDealsForLeadClient(id: number) {
        return this.sendRequest2("GET", "leadClient/deals?id=" + id, null);
    }

    public but_getMeetingsForLeadClient(id: number) {
        return this.sendRequest2("GET", "leadClient/meetings?id=" + id, null);
    }

    public but_getAdPreview(channelId: number, adGroupId: string, adId: string) {
        return this.sendRequest2("GET", `adpreview?channelId=${channelId}&adGroupId=${adGroupId}&adId=${adId}`, null);
    }


    //#region SignalR

    public getAllWhatsappMessagesLeadClient(leadClientId: number) {
        return this.sendRequest2("GET", "leadClient/getAllMessages?id=" + leadClientId, null);
    }

    public getMessageFile(url: string): Promise<Blob> {
        return this.convertFileResponseToBlob(this.sendRequest2("FILE", url, null));
    }

    public getTemplatesForLeadClient(leadClientId: number) {
        return this.sendRequest2("GET", "leadClient/templates?id=" + leadClientId, null);
    }

    public markMessagesAsRead(leadClientId: number) {
        return this.sendRequest2("POST", "leadClient/markMessagesAsRead", { leadClientId });
    }

    public sendReplyWhatsappMessage(leadClientId: number, message: string, userIdentityName: string): Observable<IResponse<any>> {
        return this.sendRequest2("PUT", "leadClient/sendReplyMessage",
            { "leadClientId": `${leadClientId}`, "message": `${message}`, "userIdentityName": `${userIdentityName}` });
    }

    public sendReplyWhatsappFile(leadClientId: number, userIdentityName: string, files?: File[]): Observable<IResponse<any>> {
        const formData = new FormData();
        formData.append('leadClientId', leadClientId.toString());
        formData.append('userIdentityName', userIdentityName);

        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i], files[i].name);
            }
        }

        return this.sendRequest2("POST", "leadClient/sendReplyFile", formData);
    }

    public sendTemplateWhatsappMessage(leadClientId: number, templateName: string, userIdentityName: string): Observable<IResponse<any>> {
        return this.sendRequest2("PUT", "leadClient/sendTemplateMessage",
            { "leadClientId": `${leadClientId}`, "templateName": `${templateName}`, "userIdentityName": `${userIdentityName}` });
    }

    public getLeadClient(phone: string) {
        return this.sendRequest2("GET", "leadClient/getByPhone?phone=" + phone, null);
    }

    public getLeadClientByEmail(email: string) {
        return this.sendRequest2("GET", "leadClient/getByEmail?email=" + email, null);
    }

    public updateLeadClientAddress(leadClientId: number, address: string, addressCoords: string): Observable<IResponse<any>> {
        return this.sendRequest2("PUT", "leadClient/updateAddress",
            { "leadClientId": `${leadClientId}`, "address": address, "addressCoords": addressCoords });
    }


    //#region WithCrmToken

    public getAllWhatsappMessagesLeadClientWithCrmToken(token: string, leadClientId: number) {
        return this.sendRequest2WithCrmToken(token, "GET", "leadClient/getAllMessages?id=" + leadClientId, null);
    }

    public getMessageFileWithCrmToken(token: string, url: string): Promise<Blob> {
        return this.convertFileResponseToBlob(this.sendRequest2WithCrmToken(token, "FILE", url, null));
    }

    public getLeadClientWithCrmToken(token: string, id: number): Observable<IResponse<GetLeadClientByCrmTokenResponse>> {
        return this.sendRequest2WithCrmToken(token, "GET", `leadClient/getByCrmToken?leadClientId=${id}`, null);
    }

    public getTemplatesForLeadClientWithCrmToken(token: string, leadClientId: number) {
        return this.sendRequest2WithCrmToken(token, "GET", "leadClient/templates?id=" + leadClientId, null);
    }

    public markMessagesAsReadWithCrmToken(token: string, leadClientId: number) {
        return this.sendRequest2WithCrmToken(token, "POST", "leadClient/markMessagesAsRead", { leadClientId });
    }

    public sendReplyWhatsappMessageWithCrmToken(token: string, leadClientId: number, message: string, userIdentityName: string): Observable<IResponse<any>> {
        return this.sendRequest2WithCrmToken(token, "PUT", "leadClient/sendReplyMessage",
            { "leadClientId": `${leadClientId}`, "message": `${message}`, "userIdentityName": `${userIdentityName}` });
    }

    public sendReplyWhatsappFileMessageWithCrmToken(token: string, leadClientId: number, message: string, userIdentityName: string, files: File[]): Observable<IResponse<any>> {
        let formData: FormData = new FormData();
        formData.append("leadClientId", leadClientId.toString());
        formData.append("userIdentityName", userIdentityName);

        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i], files[i].name);
        }

        return this.sendRequest2WithCrmToken(token, "POST", "leadClient/sendReplyFile", formData);
    }

    public sendTemplateWhatsappMessageWithCrmToken(token: string, leadClientId: number, templateName: string, userIdentityName: string): Observable<IResponse<any>> {
        return this.sendRequest2WithCrmToken(token, "PUT", "leadClient/sendTemplateMessage",
            { "leadClientId": `${leadClientId}`, "templateName": `${templateName}`, "userIdentityName": `${userIdentityName}` });
    }

    public but_getAdPreviewWithCrmToken(token: string, channelId: number, adGroupId: string, adId: string) {
        return this.sendRequest2WithCrmToken(token, "GET", `adpreview?channelId=${channelId}&adGroupId=${adGroupId}&adId=${adId}`, null);
    }

    //#endregion WithCrmToken

    private convertFileResponseToBlob(response: Observable<HttpResponse<ArrayBuffer>>): Promise<Blob> {
        return new Promise((resolve, reject) => {
            response.toPromise().then(response => {
                let contentType = response.headers.get("Content-Type");
                if (contentType.startsWith('application/json')) {
                    let decoder = new TextDecoder("utf-8");
                    let resp: IResponse<any> = JSON.parse(decoder.decode(new Uint8Array(response.body)));
                    reject(resp);
                } else {
                    let blob = new Blob([response.body], { type: contentType });
                    resolve(blob);
                }
            }).catch(reject);
        });
    }
}
