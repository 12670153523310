import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ILang } from 'src/app/model/lang.interface';
import { LangsRepository } from 'src/app/services/langs.repository';
import { UsersRepository } from 'src/app/services/users.repository';
import { User } from 'src/app/model/user.model';
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: 'users-replace-overlay',
    templateUrl: 'users.overlay.replace.html',
    styleUrls: ["../../app.component.scss", "users.overlay.replace.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class UserReplaceOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() userId: number = 0;

    public langsReady = false;
    public usersReady = false;

    constructor(
        private langsRepository: LangsRepository,
        private usersRepository: UsersRepository,
        private appService: AppService
    ) {
        this.ready = true;
        this.init();
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) this.langsReady = true; });

        this.usersRepository.prepare().then(() => { this.usersReady = true; });
    }

    get users(): User[] {
        var checkForCanDelete = this.usersRepository.users.filter(x => x.id !== this.userId).length == 0;
        if (checkForCanDelete) {
            return this.usersRepository.users.filter(x => x.id !== this.userId);
        }

        return this.usersRepository.users.filter(x => x.id !== this.userId);
    }

    public delete(user: User): void {
        if (confirm(this.currentLang.words["sure"])) {
            this.appService.showNetworkstatus("sending data...");
            var replacedUser = this.usersRepository.users.find(x => x.id == this.userId);
            replacedUser.replaceUserId = user.id;
            this.usersRepository.sendDeleteUser(replacedUser).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.usersRepository.load();
                    this.appService.leadClients.forEach((leadClient) => {
                        leadClient.assigneeId = replacedUser.replaceUserId;
                    });
                } else {
                    this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                }

                this.close();
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in users-replace-overlay delete sendDeleteUser: ${message}`, true);
            });

            this.usersRepository.delete(replacedUser);
        }
    }
}