import { Injectable } from "@angular/core";
import { DataService } from './DataServices/data.service';
import { BehaviorSubject } from 'rxjs';
import { LangsRepository } from './langs.repository';
import { ProcessesRepository } from './processes.repository';
import { AppService } from './app.service';

@Injectable()
export class ProcessesRepositoryBLT extends ProcessesRepository
{   
    public token: BehaviorSubject<string> = new BehaviorSubject ("");    

    constructor (
        protected dataService: DataService,
        protected langsRepository: LangsRepository,
        protected appService: AppService,
    )
    {        
        super (langsRepository);
        this.token.subscribe (token => {
            this.ready.next (false);

            if (token) {
                this.dataService.blt_getProcesses (token, this.locale).subscribe (res => {
                    if (res.responseCode === 200) {
                        this.processes = (res.data.length) ? res.data : [];
                        this.ready.next (true);                    
                    } else {
                        this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, err => {
                    const message = this.appService.getErrorMessage(err);
                    this.appService.showError("HTTP error", `${this.langsRepository.current.words["http_error"]} in ProcessesRepositoryBLT constructor blt_getProcesses: ${message}`);
                });
            }            
        });
    }    
}
