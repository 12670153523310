export class Model {
    public build (o: Object): any {
        for (let field in o)
        {
            this[field] = o[field];            
        }

        return this;
    }

    protected twoDigits (n: number): string {
        return (n < 10) ? `0${n}` : `${n}`;
    }
}
