import { LeadClient } from '../model/leadClients/lead.client.model';
import { DataService } from './DataServices/data.service';
import { LangsRepository } from './langs.repository';

export abstract class LeadClientsRepository {
    public leadCLients: LeadClient[] = [];

    constructor(
        protected dataService: DataService,
        protected langsRepository: LangsRepository
    ) { }

    get locale(): string { return this.langsRepository.current.locale; }

    public sortLeadClients(sorting: string, dir: number): void {
        let realSorting: string | null = null;
        // we must sort only if sorting setted to followUp or lastUpdate, in other cases date will not change and sorting is not needed!
        if (sorting === "followUp") {
            realSorting = "rawFollowup";
        }
        if (realSorting) {
            this.leadCLients = this.leadCLients.sort((a: LeadClient, b: LeadClient) => (dir)
                ? b[realSorting] - a[realSorting]
                : a[realSorting] - b[realSorting]);
        }
    }

    public reset(): void {
        this.leadCLients = [];
    }
}