import { Model } from './model';

export class LeadNote extends Model {
    constructor (
        public assigneeId?: number,
        public leadId?: number,
        public content?: string,
        public createDate?: string,
        public gpsLatitude?: string,
        public gpsLongitude?: string,
        public id?: number,
        // not from API
        public rawCreateDate?: Date,
    )
    {
        super ();        
    }    

    public build (o: Object): LeadNote
    {
        super.build (o);
        this.rawCreateDate = new Date (this.createDate);
        
        return this;
    }

    public init (): LeadNote
    {
        this.assigneeId = 0;
        this.leadId = 0;
        this.content = "";
        this.rawCreateDate = new Date ();
        this.createDate = (new Date ()).toISOString ();
        this.gpsLatitude = "";
        this.gpsLongitude = "";

        return this;
    }

    get formatCreateDate (): string 
    {        
        return `${this.rawCreateDate.getDate ()}/${this.twoDigits (this.rawCreateDate.getMonth () + 1)}/${this.rawCreateDate.getFullYear ()} ${this.rawCreateDate.getHours ()}:${this.twoDigits (this.rawCreateDate.getMinutes ())}`;
    }  
}
