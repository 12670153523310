/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tooltip.popup.component";
import * as i3 from "@ionic/angular";
var styles_TooltipPopupComponent = [i0.styles];
var RenderType_TooltipPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipPopupComponent, data: {} });
export { RenderType_TooltipPopupComponent as RenderType_TooltipPopupComponent };
export function View_TooltipPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], [[8, "lang", 0], [8, "className", 0], [8, "dir", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPopupClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "popup-win"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "popup-head grad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "popup-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "popup-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "popup-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "popup-foot"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "popup-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.slug; var currVal_1 = (_co.active ? "popup active" : "popup"); var currVal_2 = _co.currentLang.dir; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.title; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.text; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.currentLang.words["close"]; _ck(_v, 12, 0, currVal_5); }); }
export function View_TooltipPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tooltip-notification-popup", [], null, null, null, View_TooltipPopupComponent_0, RenderType_TooltipPopupComponent)), i1.ɵdid(1, 49152, null, 0, i2.TooltipPopupComponent, [i3.NavController], null, null)], null, null); }
var TooltipPopupComponentNgFactory = i1.ɵccf("tooltip-notification-popup", i2.TooltipPopupComponent, View_TooltipPopupComponent_Host_0, { active: "active", currentLang: "currentLang", text: "text", title: "title" }, { activeChange: "activeChange" }, []);
export { TooltipPopupComponentNgFactory as TooltipPopupComponentNgFactory };
