import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ILang } from '../../../model/lang.interface';

@Component({
    selector: 'error-popup',
    templateUrl: 'error.popup.component.html',
    styleUrls: ["./error.popup.component.scss"],
})
export class ErrorPopupComponent {
    @Input() active: boolean;
    @Input() currentLang: ILang;
    @Input() error: string;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }
}
