<div *ngIf="ready"
    [class]="active ? 'overlay clientOverlay clientSubstatusOverlayEdit '+currentLang.dir+' active' : 'overlay clientOverlay '+currentLang.dir">
    <div class="ol-main" style="width: 400px;">
        <div class="ol-head grad">
            <div class="ol-title">{{currentLang.words["edit-status"]}}</div>
            <div class="ol-close" (click)="close ();"><span></span></div>
        </div>
        <div class="ol-content" style="height: calc(100% - 44px);">
            <div class="full" *ngIf="langsReady && substatusesReady">
                <div id="submain">
                    <div class="content">
                        <substatus-edit [currentLang]="currentLang" [statuses]="statuses" [substatus]="substatus"></substatus-edit>
                        <div class="edit edit-button" style="margin-top: 20px">
                            <div class="edit-param">
                                <button class="big opaque" (click)="save();"><span class="ico-check-in-btn">{{currentLang.words["save"]}}</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ol-right" (click)="close ();"></div>
</div>
