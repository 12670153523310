import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ILang } from 'src/app/model/lang.interface';
import { User } from 'src/app/model/user.model';
import { AssigneesRepositoryBUT } from 'src/app/services/assignees.repository.but';
import { AuthService } from 'src/app/services/auth.service';
import { LangsRepository } from 'src/app/services/langs.repository';
import { UsersRepository } from 'src/app/services/users.repository';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'users-edit-overlay',
    templateUrl: 'users.overlay.edit.html',
    styleUrls: ["../../app.component.scss", "users.overlay.edit.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class UsersEditOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() user: User = null;

    public langsReady = false;
    public usersReady = false;

    constructor(
        private langsRepository: LangsRepository,
        private usersRepository: UsersRepository,
        private appService: AppService,
        private navCtrl: NavController,
        private authService: AuthService,
        private AssigneesRepositoryBUT: AssigneesRepositoryBUT
    ) {
        this.ready = true;
        this.init();
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) { this.langsReady = true; } });
    }

    public validate(): boolean {
        var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm);
        let error = false;

        if (!this.user.email.length) {
            this.user.emailError = this.currentLang.words['enter-email'];
            error = true;
        }

        if (!regexp.test(this.user.email)) {
            this.user.emailError = this.currentLang.words['correct-email'];
            error = true;
        }

        if (!this.user.phone || !this.user.phone.length) {
            this.user.phoneError = this.currentLang.words['enter-phone'];
            error = true;
        }
        else if (!this.user.phone.match(/^(050|051|052|053|054|055|057|058|02|03|04|08|09|071|072|073|074|076|077|078|079|170|180|19[1-9]|159)-?\d{7,7}$/)) {
            this.user.phoneError = this.currentLang.words["enter-phone-validation-start"];
            error = true;
        } else {
            this.user.phoneError = "";
        }

        if (!this.user.firstName || !this.user.firstName.trim().length) {
            this.user.firstNameError = this.currentLang.words['enter-firstName'];
            error = true;
        } else if (!/^[0-9a-zA-Z\u0590-\u05FF _-]+$/.test(this.user.firstName)) {
            this.user.firstNameError = this.currentLang.words['invalid-firstName'];
            error = true;
        } else {
            this.user.firstNameError = "";
        }

        if (!this.user.lastName || !this.user.lastName.trim().length) {
            this.user.lastNameError = this.currentLang.words['enter-lastName'];
            error = true;
        } else if (!/^[0-9a-zA-Z\u0590-\u05FF _-]+$/.test(this.user.lastName)) {
            this.user.lastNameError = this.currentLang.words['invalid-lastName'];
            error = true;
        } else {
            this.user.lastNameError = "";
        }

        return !error;
    }

    public save(): void {
        if (this.validate()) {
            this.appService.showNetworkstatus("sending data...");
            this.usersRepository.sendUpdateUser(this.user).subscribe(res => {
                if (res.body.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.AssigneesRepositoryBUT.cacheKey = res.body.settingsCacheToken;
                    this.close();
                    this.usersRepository.load();
                    if (this.user.id === this.authService.user.id) {
                        let token: string | null = res.headers.get("X-CLIENT-TOKEN");

                        if (token) {
                            this.authService.updateUser(this.user, token);
                        } else {
                            this.authService.logout();
                            this.navCtrl.navigateForward("/auth/login");
                        }
                    }
                }
                else if (res.body.responseCode == 400) {
                    var erros = res.body.error.split('\r\n');
                    erros.forEach((err) => {
                        if (err.includes('Email')) this.user.emailError = err.replace('Email:', '');
                        if (err.includes('PhoneNumber')) this.user.phoneError = err.replace('PhoneNumber:', '');
                        if (err.includes('LastName:')) this.user.lastNameError = err.replace('LastName:', '');
                        if (err.includes('FirstName:')) this.user.firstNameError = err.replace('FirstName:', '');
                    })
                }
                else {
                    this.appService.showError("API error", res.body.error, true, res.body.responseCode);
                }


            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in users-edit-overlay save sendUpdateUser: ${message}`, true);
            });
        }
    }






}
