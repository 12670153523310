import { Component, ViewEncapsulation } from '@angular/core';
import { LangsRepository } from '../services/langs.repository';
import { AppService } from '../services/app.service';
import { ILang } from '../model/lang.interface';
import { UsersRepository } from '../services/users.repository';
import { User } from '../model/user.model';
import { AssigneesRepositoryBUT } from '../services/assignees.repository.but';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'users-page',
    templateUrl: 'users.page.html',
    styleUrls: ['users.page.scss', "../app.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class UsersPage {
    public langsReady = false;
    public usersReady = false;

    public userNewActive: boolean = false;
    public currentUserToEdit: User = null;
    public userEditActive: boolean = false;
    public userReplaceActive: boolean = false;
    public currentReplaceUserId: number = 0;

    constructor(
        private langsRepository: LangsRepository,
        private usersRepository: UsersRepository,
        private appService: AppService,
        private authService: AuthService,
        private assigneesRepositoryBUT: AssigneesRepositoryBUT
    ) {
        this.init();
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) { this.langsReady = true; } });
        this.usersRepository.prepare().then(() => { this.usersReady = true; });
    }

    get currentLang(): ILang { return this.langsRepository.current; }
    get users(): User[] { return this.usersRepository.users; }
    get user(): User { return this.authService.user; }
    set menuClass(v: string) { this.appService.menuClass = v; }

    public delete(user: User): void {
        if (confirm(this.currentLang.words["sure"])) {
            this.appService.showNetworkstatus("sending data...");
            this.usersRepository.sendDeleteUser(user).subscribe(res => {
                if (!res.data) {
                    this.appService.showError("API error", this.currentLang.words['delete-admin'], true, res.responseCode);
                } else if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.usersRepository.delete(user);
                    this.assigneesRepositoryBUT.cacheKey = res.settingsCacheToken;
                } else {
                    this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                }
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in users-page delete sendDeleteUser: ${message}`, true);
            });
        }
    }

    public goToEdit(user: User) {
        this.currentUserToEdit = user;
        this.userEditActive = true;
    }
}
