import { Component } from '@angular/core';
import { LangsRepository } from '../../services/langs.repository';
import { ILang } from '../../model/lang.interface';

@Component({
	selector: 'privacy-policy',
	templateUrl: 'privacy-policy.page.html',
	styleUrls: ['privacy-policy.page.scss', "../../app.component.scss"]
})

export class PrivacyPolicyPage {
	public langsReady: boolean = false;

	constructor(
		private langsRepository: LangsRepository
	) {
		this.langsRepository.ready.subscribe(r => {
			if (r) {
				this.langsReady = true;
			}
		});
	}

	get currentLang(): ILang { return this.langsRepository.current; }

	public back(): void { window.history.back(); }
}
