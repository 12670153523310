import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ILang } from '../../model/lang.interface';
import { LangsRepository } from '../../services/langs.repository';
import { LogService } from 'src/app/services/log.service';

@Component({
	selector: 'test-page',
	templateUrl: 'test.page.html',
	styleUrls: ['test.page.scss', "../../app.component.scss"]
})
export class TestPage {
	private TOKEN_VALUE = "uco12345";
	public errorText = "";
	public langsReady = false;
	public token = "";

	constructor(
		private readonly langsRepository: LangsRepository,
		private readonly location: Location,
		private readonly logService: LogService,
	) {
		this.langsRepository.ready.subscribe(r => {
			if (r) this.langsReady = true;
		});
	}

	get currentLang(): ILang { return this.langsRepository.current; }

	get logs(): LogItem[] {
		return this.logService.logs;
	}

	public back(): void { this.location.back(); }

	public clearLogs() {
		this.logService.clear();
	}

	public sendTestError() {
		if (!this.token || this.token !== this.TOKEN_VALUE) {
			alert("Enter token");
			return;
		}
		if (!this.errorText) {
			alert("Enter error text");
			return;
		}
		throw new Error(this.errorText);
	}
}
