import { Model } from './../model';

export class LeadClientNote extends Model {
    constructor(
        public assigneeId?: number,
        public note?: string,
        public createdDate?: string,
        public gpsLatitude?: string,
        public gpsLongitude?: string,
        public id?: number,
        // not from API
        public rawCreateDate?: Date,
    ) {
        super();
    }

    public build(o: Object): LeadClientNote {
        super.build(o);
        this.rawCreateDate = new Date(this.createdDate);

        return this;
    }

    public init(): LeadClientNote {
        this.assigneeId = 0;
        this.note = "";
        this.rawCreateDate = new Date();
        this.createdDate = (new Date()).toISOString();
        this.gpsLatitude = "";
        this.gpsLongitude = "";

        return this;
    }

    get formatCreateDate(): string {
        return `${this.rawCreateDate.getDate()}/${this.twoDigits(this.rawCreateDate.getMonth() + 1)}/${this.rawCreateDate.getFullYear()} ${this.rawCreateDate.getHours()}:${this.twoDigits(this.rawCreateDate.getMinutes())}`;
    }
}
