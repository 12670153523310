<div *ngIf="currentLang && leadClient" class="lead">
    <div class="lead-sizer" [innerHTML]="leadClient.preview"></div>
    <div [ngClass]="['lead-fg']" 
        [ngStyle]="{'transform': 'translateX('+pos+'px)', 'transition-duration': transitionDuration, 'background-color': leadClient.current ? '#ebebeb' : '#fff'}"
        (mousedown)="onMouseDown($event);" (mouseup)="onMouseUp($event);" (mousemove)="onMouseMove($event);"
        (mouseleave)="onMouseLeave($event);" (touchstart)="onTouchStart($event);" (touchmove)="onTouchMove($event);"
        (touchend)="onTouchEnd($event);">
        <div class="lead-content" [innerHTML]="leadClient.preview"></div>
        <a class="lead-messages" [routerLink]="['/leadClients', leadClient.id, 'whatsapp']"
                *ngIf="leadClient.lastMessageStatus === MessageStatus.Delivered">
            <ion-icon name="chatboxes"
                class="lead-icon left-icon fix-scroll {{currentLang.dir}}">
            </ion-icon>
        </a>
        <a class="lead-messages" style="text-decoration: none;" *ngIf="leadClient.countOfWhatsAppMessages > 0" (click)="openWhatsAppPage()">
            <span style="font-size: 18px; display:inline-block;margin:5px;margin-bottom: 10px;">{{leadClient.countOfWhatsAppMessages}}</span>
            <ion-icon name="chatboxes" class="lead-icon left-icon fix-scroll {{currentLang.dir}}"></ion-icon>
        </a>

        <a class="lead-messages" *ngIf="leadClient.leadStatusId == 1 || leadClient.leadStatusId == 15">
            <ion-icon name="contact" class="lead-icon left-icon fix-scroll {{currentLang.dir}}">
            </ion-icon>
        </a>

        <div class="lead-ctrl {{currentLang.dir}}">
            <span></span>
        </div>
        <div class="lead-status {{statusClass}}" [dir]="currentLang.dir"></div>
    </div>
    <div class="lead-bg">
        <div class="lead-bg-content">
            <div class="lead-statuses" (click)="onShowStatuses();"><span></span></div>
            <div class="lead-calendar" (click)="onShowCalendar();"><span></span></div>
        </div>
    </div>
</div>

