<div class="full" *ngIf="langsReady && usersReady">
	<div id="submain">
		<div class="content users">
			<div class="user" *ngFor="let u of users">
				<div class="u-title-and-role">
					<div class="u-title" (click)="goToEdit(u)">{{u.firstName}} {{u.lastName}}</div>
					<div class="u-role">
						<span *ngIf="u.canViewAllLeads && u.canEditAllLeads">{{currentLang.words["see-and-edit-all-leads"]}}</span>
						<span *ngIf="u.canViewAllLeads && !u.canEditAllLeads">{{currentLang.words["see-all-leads-edit-him"]}}</span>
						<span *ngIf="!u.canViewAllLeads && !u.canEditAllLeads">{{currentLang.words["see-and-edit-him"]}}</span>
						<span *ngIf="u.isAdmin">{{currentLang.words["admin-user"]}}</span>
					</div>
				</div>
				<div class="u-edit" *ngIf="user" (click)="goToEdit(u)"><span></span></div>
				<div class="u-delete" *ngIf="user && !u.isAdmin" (click)="userReplaceActive = true; currentReplaceUserId = u.id;"><span></span></div>
			</div>
			<a class="add-new-user" (click)="userNewActive = true;" *ngIf="user"><span>{{currentLang.words["add-new-user"]}}</span></a>
		</div>
	</div>

	<users-new-overlay [currentLang]="currentLang" [active]="userNewActive"
		(activeChange)="userNewActive = $event;">
	</users-new-overlay>

	<users-edit-overlay [currentLang]="currentLang" [active]="userEditActive"
		(activeChange)="userEditActive = $event;" [user]="currentUserToEdit">
	</users-edit-overlay>

	<users-replace-overlay [currentLang]="currentLang" [active]="userReplaceActive"
		[userId]="currentReplaceUserId" (activeChange)="userReplaceActive = $event">
	</users-replace-overlay>
</div>