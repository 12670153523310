/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.shim.ngstyle";
import * as i1 from "./lead.client.preview.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i7 from "./lead.client.preview";
import * as i8 from "../../services/app.service";
var styles_LeadCLientPreviewComponent = [i0.styles, i1.styles];
var RenderType_LeadCLientPreviewComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_LeadCLientPreviewComponent, data: {} });
export { RenderType_LeadCLientPreviewComponent as RenderType_LeadCLientPreviewComponent };
function View_LeadCLientPreviewComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 5, "a", [["class", "lead-messages"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i2.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i2.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i2.ɵdid(1, 737280, null, 0, i3.RouterLinkDelegate, [i4.LocationStrategy, i3.NavController, i2.ElementRef, i5.Router, [2, i5.RouterLink]], null, null), i2.ɵdid(2, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i2.ɵpad(3, 3), (_l()(), i2.ɵeld(4, 0, null, null, 1, "ion-icon", [["name", "chatboxes"]], [[8, "className", 0]], null, null, i6.View_IonIcon_0, i6.RenderType_IonIcon)), i2.ɵdid(5, 49152, null, 0, i3.IonIcon, [i2.ChangeDetectorRef, i2.ElementRef, i2.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _ck(_v, 3, 0, "/leadClients", _co.leadClient.id, "whatsapp"); _ck(_v, 2, 0, currVal_2); var currVal_4 = "chatboxes"; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵnov(_v, 2).target; var currVal_1 = i2.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i2.ɵinlineInterpolate(1, "lead-icon left-icon fix-scroll ", _co.currentLang.dir, ""); _ck(_v, 4, 0, currVal_3); }); }
function View_LeadCLientPreviewComponent_3(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 4, "a", [["class", "lead-messages"], ["style", "text-decoration: none;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openWhatsAppPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "span", [["style", "font-size: 18px; display:inline-block;margin:5px;margin-bottom: 10px;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", ""])), (_l()(), i2.ɵeld(3, 0, null, null, 1, "ion-icon", [["name", "chatboxes"]], [[8, "className", 0]], null, null, i6.View_IonIcon_0, i6.RenderType_IonIcon)), i2.ɵdid(4, 49152, null, 0, i3.IonIcon, [i2.ChangeDetectorRef, i2.ElementRef, i2.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_2 = "chatboxes"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.leadClient.countOfWhatsAppMessages; _ck(_v, 2, 0, currVal_0); var currVal_1 = i2.ɵinlineInterpolate(1, "lead-icon left-icon fix-scroll ", _co.currentLang.dir, ""); _ck(_v, 3, 0, currVal_1); }); }
function View_LeadCLientPreviewComponent_4(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "a", [["class", "lead-messages"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "ion-icon", [["name", "contact"]], [[8, "className", 0]], null, null, i6.View_IonIcon_0, i6.RenderType_IonIcon)), i2.ɵdid(2, 49152, null, 0, i3.IonIcon, [i2.ChangeDetectorRef, i2.ElementRef, i2.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_1 = "contact"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵinlineInterpolate(1, "lead-icon left-icon fix-scroll ", _co.currentLang.dir, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_LeadCLientPreviewComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 24, "div", [["class", "lead"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 0, "div", [["class", "lead-sizer"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 16, "div", [], null, [[null, "mousedown"], [null, "mouseup"], [null, "mousemove"], [null, "mouseleave"], [null, "touchstart"], [null, "touchmove"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onMouseDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseup" === en)) {
        var pd_1 = (_co.onMouseUp($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousemove" === en)) {
        var pd_2 = (_co.onMouseMove($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (_co.onMouseLeave($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchstart" === en)) {
        var pd_4 = (_co.onTouchStart($event) !== false);
        ad = (pd_4 && ad);
    } if (("touchmove" === en)) {
        var pd_5 = (_co.onTouchMove($event) !== false);
        ad = (pd_5 && ad);
    } if (("touchend" === en)) {
        var pd_6 = (_co.onTouchEnd($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i2.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i2.IterableDiffers, i2.KeyValueDiffers, i2.ElementRef, i2.Renderer2]), i2.ɵdid(4, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i2.ɵpad(5, 1), i2.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i2.ElementRef, i2.KeyValueDiffers, i2.Renderer2]), i2.ɵdid(7, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i2.ɵpod(8, { "transform": 0, "transition-duration": 1, "background-color": 2 }), (_l()(), i2.ɵeld(9, 0, null, null, 0, "div", [["class", "lead-content"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_LeadCLientPreviewComponent_2)), i2.ɵdid(11, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_LeadCLientPreviewComponent_3)), i2.ɵdid(13, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_LeadCLientPreviewComponent_4)), i2.ɵdid(15, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(16, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(17, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(18, 0, null, null, 0, "div", [], [[8, "className", 0], [8, "dir", 0]], null, null, null, null)), (_l()(), i2.ɵeld(19, 0, null, null, 5, "div", [["class", "lead-bg"]], null, null, null, null, null)), (_l()(), i2.ɵeld(20, 0, null, null, 4, "div", [["class", "lead-bg-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(21, 0, null, null, 1, "div", [["class", "lead-statuses"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowStatuses() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(22, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(23, 0, null, null, 1, "div", [["class", "lead-calendar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowCalendar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(24, 0, null, null, 0, "span", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 5, 0, "lead-fg"); _ck(_v, 4, 0, currVal_1); var currVal_2 = _ck(_v, 8, 0, (("translateX(" + _co.pos) + "px)"), _co.transitionDuration, (_co.leadClient.current ? "#ebebeb" : "#fff")); _ck(_v, 7, 0, currVal_2); var currVal_4 = (_co.leadClient.lastMessageStatus === _co.MessageStatus.Delivered); _ck(_v, 11, 0, currVal_4); var currVal_5 = (_co.leadClient.countOfWhatsAppMessages > 0); _ck(_v, 13, 0, currVal_5); var currVal_6 = ((_co.leadClient.leadStatusId == 1) || (_co.leadClient.leadStatusId == 15)); _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.leadClient.preview; _ck(_v, 1, 0, currVal_0); var currVal_3 = _co.leadClient.preview; _ck(_v, 9, 0, currVal_3); var currVal_7 = i2.ɵinlineInterpolate(1, "lead-ctrl ", _co.currentLang.dir, ""); _ck(_v, 16, 0, currVal_7); var currVal_8 = i2.ɵinlineInterpolate(1, "lead-status ", _co.statusClass, ""); var currVal_9 = _co.currentLang.dir; _ck(_v, 18, 0, currVal_8, currVal_9); }); }
export function View_LeadCLientPreviewComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_LeadCLientPreviewComponent_1)), i2.ɵdid(1, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentLang && _co.leadClient); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LeadCLientPreviewComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "lead-client-preview", [], null, null, null, View_LeadCLientPreviewComponent_0, RenderType_LeadCLientPreviewComponent)), i2.ɵdid(1, 49152, null, 0, i7.LeadCLientPreviewComponent, [i3.NavController, i5.Router, i8.AppService], null, null)], null, null); }
var LeadCLientPreviewComponentNgFactory = i2.ɵccf("lead-client-preview", i7.LeadCLientPreviewComponent, View_LeadCLientPreviewComponent_Host_0, { leadClient: "leadClient", currentLang: "currentLang", statusClass: "statusClass", user: "user" }, { showCalendar: "showCalendar", showStatuses: "showStatuses" }, []);
export { LeadCLientPreviewComponentNgFactory as LeadCLientPreviewComponentNgFactory };
