/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.ngstyle";
import * as i1 from "./users.overlay.edit.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../iface.components/user/user.edit.component.ngfactory";
import * as i4 from "../../iface.components/user/user.edit.component";
import * as i5 from "@angular/common";
import * as i6 from "./users.overlay.edit";
import * as i7 from "../../services/langs.repository";
import * as i8 from "../../services/users.repository";
import * as i9 from "../../services/app.service";
import * as i10 from "@ionic/angular";
import * as i11 from "../../services/auth.service";
import * as i12 from "../../services/assignees.repository.but";
var styles_UsersEditOverlayComponent = [i0.styles, i1.styles];
var RenderType_UsersEditOverlayComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_UsersEditOverlayComponent, data: {} });
export { RenderType_UsersEditOverlayComponent as RenderType_UsersEditOverlayComponent };
function View_UsersEditOverlayComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 8, "div", [["class", "full"]], null, null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 7, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 6, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "user-edit", [], null, null, null, i3.View_UserEditComponent_0, i3.RenderType_UserEditComponent)), i2.ɵdid(4, 49152, null, 0, i4.UserEditComponent, [], { user: [0, "user"], currentLang: [1, "currentLang"] }, null), (_l()(), i2.ɵeld(5, 0, null, null, 3, "div", [["class", "edit edit-button"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 2, "div", [["class", "edit-param"]], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 1, "button", [["class", "big opaque"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = _co.currentLang; _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.currentLang.words["save"]; _ck(_v, 8, 0, currVal_2); }); }
function View_UsersEditOverlayComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 13, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 11, "div", [["class", "ol-main"], ["style", "width: 400px;"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 7, "div", [["class", "ol-head grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 2, "div", [["class", "ol-close"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "a", [["class", "save"], ["style", "color: #fff"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(5, null, ["", ""])), (_l()(), i2.ɵeld(6, 0, null, null, 1, "div", [["class", "ol-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(7, null, ["", ""])), (_l()(), i2.ɵeld(8, 0, null, null, 1, "div", [["class", "ol-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 2, "div", [["class", "ol-content"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_UsersEditOverlayComponent_2)), i2.ɵdid(12, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(13, 0, null, null, 0, "div", [["class", "ol-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.user; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? (("overlay clientOverlay clientUserEditOverlay " + _co.currentLang.dir) + " active") : ("overlay clientOverlay " + _co.currentLang.dir)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.currentLang.words["save"]; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.currentLang.words["edit-user"]; _ck(_v, 7, 0, currVal_2); }); }
export function View_UsersEditOverlayComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_UsersEditOverlayComponent_1)), i2.ɵdid(1, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UsersEditOverlayComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "users-edit-overlay", [], null, null, null, View_UsersEditOverlayComponent_0, RenderType_UsersEditOverlayComponent)), i2.ɵdid(1, 49152, null, 0, i6.UsersEditOverlayComponent, [i7.LangsRepository, i8.UsersRepository, i9.AppService, i10.NavController, i11.AuthService, i12.AssigneesRepositoryBUT], null, null)], null, null); }
var UsersEditOverlayComponentNgFactory = i2.ɵccf("users-edit-overlay", i6.UsersEditOverlayComponent, View_UsersEditOverlayComponent_Host_0, { active: "active", currentLang: "currentLang", user: "user" }, { activeChange: "activeChange", cancel: "cancel" }, []);
export { UsersEditOverlayComponentNgFactory as UsersEditOverlayComponentNgFactory };
