import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { IResponse } from "src/app/model/response.interface";
import { ISubstatus } from "src/app/model/substatus.interface";
import { DataService } from "../DataServices/data.service";

@Injectable()
export class SubstatusesRepositoryBPT {
    public substatuses: ISubstatus[] = [];
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public lastReload: number = 0;
    public cacheKey: string = "";

    constructor(private dataService: DataService) { }

    public load(): void {
        this.dataService.token.subscribe(token => {
            this.ready.next(false);
            this.substatuses = [];
            if (token) {
                this.dataService.but_getSubstatuses().subscribe(res => {
                    if (res.responseCode === 200) {
                        this.substatuses = (res.data.length) ? res.data : [];
                        this.sort();
                        this.lastReload = (new Date()).getTime();
                        this.setCacheKeyIfNotExistToLocalStorage(res.settingsCacheToken);
                        this.ready.next(true);
                    }
                }, err => { });
            } else {
                this.ready.next(false);
                this.substatuses = [];
            }
        });
    }

    public prepare(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.load();
            this.ready.subscribe(r => {
                if (r) {
                    resolve();
                }
            });
        });
    }

    public sendAddSubstatus(substatus: ISubstatus): Observable<IResponse<ISubstatus>> {
        return this.dataService.but_addSubstatus(substatus);
    }

    public delete(substatus: ISubstatus): void {
        let index: number = this.substatuses.findIndex(ss => ss.id === substatus.id);

        if (index !== -1) {
            this.substatuses.splice(index, 1);
        }
    }

    public sort(): void {
        this.substatuses.sort((a: ISubstatus, b: ISubstatus) => a.position - b.position ? 1 : -1);
    }

    public setCacheKeyIfNotExistToLocalStorage(key: string) {
        var storeKey = localStorage.getItem('cacheKey');
        if (!storeKey) localStorage.setItem('cacheKey', key);
    }

    public getCurrentCacheKeyFromLocalStorage() {
        return localStorage.getItem('cacheKey');
    }

    public updateCacheKeyInLocalStorage(key: string) {
        localStorage.setItem('cacheKey', key);
    }
}