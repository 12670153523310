/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.ngstyle";
import * as i1 from "./processes.overlay.page.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../processes.page.ngfactory";
import * as i4 from "../processes.page";
import * as i5 from "../../services/langs.repository";
import * as i6 from "../../services/processes.repository.but";
import * as i7 from "../../services/auth.service";
import * as i8 from "../../services/app.service";
import * as i9 from "@angular/common";
import * as i10 from "./processes.overlay.page";
var styles_ProcessesViewOverlayComponent = [i0.styles, i1.styles];
var RenderType_ProcessesViewOverlayComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_ProcessesViewOverlayComponent, data: {} });
export { RenderType_ProcessesViewOverlayComponent as RenderType_ProcessesViewOverlayComponent };
function View_ProcessesViewOverlayComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 10, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 8, "div", [["class", "ol-main"], ["style", "width: 400px;"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "div", [["class", "ol-head grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "ol-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "ol-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 2, "div", [["class", "ol-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 1, "processes-page", [], null, null, null, i3.View_ProcessesPage_0, i3.RenderType_ProcessesPage)), i2.ɵdid(9, 49152, null, 0, i4.ProcessesPage, [i5.LangsRepository, i6.ProcessesRepositoryBUT, i7.AuthService, i8.AppService, i9.Location], null, null), (_l()(), i2.ɵeld(10, 0, null, null, 0, "div", [["class", "ol-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? (("overlay clientSubstatusOverlay clientOverlay " + _co.currentLang.dir) + " active") : ("overlay clientOverlay " + _co.currentLang.dir)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.currentLang.words["menu-processes"]; _ck(_v, 4, 0, currVal_1); }); }
export function View_ProcessesViewOverlayComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_ProcessesViewOverlayComponent_1)), i2.ɵdid(1, 16384, null, 0, i9.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProcessesViewOverlayComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "processes-view-overlay", [], null, null, null, View_ProcessesViewOverlayComponent_0, RenderType_ProcessesViewOverlayComponent)), i2.ɵdid(1, 49152, null, 0, i10.ProcessesViewOverlayComponent, [i8.AppService], null, null)], null, null); }
var ProcessesViewOverlayComponentNgFactory = i2.ɵccf("processes-view-overlay", i10.ProcessesViewOverlayComponent, View_ProcessesViewOverlayComponent_Host_0, { active: "active", currentLang: "currentLang" }, { activeChange: "activeChange", cancel: "cancel" }, []);
export { ProcessesViewOverlayComponentNgFactory as ProcessesViewOverlayComponentNgFactory };
