import { Component, Input } from '@angular/core';
import { ILang } from '../../model/lang.interface';
import { User } from '../../model/user.model';

@Component({
	selector: 'user-edit',
	templateUrl: 'user.edit.component.html',
	styleUrls: ["../../app.component.scss", "./user.edit.component.scss"],
})
export class UserEditComponent {
	@Input() user: User;
	@Input() currentLang: ILang;

	public phoneError = "";
	public emailError = "";

	public validatePhoneOnlyNumbers(): void {
		if (this.user.phone) {
			this.user.phone = this.user.phone.replace(/[^0-9]+/g, "");
		}
	}

	public ValidatePhone() {
		if (!this.user.phone.match(/^(050|051|052|053|054|055|057|058|02|03|04|08|09|071|072|073|074|076|077|078|079|170|180|19[1-9]|159)-?\d{7,7}$/)) {
			this.phoneError = this.currentLang.words["enter-phone-validation-start"];
		} else {
			this.phoneError = "";
		}
	}

	public validateEmail(): void {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!re.test(String(this.user.email).toLowerCase())) {
			this.user.emailError = this.currentLang.words["correct-email"];
		} else {
			this.user.emailError = "";
		}
	}

	public validateFirstName(): void {
		if (this.user.firstName && this.user.firstName.trim().length > 0) {
			this.user.firstNameError = "";
		} else {
			this.user.firstNameError = this.currentLang.words['enter-firstName'];
		}
	}

	public validateLastName(): void {
		if (this.user.lastName && this.user.lastName.trim().length > 0) {
			this.user.lastNameError = "";
		} else {
			this.user.lastNameError = this.currentLang.words['enter-lastName'];
		}
	}

	public clearInput(field: string) {
		switch (field) {
			case 'firstName': this.user.firstName = ''; break;
			case 'lastName': this.user.lastName = ''; break;
			case 'email': this.user.email = ''; break;
			case 'phone': this.user.phone = ''; break;
			default: break;
		}
	}
}
