/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../../app.component.scss.shim.ngstyle";
import * as i1 from "./download.popup.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "./download.popup.component";
var styles_DownloadPopupComponent = [i0.styles, i1.styles];
var RenderType_DownloadPopupComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_DownloadPopupComponent, data: {} });
export { RenderType_DownloadPopupComponent as RenderType_DownloadPopupComponent };
export function View_DownloadPopupComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 16, "div", [], [[8, "lang", 0], [8, "className", 0], [8, "dir", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPopupClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 15, "div", [["class", "popup-win"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "div", [["class", "popup-head grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "popup-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "popup-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 6, "div", [["class", "popup-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 2, "div", [["class", "popup-flexblock center"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 0, "img", [["alt", "google play"], ["src", "../assets/img/google-play.png"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 2, "div", [["class", "popup-flexblock center"]], null, null, null, null, null)), (_l()(), i2.ɵeld(12, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i2.ɵeld(13, 0, null, null, 0, "img", [["alt", "google play"], ["src", "../assets/img/app-store.png"]], null, null, null, null, null)), (_l()(), i2.ɵeld(14, 0, null, null, 2, "div", [["class", "popup-foot"]], null, null, null, null, null)), (_l()(), i2.ɵeld(15, 0, null, null, 1, "button", [["class", "popup-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(16, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentLang.slug; var currVal_1 = (_co.active ? "popup active" : "popup"); var currVal_2 = _co.currentLang.dir; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.currentLang.words["download-app"]; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.currentLang.words["google-play-link"]; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.currentLang.words["app-store-link"]; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.currentLang.words["close"]; _ck(_v, 16, 0, currVal_6); }); }
export function View_DownloadPopupComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "download-popup", [], null, null, null, View_DownloadPopupComponent_0, RenderType_DownloadPopupComponent)), i2.ɵdid(1, 49152, null, 0, i3.DownloadPopupComponent, [], null, null)], null, null); }
var DownloadPopupComponentNgFactory = i2.ɵccf("download-popup", i3.DownloadPopupComponent, View_DownloadPopupComponent_Host_0, { active: "active", currentLang: "currentLang" }, { activeChange: "activeChange" }, []);
export { DownloadPopupComponentNgFactory as DownloadPopupComponentNgFactory };
