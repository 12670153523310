import { Injectable } from "@angular/core";
import { DataService } from './DataServices/data.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IResponse } from '../model/response.interface';
import { User } from '../model/user.model';
import { LangsRepository } from './langs.repository';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class UsersRepository {
    public loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public lastReload: number = 0;
    public users: User[] = [];

    constructor(
        private dataService: DataService,
        protected langsRepository: LangsRepository,
    ) { }

    get locale(): string { return this.langsRepository.current.locale; }

    public load(): void {
        this.dataService.token.subscribe(token => {
            this.loading.next(true);
            this.ready.next(false);
            this.users = [];
            if (token) {
                this.dataService.but_getUsers().subscribe(res => {
                    if (res.responseCode === 200) {
                        this.users = (res.data.length) ? res.data.map(d => (new User()).build(d)) : [];
                        this.lastReload = (new Date()).getTime();
                        this.loading.next(false);
                        this.ready.next(true);
                    } else {
                        this.loading.next(false);
                        this.ready.next(true);
                        this.users = [];
                    }
                }, err => { });
            } else {
                this.loading.next(false);
                this.ready.next(false);
                this.users = [];
            }
        });
    }

    public prepare(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.ready.value || this.loading.value) {
                resolve();
            } else {
                this.load();
                this.ready.subscribe(r => {
                    if (r) {
                        resolve();
                    }
                });
            }
        });
    }

    public getUserById(id: number): User | null { return this.users.find(u => u.id === id) || null; }
    public sendAddUser(user: User): Observable<IResponse<User>> { return this.dataService.but_addUser(user, this.locale); }
    public sendUpdateUser(user: User): Observable<HttpResponse<IResponse<User>>> { return this.dataService.but_updateUser(user); }
    public sendUpdateUserSettings(user: User): Observable<HttpResponse<IResponse<User>>> {return this.dataService.but_updateUserSettings(user); }
    public sendDeleteUser(user: User): Observable<IResponse<any>> { return this.dataService.but_deleteUser(user, this.locale); }

    public delete(user: User): void {
        let index: number = this.users.findIndex(u => u.id === user.id);

        if (index !== -1) {
            this.users.splice(index, 1);
        }
    }
}
