<div id="wrap" *ngIf="langsReady">
	<header class="grad">
		<div id="logo"></div>
		<div id="h1">Test page</div>
		<div id="h-left">
			<a class="back" (click)="back ();">{{currentLang.words["back"]}}</a>
		</div>
	</header>
	<div id="main" [class]='currentLang.dir === "rtl" ? "rtl" : "ltr"'>
		<div id="submain">
			<div class="content">
				<div class="test-page-content">
					<div class="test-block">
						<div class="caption">Logs</div>
						<div class="logs">
							<div class="log"  *ngFor="let logItem of logs">
								<div class="log-text">{{ logItem.text }}</div>
								<div class="log-date">{{ logItem.date | date:"dd/MM/yyyy HH:mm:ss" }}</div>
							</div>
						</div>
						<button (click)="clearLogs()">Clear</button>
					</div>
					<div class="test-block token-block">
						<div class="caption">Token</div>
						<input type="password" [(ngModel)]="token" placeholder="token" />
					</div>
					<div class="test-block">
						<div class="caption">Send test error (for Sentry testing)</div>
						<input [(ngModel)]="errorText" placeholder="error text" />
						<button (click)="sendTestError()">Send</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
