import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { StatusType } from 'src/app/Enums/status.type.enum';
import { ILang } from 'src/app/model/lang.interface';
import { ISubstatus } from 'src/app/model/substatus.interface';
import { LangsRepository } from 'src/app/services/langs.repository';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';

@Component({
    selector: 'substatus-replace-overlay',
    templateUrl: 'substatus.overlay.replace.html',
    styleUrls: ["../../app.component.scss", "substatus.overlay.replace.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class SubStatusReplaceOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() substatusId: number = 0;
    @Input() globalStatus: number = 0;

    public langsReady = false;
    public substatusesReady = false;
    public processesReady = false;

    constructor(
        private langsRepository: LangsRepository,
        private substatusesRepository: SubstatusesRepository,
        private processesRepository: ProcessesRepositoryBUT,
        private appService: AppService,
        private loadingCtrl: LoadingController,
    ) {
        this.ready = true;
        this.init();
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) this.langsReady = true; });
    }

    get substatuses(): ISubstatus[] {
        var checkForCanDelete = this.substatusesRepository.substatuses.filter(x => x.id !== this.substatusId).length == 0;
        if (checkForCanDelete) {
            return this.substatusesRepository.substatuses.filter(x => x.id !== this.substatusId);
        }

        return this.substatusesRepository.substatuses.filter(x => x.id !== this.substatusId);
    }

    get subStatusForInProcess(): ISubstatus[] { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.InProcess && x.id !== this.substatusId); }
    get subStatusForMeetingProcess(): ISubstatus[] { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.Meeting && x.id !== this.substatusId); }
    get subStatusForDealProcess(): ISubstatus[] { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.Closed && x.id !== this.substatusId); }
    get subStatusForNotGoodProcess(): ISubstatus[] { return this.substatusesRepository.substatuses.filter(x => x.statusId == StatusType.NotGood && x.id !== this.substatusId); }

    public async delete(ss: ISubstatus): Promise<void> {
        if (confirm(this.currentLang.words["sure"])) {
            this.appService.showNetworkstatus("sending data...");
            var sub = this.substatusesRepository.substatuses.find(x => x.id == this.substatusId);
            ss.replaceStatusId = this.substatusId;
            const loading = await this.loadingCtrl.create({
                message: this.currentLang.words["wait"],
            });
            loading.present();
            this.substatusesRepository.sendDeleteSubstatus(ss).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.processesRepository.load();
                } else this.appService.showError("API error", res.error || res.Error, true, res.responseCode);
                loading.dismiss();
                this.close();
            }, err => {
                loading.dismiss();
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in substatus-replace-overlay delete sendDeleteSubstatus: ${message}`, true);
            });

            this.substatusesRepository.delete(sub);
        }
    }

    public replaceToGlobalStatus(statusId: number) {
        if (confirm(this.currentLang.words["sure"])) {
            var ss: ISubstatus = { statusId: statusId, position: 0, replaceStatusId: 0, id : 0 };
            this.appService.showNetworkstatus("sending data...");
            var sub = this.substatusesRepository.substatuses.find(x => x.id == this.substatusId);
            ss.replaceStatusId = this.substatusId;
            this.substatusesRepository.sendDeleteSubstatus(ss).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.processesRepository.load();
                    this.substatusesRepository.delete(sub);
                    this.close();
                } else this.appService.showError("API error", res.error || res.Error, true, res.responseCode);

                
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in substatus-replace-overlay replaceToGlobalStatus sendDeleteSubstatus: ${message}`, true);
            });
        }
    }
}