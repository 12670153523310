/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.notification.popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alertnotification.popup.component";
import * as i3 from "@ionic/angular";
var styles_AlertNotificationPopupComponent = [i0.styles];
var RenderType_AlertNotificationPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertNotificationPopupComponent, data: {} });
export { RenderType_AlertNotificationPopupComponent as RenderType_AlertNotificationPopupComponent };
export function View_AlertNotificationPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["id", "notification"]], [[8, "className", 0], [8, "dir", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["id", "notification-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["id", "notification-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["routerDirection", "forward"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLeadClient() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["id", "notification-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? "active" : ""); var currVal_1 = _co.currentLang.dir; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.currentLang.words["notification-followUp-txt"]; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.currentLang.words["notification-link"]; _ck(_v, 6, 0, currVal_3); }); }
export function View_AlertNotificationPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alert-notification-popup", [], null, null, null, View_AlertNotificationPopupComponent_0, RenderType_AlertNotificationPopupComponent)), i1.ɵdid(1, 49152, null, 0, i2.AlertNotificationPopupComponent, [i3.NavController], null, null)], null, null); }
var AlertNotificationPopupComponentNgFactory = i1.ɵccf("alert-notification-popup", i2.AlertNotificationPopupComponent, View_AlertNotificationPopupComponent_Host_0, { active: "active", currentLang: "currentLang", leadId: "leadId" }, { activeChange: "activeChange" }, []);
export { AlertNotificationPopupComponentNgFactory as AlertNotificationPopupComponentNgFactory };
