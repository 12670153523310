/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.ngstyle";
import * as i1 from "./settings.overlay.page.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../iface.components/settings/notifications/notifications.settings.edit.component.ngfactory";
import * as i4 from "../../iface.components/settings/notifications/notifications.settings.edit.component";
import * as i5 from "@angular/common";
import * as i6 from "./settings.overlay.page";
import * as i7 from "../../services/app.service";
import * as i8 from "../../services/auth.service";
import * as i9 from "../../services/langs.repository";
import * as i10 from "../../services/users.repository";
var styles_SettingsOverlayComponent = [i0.styles, i1.styles];
var RenderType_SettingsOverlayComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_SettingsOverlayComponent, data: {} });
export { RenderType_SettingsOverlayComponent as RenderType_SettingsOverlayComponent };
function View_SettingsOverlayComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 16, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 14, "div", [["class", "ol-main ol-main-users"], ["style", "width: 400px"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 7, "div", [["class", "ol-head grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 2, "div", [["class", "ol-close"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 1, "a", [["class", "save"], ["style", "color: #fff"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵted(5, null, ["", ""])), (_l()(), i2.ɵeld(6, 0, null, null, 1, "div", [["class", "ol-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(7, null, ["", ""])), (_l()(), i2.ɵeld(8, 0, null, null, 1, "div", [["class", "ol-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 5, "div", [["class", "ol-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(11, 0, null, null, 4, "div", [["class", "full"]], null, null, null, null, null)), (_l()(), i2.ɵeld(12, 0, null, null, 3, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(13, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(14, 0, null, null, 1, "notification-settings-edit", [], null, null, null, i3.View_SettingsNotificationsEditComponent_0, i3.RenderType_SettingsNotificationsEditComponent)), i2.ɵdid(15, 49152, null, 0, i4.SettingsNotificationsEditComponent, [], { user: [0, "user"], currentLang: [1, "currentLang"] }, null), (_l()(), i2.ɵeld(16, 0, null, null, 0, "div", [["class", "ol-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.user; var currVal_4 = _co.currentLang; _ck(_v, 15, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? (("overlay clientOverlay clientUsersOverlay " + _co.currentLang.dir) + " active") : ("overlay clientOverlay " + _co.currentLang.dir)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.currentLang.words["save"]; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.currentLang.words["notification-settings"]; _ck(_v, 7, 0, currVal_2); }); }
export function View_SettingsOverlayComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_SettingsOverlayComponent_1)), i2.ɵdid(1, 16384, null, 0, i5.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SettingsOverlayComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "settings-view-overlay", [], null, null, null, View_SettingsOverlayComponent_0, RenderType_SettingsOverlayComponent)), i2.ɵdid(1, 49152, null, 0, i6.SettingsOverlayComponent, [i7.AppService, i8.AuthService, i9.LangsRepository, i10.UsersRepository], null, null)], null, null); }
var SettingsOverlayComponentNgFactory = i2.ɵccf("settings-view-overlay", i6.SettingsOverlayComponent, View_SettingsOverlayComponent_Host_0, { active: "active", currentLang: "currentLang" }, { activeChange: "activeChange", cancel: "cancel" }, []);
export { SettingsOverlayComponentNgFactory as SettingsOverlayComponentNgFactory };
