/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../../app.component.scss.ngstyle";
import * as i1 from "./users.overlay.replace.scss.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@angular/common";
import * as i4 from "./users.overlay.replace";
import * as i5 from "../../services/langs.repository";
import * as i6 from "../../services/users.repository";
import * as i7 from "../../services/app.service";
var styles_UserReplaceOverlayComponent = [i0.styles, i1.styles];
var RenderType_UserReplaceOverlayComponent = i2.ɵcrt({ encapsulation: 2, styles: styles_UserReplaceOverlayComponent, data: {} });
export { RenderType_UserReplaceOverlayComponent as RenderType_UserReplaceOverlayComponent };
function View_UserReplaceOverlayComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "div", [["class", "user"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 1, "div", [["class", "ss-title"], ["style", "width: 100%;"]], null, null, null, null, null)), (_l()(), i2.ɵted(2, null, ["", " ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.firstName; var currVal_1 = _v.context.$implicit.lastName; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_UserReplaceOverlayComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 13, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i2.ɵeld(1, 0, null, null, 11, "div", [["class", "ol-main"]], null, null, null, null, null)), (_l()(), i2.ɵeld(2, 0, null, null, 4, "div", [["class", "ol-head grad"]], null, null, null, null, null)), (_l()(), i2.ɵeld(3, 0, null, null, 1, "div", [["class", "ol-title"]], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["", ""])), (_l()(), i2.ɵeld(5, 0, null, null, 1, "div", [["class", "ol-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i2.ɵeld(7, 0, null, null, 5, "div", [["class", "ol-content"]], null, null, null, null, null)), (_l()(), i2.ɵeld(8, 0, null, null, 4, "div", [["class", "full"]], null, null, null, null, null)), (_l()(), i2.ɵeld(9, 0, null, null, 3, "div", [["id", "submain"]], null, null, null, null, null)), (_l()(), i2.ɵeld(10, 0, null, null, 2, "div", [["class", "content replace-user"]], null, null, null, null, null)), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_UserReplaceOverlayComponent_2)), i2.ɵdid(12, 278528, null, 0, i3.NgForOf, [i2.ViewContainerRef, i2.TemplateRef, i2.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i2.ɵeld(13, 0, null, null, 0, "div", [["class", "ol-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.users; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active ? (("overlay clientOverlay " + _co.currentLang.dir) + " active") : ("overlay clientOverlay " + _co.currentLang.dir)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.currentLang.words["replace-user"]; _ck(_v, 4, 0, currVal_1); }); }
export function View_UserReplaceOverlayComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵand(16777216, null, null, 1, null, View_UserReplaceOverlayComponent_1)), i2.ɵdid(1, 16384, null, 0, i3.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UserReplaceOverlayComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "users-replace-overlay", [], null, null, null, View_UserReplaceOverlayComponent_0, RenderType_UserReplaceOverlayComponent)), i2.ɵdid(1, 49152, null, 0, i4.UserReplaceOverlayComponent, [i5.LangsRepository, i6.UsersRepository, i7.AppService], null, null)], null, null); }
var UserReplaceOverlayComponentNgFactory = i2.ɵccf("users-replace-overlay", i4.UserReplaceOverlayComponent, View_UserReplaceOverlayComponent_Host_0, { active: "active", currentLang: "currentLang", userId: "userId" }, { activeChange: "activeChange", cancel: "cancel" }, []);
export { UserReplaceOverlayComponentNgFactory as UserReplaceOverlayComponentNgFactory };
