import { Injectable } from "@angular/core";
import { DataService } from './DataServices/data.service';
import { ILang } from '../model/lang.interface';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AppService } from './app.service';

@Injectable()
export class LangsRepository {
    public langs: ILang[] = [];
    public current: ILang | null = null;
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private dataService: DataService,
        private route: ActivatedRoute,
        private appService: AppService,
    ) {
        this.dataService.getLangs().subscribe(res => {
            this.langs = res;
            this.initCurrent();
            this.initCurrentSwitch();
            this.ready.next(true);
        });
    }

    public setDefault(): void {
        this.current = this.langs[0];
        localStorage.setItem("currentLang", this.current.slug);
    }

    public setCurrent(slug: string): void {
        // remove because UI issue apper
        // this.current = this.langs.find(l => l.slug === slug);
        localStorage.setItem("currentLang", slug);
        this.appService.showNetworkstatus(`language = ${this.current.slug}`);
        window.location.reload();
    }

    private initCurrent(): void {
        var currentLangSlug = localStorage.getItem("currentLang");

        if (currentLangSlug) {
            let currentLang: ILang | null = this.langs.find(l => l.slug === currentLangSlug) || null;

            if (currentLang) {
                this.current = currentLang;
            } else {
                this.setDefault();
            }
        } else {
            this.setDefault();
        }
        document.documentElement.lang = this.current.slug;
        document.dir = this.current.locale === "he-IL" ? "rtl" : "ltr";
    }

    private initCurrentSwitch(): void {
        this.route.fragment.subscribe(hash => {
            if (hash) {
                let hashParts: string[] = hash.split("=");

                if (hashParts.length === 2 && hashParts[0] === "lang") {
                    let langSlug = hashParts[1];
                    let currentLang: ILang | null = this.langs.find(l => l.slug === langSlug) || null;

                    if (currentLang) {
                        this.current = currentLang;
                        localStorage.setItem("currentLang", this.current.slug);
                        this.appService.showNetworkstatus(`language = ${langSlug}`);
                        window.location.href = "/";
                    }
                }
            }
        });
    }
}
