import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../services/app.service';
import { LeadClientsCalendarComponent } from './../lead.clients.calendar';

@Component({
    selector: 'lead-client-calendar-win',
    templateUrl: 'lead.clients.calendar.win.html',
    styleUrls: ["../../app.component.scss", "../../calendar.scss", "lead.clients.calendar.win.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class LeadCalendarWinComponent extends LeadClientsCalendarComponent {
    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    constructor(
        protected appService: AppService
    ) {
        super(appService);
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }
}
