import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ILang } from 'src/app/model/lang.interface';
import { IProcess } from 'src/app/model/process.interface';
import { IStatus } from 'src/app/model/status.interface';
import { ISubstatus } from 'src/app/model/substatus.interface';
import { LangsRepository } from 'src/app/services/langs.repository';
import { ProcessesRepositoryBUT } from 'src/app/services/processes.repository.but';
import { SubstatusesRepository } from 'src/app/services/substatuses.repository';
import { AppService } from '../../services/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'processes-new-overlay',
    templateUrl: 'processes.overlay.new.html',
    styleUrls: ["../../app.component.scss", "processes.overlay.new.scss"],
    encapsulation: ViewEncapsulation.None,
})

export class ProcessesNewOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() globalStatus: number = 0;

    public langsReady = false;
    public substatusesReady = false;
    public processesReady = false;
    public process: IProcess = { id: 0, title: "", statuses: [], targetProcessId: 0, titleError: '', targetSubStatusId: 0, targetLeadStatusId: 0, isDefault: false };
    public advanced = false;

    constructor(
        protected appService: AppService,
        protected authService: AuthService,
        protected langsRepository: LangsRepository,
        protected substatusesRepository: SubstatusesRepository,
        protected processesRepository: ProcessesRepositoryBUT,
    ) {
        this.ready = true;
        this.init();
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() {
        this.process = { id: 0, title: "", statuses: [], targetProcessId: 0, titleError: '', targetSubStatusId: 0, targetLeadStatusId: 0, isDefault: false};
        this.langsRepository.ready.subscribe(r => { if (r) { this.langsReady = true; } });
        if (this.authService.authenticated) {
            this.processesRepository.getStatuses().subscribe(res => {
                this.process.statuses = res.data;
            });
        }
    }
    public validate(): boolean {
        let err = false;

        if (!this.process.title.trim().length) {
            this.process.titleError = this.currentLang.words['enter-process'];
            err = true;
        } else if(this.process.title.trim().length > 250){
            this.process.titleError = this.currentLang.words['process-limit-error'];
            err = true;
        } else {
            this.process.titleError = "";
        }

        return !err;
    }

    public save(): void {
        if (this.validate()) {
            this.appService.showNetworkstatus("sending data...");
             this.processesRepository.createProcess(this.process).subscribe(res => {
                if (res.responseCode === 200) {
                    this.appService.showNetworkstatus("sent ok");
                    this.processesRepository.load();
                    this.substatusesRepository.load();
                    this.close();
                } else if (res.responseCode === 400) {
                    //this.appService.showError("API error", this.currentLang.words['status-exist'], true);
                } else {
                    //this.appService.showError("API error", res.error || res.Error, true);
                }
            }, err => {
                const message = this.appService.getErrorMessage(err);
                this.appService.showError("HTTP error", `${this.currentLang.words["http_error"]} in processes-new-overlay save createProcess: ${message}`, true);
            });
        }
    }
}
