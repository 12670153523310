<div class="edit user-edit-component" *ngIf="currentLang && user">
    <div class="edit-param">
        <label [class]="user.firstName ? '' : 'empty'">{{currentLang.words["firstname"]}}<span class="required"></span></label>
        <label *ngIf="user.firstName && user.firstName.length" class="empty clear-button"(click)="clearInput('firstName')">X</label>
        <input type="text" name="firstname" [(ngModel)]="user.firstName" (change)="validateFirstName()">
        <div class="bg-white"></div>
    </div>
    <div class="edit-status error" [innerHTML]="user.firstNameError"></div>

    <div class="edit-param">
        <label [class]="user.lastName ? '' : 'empty'">{{currentLang.words["lastname"]}}<span class="required"></span></label>
        <label *ngIf="user.lastName && user.lastName.length" class="empty clear-button"(click)="clearInput('lastName')">X</label>
        <input type="text" name="lastname" [(ngModel)]="user.lastName" (change)="validateLastName()">
        <div class="bg-white"></div>
    </div>
    <div class="edit-status error" [innerHTML]="user.lastNameError"></div>

    <div class="edit-param">
        <label [class]="user.email ? '' : 'empty'">{{currentLang.words["user-email"]}} <span class="required"></span></label>
        <label *ngIf="user.email && user.email.length" class="empty clear-button"(click)="clearInput('email')">X</label>
        <input type="text" name="email" [(ngModel)]="user.email" (change)="validateEmail()">
        <div class="bg-white"></div>
    </div>
    <div class="edit-status error" [innerHTML]="user.emailError"></div>

    <div class="edit-param">
        <label [class]="user.phone ? '' : 'empty'">{{currentLang.words["mobile-phone"]}} <span class="required"></span></label>
        <label *ngIf="user.phone && user.phone.length" class="empty clear-button"(click)="clearInput('phone')">X</label>
        <input type="tel" name="phone" [(ngModel)]="user.phone" (keyup)="validatePhoneOnlyNumbers()"
            (change)="ValidatePhone()">
        <div class="bg-white"></div>
    </div>
    <div class="edit-status error" [innerHTML]="user.phoneError"></div>
    
    <h2>{{currentLang.words["user-permissions"]}}</h2>
    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (user.canViewAllLeads ? ' active' : '')"
                (click)="user.canViewAllLeads = true;">{{currentLang.words["view-all-leads"]}}</div>
            <div [class]="'switch-state' + (!user.canViewAllLeads ? ' active' : '')"
                (click)="user.canViewAllLeads = false;">{{currentLang.words["view-my-leads"]}}</div>
        </div>
    </div>
    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (user && user.canEditAllLeads ? ' active' : '')"
                (click)="user.canEditAllLeads = true;">{{currentLang.words["edit-all-leads"]}}</div>
            <div [class]="'switch-state' + ( user && !user.canEditAllLeads ? ' active' : '')"
                (click)="user.canEditAllLeads = false;">{{currentLang.words["edit-my-leads"]}}</div>
        </div>
    </div>
    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (!user.isAdmin ? ' active' : '')" (click)="user.isAdmin = false;">
                {{currentLang.words["user"]}}</div>
            <div [class]="'switch-state' + (user.isAdmin ? ' active' : '')" (click)="user.isAdmin = true;">
                {{currentLang.words["admin"]}}</div>
        </div>
    </div>
</div>
<div class="ue-permissions">
    <h2>{{currentLang.words["fields-view-perm"]}}</h2>

    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (!user.canViewFullname ? ' active' : '')" (click)="user.canViewFullname = false;">
                {{currentLang.words["fullname"]}} {{currentLang.words["hide"]}}</div>
            <div [class]="'switch-state' + (user.canViewFullname ? ' active' : '')" (click)="user.canViewFullname = true;">
                {{currentLang.words["fullname"]}} {{currentLang.words["show"]}}</div>
        </div>
    </div>
    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (!user.canViewEmail ? ' active' : '')" (click)="user.canViewEmail = false;">
                {{currentLang.words["email"]}} {{currentLang.words["hide"]}}</div>
            <div [class]="'switch-state' + (user.canViewEmail ? ' active' : '')" (click)="user.canViewEmail = true;">
                {{currentLang.words["email"]}} {{currentLang.words["show"]}}</div>
        </div>
    </div>
    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (!user.canViewPhone ? ' active' : '')" (click)="user.canViewPhone = false;">
                {{currentLang.words["phone"]}} {{currentLang.words["hide"]}}</div>
            <div [class]="'switch-state' + (user.canViewPhone ? ' active' : '')" (click)="user.canViewPhone = true;">
                {{currentLang.words["phone"]}} {{currentLang.words["show"]}}</div>
        </div>
    </div>
    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (!user.canViewAssignee ? ' active' : '')" (click)="user.canViewAssignee = false;">
                {{currentLang.words["assignee"]}} {{currentLang.words["hide"]}}</div>
            <div [class]="'switch-state' + (user.canViewAssignee ? ' active' : '')" (click)="user.canViewAssignee = true;">
                {{currentLang.words["assignee"]}} {{currentLang.words["show"]}}</div>
        </div>
    </div>
    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (!user.canViewPrice ? ' active' : '')" (click)="user.canViewPrice = false;">
                {{currentLang.words["price"]}} {{currentLang.words["hide"]}}</div>
            <div [class]="'switch-state' + (user.canViewPrice ? ' active' : '')" (click)="user.canViewPrice = true;">
                {{currentLang.words["price"]}} {{currentLang.words["show"]}}</div>
        </div>
    </div>
    <div class="edit-param-switch">
        <div class="switch">
            <div [class]="'switch-state' + (!user.canViewOtherData ? ' active' : '')" (click)="user.canViewOtherData = false;">
                {{currentLang.words["other-data"]}} {{currentLang.words["hide"]}}</div>
            <div [class]="'switch-state' + (user.canViewOtherData ? ' active' : '')" (click)="user.canViewOtherData = true;">
                {{currentLang.words["other-data"]}} {{currentLang.words["show"]}}</div>
        </div>
    </div>

</div>
