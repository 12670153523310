<div id="wrap" *ngIf="langsReady">
    <header class="grad">
        <div id="h1">{{currentLang.words['subscription']}}</div>
        <div id="h-left">
            <a class="back" (click)="back ();">{{currentLang.words["back"]}}</a>
        </div>
    </header>
    <div id="main" class="full" [class]='currentLang.dir === "rtl" ? "full rtl" : "full ltr"'>
        <div id="submain">
            <div class="content">
                <ng-container *ngIf="step === 1">
                    <div class="payment-text">
                        <p [innerHTML]="currentLang.words['payinfo1']"></p>
                        <p [innerHTML]="currentLang.words['payinfo2']"></p>
                    </div>
                    <form (submit)="toStep2();">
                        <div class="edit">

                            <div class="edit-param">
                                <label [class]="login ? '' : 'empty'">{{currentLang.words["email-phone"]}}</label>
                                <input name="login" type="text" [(ngModel)]="login" class="ltr-always">
                            </div>
                           

                            <div class="edit-param">
                                <button class="big opaque" [disabled]="busy"
                                    type="submit">{{currentLang.words["continue"]}}</button>
                            </div>
                            <div [class]="step1Error ? 'edit-status error' : 'edit-status'" [innerHTML]="step1Status">
                            </div>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="step === 2">
                    <div id="payment-framewrap" *ngIf="iframeSrc">
                        <iframe [src]="iframeSrc | safeurl"></iframe>
                    </div>
                    <ng-container *ngIf="showSendSms">
                        <form (submit)="finish();">
                            <div class="edit">
                                <div class="edit-param">
                                    <label [class]="passcode ? '' : 'empty'">{{currentLang.words["smscode"]}}</label>
                                    <input name="passcode" type="text" [(ngModel)]="passcode" class="ltr-always">
                                    <div class="edit-status error" [innerHTML]="passcodeError"></div>
                                </div>
                                <div class="edit-param"><button class="big opaque" [disabled]="busy"
                                        type="submit">{{currentLang.words["enter-passcode-button"]}}</button></div>
                                <div class="edit-link" (click)="toStep1();">{{currentLang.words["didntreceive"]}}</div>
                                <div [class]="step2Error ? 'edit-status error' : 'edit-status'"
                                    [innerHTML]="step2Status"></div>
                            </div>
                        </form>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div id="loading" [class]="langsReady && !busy ? '' : 'visible'">
    <div class="loading-spinner"></div>
</div>