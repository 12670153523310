<div [lang]="currentLang.slug" [class]="active ? 'popup active' : 'popup'" [dir]="currentLang.dir"
    (click)="onPopupClick($event);">
    <div class="popup-win">
        <div class="popup-head grad">
            <div class="popup-title">{{currentLang.words["download-app"]}}</div>
            <div class="popup-close" (click)="close();"><span></span></div>
        </div>
        <div class="popup-content">
            <div class="popup-flexblock center">
                <a [href]="currentLang.words['google-play-link']" target="_blank"><img
                        src="../assets/img/google-play.png" alt="google play"></a>
            </div>
            <div class="popup-flexblock center">
                <a [href]="currentLang.words['app-store-link']" target="_blank"><img
                    src="../assets/img/app-store.png" alt="google play"></a>
            </div>
        </div>
        <div class="popup-foot">
            <button class="popup-close" (click)="close();">{{currentLang.words["close"]}}</button>
        </div>
    </div>
</div>