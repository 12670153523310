import { Injectable } from "@angular/core";
import { DataService } from './DataServices/data.service';
import { ProcessesRepository } from './processes.repository';
import { LangsRepository } from './langs.repository';
import { AppService } from './app.service';
import { IProcess } from "../model/process.interface";
import { Observable } from "rxjs/internal/Observable";
import { IResponse } from "../model/response.interface";

@Injectable()
export class ProcessesRepositoryBUT extends ProcessesRepository {
    public lastReload: number = 0;
    public cacheKey: string = "";

    constructor(
        protected dataService: DataService,
        protected langsRepository: LangsRepository,
        protected appService: AppService,
    ) {
        super(langsRepository);
        this.cacheKey = this.getCurrentCacheKeyFromLocalStorage();
    }

    public load(): void {
        this.dataService.token.subscribe(token => {
            this.loading.next(true);
            this.ready.next(false);
            this.processes = [];
            if (token) {
                this.dataService.but_getProcesses(this.locale).subscribe(res => {
                    if (res.responseCode === 200) {
                        this.processes = (res.data.length) ? res.data : [];
                        this.lastReload = (new Date()).getTime();
                        this.setCacheKeyIfNotExistToLocalStorage(res.settingsCacheToken);
                        this.loading.next(false);
                        this.ready.next(true);
                    } else {
                        this.appService.showError("API error", res.error || res.Error, false, res.responseCode);
                    }
                }, err => {
                    const message = this.appService.getErrorMessage(err);
                    this.appService.showError("HTTP error", `${this.langsRepository.current.words["http_error"]} in ProcessesRepositoryBUT load but_getProcesses: ${message}`);
                });
            }
        });
    }

    public prepare(): Promise<void> {
        return new Promise((resolve, reject) => {
            if ((this.ready.value || this.loading.value) && this.getCurrentCacheKeyFromLocalStorage() === this.cacheKey) {
                resolve();
            } else {
                this.load();
                this.ready.subscribe(r => {
                    if (r) {
                        resolve();
                        this.updateCacheKeyInLocalStorage(this.cacheKey);
                    }
                });
            }
        });
    }

    public setCacheKeyIfNotExistToLocalStorage(key: string) {
        var storeKey = localStorage.getItem('cacheKey');
        if (!storeKey) localStorage.setItem('cacheKey', key);
    }

    public getCurrentCacheKeyFromLocalStorage() {
        return localStorage.getItem('cacheKey');
    }

    public updateCacheKeyInLocalStorage(key: string) {
        localStorage.setItem('cacheKey', key);
    }

    public getStatuses(): Observable<IResponse<any>> {
        return this.dataService.but_getStatuses();
    }

    public updateProcess(process: IProcess): Observable<IResponse<any>> {
        return this.dataService.but_updateProcess(process);
    }

    public createProcess(process: IProcess): Observable<IResponse<any>> {
        return this.dataService.but_createProcess(process);
    }

    public deleteProcess(process: IProcess): Observable<IResponse<any>> {
        return this.dataService.but_deleteProcess(process);
    }

    public delete(process: IProcess): void {
        let index: number = this.processes.findIndex(ss => ss.id === process.id);

        if (index !== -1) {
            this.processes.splice(index, 1);
        }
    }
}
