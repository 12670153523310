import * as tslib_1 from "tslib";
import { NgZone } from "@angular/core";
import { Platform, NavController } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AuthService } from './auth.service';
import { LeadsRepositoryBUT } from './leads.repository.but';
import { AppService } from './app.service';
import { Lead } from '../model/lead.model';
export class CallService {
    constructor(platform, androidPermissions, authService, leadsRepository, appService, ngZone, navCtrl) {
        this.platform = platform;
        this.androidPermissions = androidPermissions;
        this.authService = authService;
        this.leadsRepository = leadsRepository;
        this.appService = appService;
        this.ngZone = ngZone;
        this.navCtrl = navCtrl;
        this.prevState = "";
        this.state = "";
        this.oldCalls = [];
    }
    get canWork() { return this.platform.is("android") && (!this.platform.is('mobileweb')); }
    init() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.canWork) {
                if (yield this.setPermissions()) {
                    this.initCallProcessing();
                    window.drawover.initialize(() => { }, () => { }, {});
                    let strOldCalls = localStorage.getItem("oldCalls");
                    if (strOldCalls) {
                        this.oldCalls = JSON.parse(strOldCalls);
                    }
                }
            }
        });
    }
    setPermissions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const values = yield Promise.all([
                this.setPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE),
                this.setPermission(this.androidPermissions.PERMISSION.READ_CALL_LOG)
            ]);
            return values.every(i => i);
        });
    }
    setPermission(permission) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let res = yield this.androidPermissions.checkPermission(permission);
            if (res.hasPermission) {
                return true;
            }
            let res2 = yield this.androidPermissions.requestPermission(permission);
            return res2.hasPermission;
        });
    }
    initCallProcessing() {
        if (PhoneCallTrap) {
            PhoneCallTrap.onCall((obj) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (this.authService.authenticated) {
                    this.prevState = this.state;
                    let call = JSON.parse(obj);
                    if (!call || !call.incomingNumber) {
                        return;
                    }
                    this.state = call.state;
                    if (call.incomingNumber.includes('+')) {
                        call.incomingNumber = call.incomingNumber.replace('+', '');
                    }
                    let lead = yield this.findLead(call.incomingNumber);
                    if (this.state === "RINGING") { // incoming call
                        if (lead) {
                            this.showCallReceiveDrawOver(lead, call.incomingNumber);
                        }
                        else {
                            this.showCallEndDrawOver(call.incomingNumber);
                        }
                    }
                    else if (this.state === "IDLE") {
                        if (this.prevState === "RINGING") { // call ended without answer
                            if (lead) {
                                this.showCallReceiveDrawOver(lead, call.incomingNumber);
                            }
                            else {
                                this.showCallEndDrawOver(call.incomingNumber);
                            }
                        }
                        else if (this.prevState === 'OFFHOOK') { // call ended with answer
                            if (!this.oldCalls.includes(call.incomingNumber)) {
                                if (lead) {
                                    this.showCallReceiveDrawOver(lead, call.incomingNumber);
                                }
                                else {
                                    this.showCallEndDrawOver(call.incomingNumber);
                                }
                                this.oldCalls.push(call.incomingNumber);
                                localStorage.setItem("oldCalls", JSON.stringify(this.oldCalls));
                            }
                        }
                    }
                }
            }));
        }
    }
    findLead(phone) {
        return new Promise((resolve, reject) => {
            this.leadsRepository.ready.subscribe(r => {
                if (r) {
                    this.leadsRepository.loadLeads(0, 1, phone, 0, "followUp", 0).subscribe(res => {
                        if (res.responseCode === 200) {
                            if (res.data.leads.length) {
                                resolve(new Lead().build(res.data.leads[0]));
                            }
                            else {
                                resolve(null);
                            }
                        }
                        else {
                            this.appService.showError("API error", `${res.responseCode} ${res.error || res.Error}`, false, res.responseCode);
                            resolve(null);
                        }
                    }, err => {
                        const message = this.appService.getErrorMessage(err);
                        this.appService.showError("HTTP error", `${message} in CallService findLead loadLeads`);
                        resolve(null);
                    });
                }
            });
        });
    }
    // using Dhaval plugin
    showCallReceiveDrawOver(lead, phoneNumber) {
        window.drawover.callReceive(() => {
            this.ngZone.run(() => {
                window.plugins.bringtofront();
                this.navCtrl.navigateForward(`/leadClients/${lead.leadClientId}`);
            });
        }, (error) => { }, { phoneNumber, name: lead.fullname });
    }
    // using Dhaval plugin
    showCallEndDrawOver(phoneNumber) {
        window.drawover.callEnd(() => {
            this.ngZone.run(() => {
                window.plugins.bringtofront();
                this.navCtrl.navigateForward(`/leadClients/new/fromcalls/${phoneNumber}`);
            });
        }, (error) => { }, { phoneNumber });
    }
}
