import { Component } from '@angular/core';
import { LangsRepository } from '../../services/langs.repository';
import { ILang } from '../../model/lang.interface';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
	selector: 'promo-page',
	templateUrl: 'promo.page.html',
	styleUrls: ['promo.page.scss', "../../app.component.scss"]
})
export class PromoPage {
	public langsReady: boolean = false;
	public token: string = "";

	constructor(
		private readonly langsRepository: LangsRepository,
		private readonly route: ActivatedRoute,
		private readonly location: Location
	) {
		this.route.params.subscribe(p => {
			this.token = p["token"];
		});
		this.langsRepository.ready.subscribe(r => {
			if (r) this.langsReady = true;
		});
	}

	get currentLang(): ILang { return this.langsRepository.current; }

	public back(): void { this.location.back(); }
}
