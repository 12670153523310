import * as tslib_1 from "tslib";
import { NgZone } from "@angular/core";
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Platform, NavController } from '@ionic/angular';
import { AppService } from './app.service';
import { LogService } from "./log.service";
export class NotificationService {
    constructor(firebaseX, platform, appService, navCtrl, ngZone, logService) {
        this.firebaseX = firebaseX;
        this.platform = platform;
        this.appService = appService;
        this.navCtrl = navCtrl;
        this.ngZone = ngZone;
        this.logService = logService;
        this.leadId = null;
        this.active = false;
        this.followUpAlert = false;
        this.whatsAppAlert = false;
        this.notificationTitle = "";
        this.link = "";
        this.message = "";
    }
    get canWork() { return this.platform.is("cordova"); }
    get isAndroid() { return this.platform.is("android"); }
    get isIOS() { return this.platform.is("ios"); }
    subscribe(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.platform.ready();
            if (this.canWork) {
                try {
                    this.logService.add(`canWork. userId: ${userId}`);
                    yield this.firebaseX.subscribe(`fix-${userId}`).then(function (res) { });
                    this.logService.add(`check hasPermission`);
                    this.firebaseX.hasPermission().then((hasPerm) => {
                        this.logService.add(`hasPerm: ${hasPerm}`);
                        if (!hasPerm) {
                            this.firebaseX.grantPermission().then((permissionGranted) => { });
                            this.logService.add(`grantPermission`);
                        }
                    });
                    if (this.isAndroid) {
                        this.logService.add(`isAndroid`);
                        this.firebaseX.onTokenRefresh().subscribe((token) => {
                            this.logService.add(`onTokenRefresh success: ${token}`);
                        });
                        this.logService.add(`onTokenRefresh`);
                        this.firebaseX.getToken().then((token) => {
                            this.logService.add(`getToken success: ${token}`);
                        });
                        this.logService.add(`getToken`);
                    }
                    else if (this.isIOS) {
                        this.logService.add(`isIOS`);
                        // TEMP block (as in Android)
                        this.firebaseX.onTokenRefresh().subscribe((token) => {
                            this.logService.add(`onTokenRefresh success: ${token}`);
                        });
                        this.logService.add(`onTokenRefresh`);
                        this.firebaseX.getToken().then((token) => {
                            this.logService.add(`getToken success: ${token}`);
                        });
                        this.logService.add(`getToken`);
                        this.firebaseX.onApnsTokenReceived().subscribe((token) => {
                            this.logService.add(`onApnsTokenReceived success: ${token}`);
                        });
                        this.logService.add(`onApnsTokenReceived`);
                        this.firebaseX.getAPNSToken().then((token) => {
                            this.logService.add(`getAPNSToken success: ${token}`);
                        });
                        this.logService.add(`getAPNSToken`);
                    }
                    this.logService.add(`before onMessageReceived`);
                    this.firebaseX.onMessageReceived().subscribe(msg => {
                        this.logService.add(`onMessageReceived: ${JSON.stringify(msg)}`);
                        if ((msg.messageType === 'notification' || msg.messageType == 'data') && msg.leadClientId > 0) {
                            this.logService.add(`notification leadClientId`);
                            this.leadId = msg.leadClientId;
                            setTimeout(() => {
                                this.appService.leadClientsGroup.forEach((x) => {
                                    var lc = x.leadClients.find(c => c.id == msg.leadClientId);
                                    if (lc) {
                                        if (!lc.countOfWhatsAppMessages) {
                                            lc.countOfWhatsAppMessages = 0;
                                        }
                                        lc.countOfWhatsAppMessages++;
                                    }
                                });
                            }, 100);
                            if (msg.tap) {
                                this.ngZone.run(() => {
                                    this.navCtrl.navigateForward(`/leadClients/${msg.leadClientId}/whatsapp`);
                                });
                            }
                            else {
                                this.ngZone.run(() => {
                                    this.notificationTitle = msg.notification.title;
                                    this.message = msg.notification.body;
                                    this.whatsAppAlert = true;
                                });
                            }
                        }
                        else if ((msg.messageType === "notification" || msg.messageType == 'data') && msg.leadId > 0) {
                            this.logService.add(`notification leadId`);
                            this.leadId = msg.leadId;
                            if (msg.tap) {
                                this.ngZone.run(() => {
                                    // if (this.isAndroid) {
                                    //     (<any>window).plugins.bringtofront();
                                    // }
                                    this.navCtrl.navigateForward(`/leadClients/${msg.leadId}`);
                                });
                            }
                            else {
                                this.ngZone.run(() => {
                                    this.active = true;
                                });
                            }
                        }
                        else if ((msg.messageType === 'notification' || msg.messageType == 'data') && msg.followUpLeadId > 0) {
                            this.logService.add(`notification followUpLeadId`);
                            this.leadId = msg.followUpLeadId;
                            if (msg.tap || msg.tap == "background") {
                                this.ngZone.run(() => {
                                    // if (this.isAndroid) {
                                    //     (<any>window).plugins.bringtofront();
                                    // }
                                    this.navCtrl.navigateForward(`/leadClients/${msg.followUpLeadId}`);
                                });
                            }
                            else {
                                this.ngZone.run(() => {
                                    this.followUpAlert = true;
                                });
                            }
                        }
                    });
                }
                catch (err) {
                    this.logService.add(`Firebase subscription error. ${JSON.stringify(err)}`);
                    this.appService.showError("Firebase subscription error", err);
                }
            }
            else {
                this.logService.add(`NO canWork. userId: ${userId}`);
            }
        });
    }
    unsubscribe(userId) {
        if (this.canWork) {
            this.firebaseX.unsubscribe(`fix-${userId}`);
        }
    }
}
