import { Injectable, NgZone } from "@angular/core";
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Platform, NavController } from '@ionic/angular';
import { AppService } from './app.service';
import { LogService } from "./log.service";

@Injectable()
export class NotificationService {
    public leadId: number | null = null;
    public active: boolean = false;
    public followUpAlert: boolean = false;
    public whatsAppAlert: boolean = false;
    public notificationTitle: string = "";
    public link: string = "";
    public message: string = "";

    constructor(
        private firebaseX: FirebaseX,
        private platform: Platform,
        private appService: AppService,
        private navCtrl: NavController,
        private ngZone: NgZone,
        private logService: LogService,
    ) { }

    get canWork(): boolean { return this.platform.is("cordova"); }
    get isAndroid(): boolean { return this.platform.is("android"); }
    get isIOS(): boolean { return this.platform.is("ios"); }

    public async subscribe(userId: number): Promise<void> {
        await this.platform.ready();
        if (this.canWork) {
            try {
                this.logService.add(`canWork. userId: ${userId}`);
                await this.firebaseX.subscribe(`fix-${userId}`).then(function (res) { });
                this.logService.add(`check hasPermission`);
                this.firebaseX.hasPermission().then((hasPerm: boolean) => {
                    this.logService.add(`hasPerm: ${hasPerm}`);
                    if (!hasPerm) {
                        this.firebaseX.grantPermission().then((permissionGranted) => { });
                        this.logService.add(`grantPermission`);
                    }
                });
                if (this.isAndroid) {
                    this.logService.add(`isAndroid`);
                    this.firebaseX.onTokenRefresh().subscribe((token: string) => {
                        this.logService.add(`onTokenRefresh success: ${token}`)
                    });
                    this.logService.add(`onTokenRefresh`);
                    this.firebaseX.getToken().then((token) => {
                        this.logService.add(`getToken success: ${token}`);
                    });
                    this.logService.add(`getToken`);
                } else if (this.isIOS) {
                    this.logService.add(`isIOS`);


                    // TEMP block (as in Android)
                    this.firebaseX.onTokenRefresh().subscribe((token: string) => {
                        this.logService.add(`onTokenRefresh success: ${token}`)
                    });
                    this.logService.add(`onTokenRefresh`);
                    this.firebaseX.getToken().then((token) => {
                        this.logService.add(`getToken success: ${token}`);
                    });
                    this.logService.add(`getToken`);


                    this.firebaseX.onApnsTokenReceived().subscribe((token: string) => {
                        this.logService.add(`onApnsTokenReceived success: ${token}`)
                    })
                    this.logService.add(`onApnsTokenReceived`);
                    this.firebaseX.getAPNSToken().then((token) => {
                        this.logService.add(`getAPNSToken success: ${token}`);
                    });
                    this.logService.add(`getAPNSToken`);
                }
                this.logService.add(`before onMessageReceived`);
                this.firebaseX.onMessageReceived().subscribe(msg => {
                    this.logService.add(`onMessageReceived: ${JSON.stringify(msg)}`);
                    if ((msg.messageType === 'notification' || msg.messageType == 'data') && msg.leadClientId > 0) {
                        this.logService.add(`notification leadClientId`);
                        this.leadId = msg.leadClientId;

                        setTimeout(() => {
                            this.appService.leadClientsGroup.forEach((x) => {
                                var lc = x.leadClients.find(c => c.id == msg.leadClientId);
                                if (lc) {
                                    if (!lc.countOfWhatsAppMessages) {
                                        lc.countOfWhatsAppMessages = 0;
                                    }

                                    lc.countOfWhatsAppMessages++;
                                }
                            })
                        }, 100);

                        if (msg.tap) {
                            this.ngZone.run(() => {
                                this.navCtrl.navigateForward(`/leadClients/${msg.leadClientId}/whatsapp`);
                            });
                        } else {
                            this.ngZone.run(() => {
                                this.notificationTitle = msg.notification.title;
                                this.message = msg.notification.body;
                                this.whatsAppAlert = true;
                            });
                        }
                    }
                    else if ((msg.messageType === "notification" || msg.messageType == 'data') && msg.leadId > 0) {
                        this.logService.add(`notification leadId`);
                        this.leadId = msg.leadId;
                        if (msg.tap) {
                            this.ngZone.run(() => {
                                // if (this.isAndroid) {
                                //     (<any>window).plugins.bringtofront();
                                // }
                                this.navCtrl.navigateForward(`/leadClients/${msg.leadId}`);
                            });
                        } else {
                            this.ngZone.run(() => {
                                this.active = true;
                            });
                        }
                    }
                    else if ((msg.messageType === 'notification' || msg.messageType == 'data') && msg.followUpLeadId > 0) {
                        this.logService.add(`notification followUpLeadId`);
                        this.leadId = msg.followUpLeadId;
                        if (msg.tap || msg.tap == "background") {
                            this.ngZone.run(() => {
                                // if (this.isAndroid) {
                                //     (<any>window).plugins.bringtofront();
                                // }
                                this.navCtrl.navigateForward(`/leadClients/${msg.followUpLeadId}`);
                            });
                        } else {
                            this.ngZone.run(() => {
                                this.followUpAlert = true;
                            });
                        }
                    }
                });
            } catch (err) {
                this.logService.add(`Firebase subscription error. ${JSON.stringify(err)}`);
                this.appService.showError("Firebase subscription error", err);
            }
        } else {
            this.logService.add(`NO canWork. userId: ${userId}`);
        }
    }

    public unsubscribe(userId: number): void {
        if (this.canWork) {
            this.firebaseX.unsubscribe(`fix-${userId}`);
        }
    }
}
