import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PromoPage } from './promo/promo.page';
import { HomePage } from './home/home.page';
import { Page404 } from './notFound/404.page';
import { PaymentPage } from './payment/payment.page';
import { PipesModule } from '../pipes/pipes.module';
import { ComingsoonPage } from './comingsoon/comingsoon.page';
import { RegistrationPage } from './registration/registration.page';
import { LeadClientsBUTPage } from '../leadClients/leadClientsBUTPage/lead.clients.but.page';
import { LeadCLientPreviewComponent } from '../leadClients/LeadClientPreview/lead.client.preview';
import { LeadStatusesWinComponent } from '../leadClients/LeadClientsStatusesWin/lead.clients.statuses.win';
import { LeadCalendarWinComponent } from '../leadClients/LeadClientsCalendarWin/lead.clients.calendar.win';
import { MyDatePickerModule } from 'mydatepicker/dist/my-date-picker.module';
import { TestPage } from './test/test.page';
import { PrivacyPolicyPage } from './privacy-policy/privacy-policy.page';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		RouterModule,
		PipesModule,
		MyDatePickerModule,
	],
	declarations: [
		HomePage,
		PromoPage,
		PaymentPage,
		Page404,
		ComingsoonPage,
		RegistrationPage,
		LeadClientsBUTPage,
		LeadClientsBUTPage,
		LeadCLientPreviewComponent,
		LeadCalendarWinComponent,
		LeadStatusesWinComponent,
		TestPage,
		PrivacyPolicyPage,
	]
})
export class PagesModule { }
